.table.grouped {
    @include lib-table-bordered(
        $_table_type: horizontal
    );

    > thead > tr > th {
        border-bottom: 0;
    }

    .product-item-name {
        font-weight: $font-weight__regular;
    }

    .price-box {
        .price {
            font-size: $font-size__base;
        }
    }

    .control {
        .qty {
            @extend .abs-input-qty;
        }
    }

    .stock.unavailable {
        font-weight: normal;
        margin: 0;
        text-transform: none;
        white-space: nowrap;
    }

    .row-tier-price {
        td {
            border-top: 0;
        }

        .prices-tier {
            margin: 0 -10px 10px;
        }
    }
}

.notification-wrapper {
    margin-top: 5px;
}

.notification-subscribe {
    text-decoration: underline;
    color: #16a3ff;
    font-weight: $font-weight__semibold;
}

.notification-subscribe {
    text-decoration: underline;
}

.grouped-product-list {
    &__row {
        position: relative;
        margin-bottom: $indent__s;
        padding: 15px 20px;
        background-color: $color-white;
        border-radius: 5px;

        &::after {
            content: '';
            position: absolute;
            z-index: -1;
            right: 10px;
            bottom: 0;
            left: 10px;
            height: 10px;
            box-shadow: 0 0 25px rgba(0, 0, 0, 0.8);
            border-radius: 100px / 10px;
        }
    }

    &__info {
        width: 100%;
    }

    &__properties {
        display: flex;
        align-items: center;
        margin-top: 5px;
        margin-right: 10px;

        .tooltip-wrapper {
            margin: 5px 0 0 0;

            .tooltip-icon {
                position: relative;
                @include lib-icon-font(
                    $_icon-font-content: false,
                    $_icon-font-size: 44px,
                    $_icon-font-line-height: 1,
                    $_icon-font-color: #6da0ca
                );

                &.icon-transportation {
                    @include lib-icon-font-size(
                        $_icon-font-size: 52px,
                        $_icon-font-line-height: 1px
                    );
                    position: relative;
                    top: -2px;
                }
            }

            &.active .tooltip-content {
                display: block;
            }

            &:not(:last-of-type) {
                padding-right: 5px;
            }

            &:not(:first-of-type) {
                padding-left: 5px;
            }
        }

        .tooltip-content {
            position: absolute;
            display: none;
            padding: 15px;
            background-color: #0a2d67;
            border-radius: 6px;
            z-index: 10;
            color: #fff;

            h4 {
                margin-top: 0;
                margin-bottom: 10px;
                font-size: 18px;
            }

            p {
                margin: 0;
                color: $text__color__light;
            }
        }
    }

    &__right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    &__actions {
        display: flex;
        align-items: center;

        input {
            border: 1px solid #dedede;
            height: 30px;
            width: 50px;
            padding: 0;
            text-align: center;
            font-size: 16px;
            font-weight: $font-weight__semibold;
            margin: 0 5px;
        }

        .restock-notify {
            @include lib-button();
            @include lib-link-as-button();
            @include lib-css(border-radius, $button__border-radius);
            text-align: center;
        }
    }

    .js-price-total {
        margin-left: 10px;
        min-width: 71px;
    }

    &__info-small {
        display: flex;
        align-items: center;
        color: $djm-color-light-gray;
        font-size: 14px;
    }

    &__wishlist {
        .action.towishlist {
            @include lib-button-reset;
            @include lib-icon-font(
                    $icon-wishlist-add,
                $_icon-font-text-hide: true,
                $_icon-font-size: 18px,
                $_icon-font-color: $color-gray-icon,
                $_icon-font-color-hover: darken($color-gray-icon, 8%),
                $_icon-font-margin: 0 0 0 20px
            );
        }
    }

    .product-item-name {
        margin: 0;

        &__container {
            display: flex;

            span:first-child {
                margin-right: 5px;
            }

            span:last-child {
                color: #959ca7;
            }
        }

        span {
            display: block;
            font-weight: 700;
        }
    }
}

.product-item-attributes {
    display: flex;
    flex-wrap: wrap;
    margin: 5px 0;

    &__attribute {
        strong {
            margin-right: 5px;
            color: $djm-color-light-gray;
            font-weight: $font-weight__regular;
        }

        span {
            color: $djm-color-gray;
            font-weight: $font-weight__semibold;
        }
    }
}

.icon-quality {
    @include lib-icon-font-symbol(
            $icon-quality
    );
}

.icon-documents {
    @include lib-icon-font-symbol(
            $icon-documents
    );
}

.icon-transportation {
    @include lib-icon-font-symbol(
            $icon-transportation
    );
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .grouped-product-list {
        &__left {
            flex: 1 0 auto;
        }
    }

    .product-item-attributes {
        &__attribute {
            display: flex;

            &:not(:last-child) {
                margin-right: 5px;

                &::after {
                    content: '-';
                    margin-left: 5px;
                }
            }
        }
    }
}

@include min-screen($screen__l) {
    .grouped-product-list {
        &__row {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }

    .tooltip-wrapper {
        position: relative;
    }

    .tooltip-content {
        width: 230px;

        &::before {
            content: '';
            position: absolute;
            left: calc(50% - 10px);
            bottom: 100%;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid #0a2d67;
        }
    }
}

@include max-screen($screen__l) {
    .grouped-product-list {
        &__properties {
            position: relative;
        }

        &__right {
            justify-content: flex-start;
        }
    }

    .tooltip-content {
        left: 0;
        right: 0;
        margin-top: 10px;
    }

    .tooltip-wrapper.active .tooltip-icon {
        &::before {
            color: darken(#6da0ca, 15%)
        }

        &::after {
            content: '';
            position: absolute;
            top: calc(100% + 5px);
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;

            border-bottom: 5px solid #0a2d67;
        }
    }
}

@include min-screen(1200px) {
    .grouped-product-list {
        &__left {
            display: flex;
            flex-wrap: wrap;
            width: 53%;
        }

        &__right {
            width: 42%;
        }
    }
}

@include max-screen(1199px) {
    .grouped-product-list {
        &__right {
            margin-top: 15px;
        }
    }
}
