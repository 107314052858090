//
//  Variables
//  _____________________________________________

$checkout-shipping-address__max-width           : 600px;
$checkout-shipping-address__margin-top          : 28px;

$checkout-shipping-item-icon__color             : $color-white;
$checkout-shipping-item-icon__content           : $icon-checkmark;

$checkout-shipping-item__border                 : 2px solid transparent;
$checkout-shipping-item__line-height            : 30px;
$checkout-shipping-item__margin                 : 0 0 $indent__base;
$checkout-shipping-item__padding                : $indent__base ($indent__l + 5px) $indent__base $indent__base;
$checkout-shipping-item__transition             : 0.3s border-color;
$checkout-shipping-item__width                  : 100% / 3;
$checkout-shipping-item-tablet__width           : 100% / 2;
$checkout-shipping-item-mobile__width           : 100%;
$checkout-shipping-item__active__border-color   : $color-blue1;
$checkout-shipping-item-icon__background-color  : $checkout-shipping-item__active__border-color;

$checkout-shipping-item-icon__selected__height  : 27px;
$checkout-shipping-item-icon__selected__width   : 29px;

$checkout-shipping-item-mobile__padding         : 0 0 15px;
$checkout-shipping-item-mobile__margin          : $checkout-shipping-item-mobile__padding;
$checkout-shipping-item-mobile__active__padding : 15px ($indent__l + 5px) 15px 18px;

$checkout-shipping-item-before__border-color    : $color-gray80;
$checkout-shipping-item-before__height          : calc(100% - 20px);

$checkout-shipping-method__border               : $checkout-step-title__border;
$checkout-shipping-method__padding              : $indent__base;

//
//  Common
//  _____________________________________________

.opc-wrapper {

    //
    //  Shipping Address
    //  ---------------------------------------------

    .form-login,
    .form-shipping-address {
        margin-bottom: $indent__base;

        .fieldset {
            .note {
                font-size: $font-size__base;
                margin-top: $indent__s;
            }
        }
    }

    .form-login {
        @include lib-css(margin-top, $checkout-shipping-address__margin-top);
    }

    .shipping-address-items {
        font-size: 0;
    }

    .shipping-address-item {
        @extend .abs-add-box-sizing;
        @include lib-css(border, $checkout-shipping-item__border);
        @include lib-css(line-height, $checkout-shipping-item__line-height);
        @include lib-css(margin, $checkout-shipping-item__margin);
        @include lib-css(padding, $checkout-shipping-item__padding);
        @include lib-css(transition, $checkout-shipping-item__transition);
        @include lib-css(width, $checkout-shipping-item-tablet__width);
        display: inline-block;
        font-size: $font-size__base;
        position: relative;
        vertical-align: top;
        word-wrap: break-word;

        &.selected-item {
            @include lib-css(border-color, $checkout-shipping-item__active__border-color);

            &:after {
                @include lib-css(background, $checkout-shipping-item-icon__background-color);
                @include lib-css(color, $checkout-shipping-item-icon__color);
                @include lib-css(content, $checkout-shipping-item-icon__content);
                @include lib-css(font-family, $icons__font-name);
                @include lib-css(height, $checkout-shipping-item-icon__selected__height);
                @include lib-css(width, $checkout-shipping-item-icon__selected__width);
                font-size: 19px;
                line-height: 21px;
                padding-top: 2px;
                position: absolute;
                right: 0;
                text-align: center;
                top: 0;
            }

            .action-select-shipping-item {
                @extend .abs-no-display-s;
                visibility: hidden;
            }
        }
    }

    .field {
        &.addresses {
            @extend .abs-add-clearfix;
        }
    }

    .action-show-popup {
        margin: 0 0 $indent__base;

        > span {
            &:before {
                content: '+';
                padding-right: $indent__xs;
            }
        }
    }

    .action-select-shipping-item {
        float: right;
        margin: $indent__base 0 0;
    }

    .edit-address-link {
        @extend .abs-action-button-as-link;
        display: block;
        float: left;
        margin: 26px 5px 0 0;
    }
}

//
//  Shipping Methods
//  ---------------------------------------------

.checkout-shipping-method {
    .step-title {
        margin-bottom: 0;
    }

    .no-quotes-block {
        margin: $indent__base 0;
    }
}

.methods-shipping {
    .actions-toolbar {
        .action {
            &.primary {
                @extend .abs-button-l;
                margin: $indent__base 0 0;
                border-radius: 32px;
            }
        }
    }
}

.table-checkout-shipping-method {
    thead {
        th {
            display: none;
        }
    }

    tbody {
        td {
            @include lib-css(border-top, $checkout-shipping-method__border);
            @include lib-css(padding-bottom, $checkout-shipping-method__padding);
            @include lib-css(padding-top, $checkout-shipping-method__padding);

            &:first-child {
                padding-left: 0;
                padding-right: 0;
                width: 20px;
            }
        }

        tr {
            &:first-child {
                td {
                    border-top: none;
                }
            }
        }
        .row-error {
            td {
                border-top: none;
                padding-bottom: $indent__s;
                padding-top: 0;
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .opc-wrapper {
        .form-login {
            @include lib-css(border-bottom, 1px solid $checkout-shipping-item-before__border-color);
            @include lib-css(margin, $checkout-shipping-item-mobile__padding);
            @include lib-css(padding, $checkout-shipping-item-mobile__padding);
        }

        .shipping-address-item {
            @include lib-css(border-bottom, 1px solid $checkout-shipping-item-before__border-color);
            @include lib-css(margin, $checkout-shipping-item-mobile__margin);
            @include lib-css(padding, $checkout-shipping-item-mobile__padding);
            width: 100%;

            &.selected-item {
                @include lib-css(padding, $checkout-shipping-item-mobile__active__padding);
                border-bottom-width: 2px;

                .edit-address-link {
                    @include lib-css(right, $checkout-shipping-item-icon__selected__width + $indent__s);
                }
            }
        }

        .form-login,
        .form-shipping-address {
            @include lib-css(margin-top, $checkout-shipping-address__margin-top);
        }

        .action-select-shipping-item {
            float: none;
            margin-top: $indent__s;
            width: 100%;
        }

        .action-show-popup {
            width: 100%;
        }

        .edit-address-link {
            @include lib-icon-font(
                $icon-settings,
                $_icon-font-size         : 28px,
                $_icon-font-line-height  : 28px,
                $_icon-font-text-hide    : true,
                $_icon-font-color        : $color-gray19,
                $_icon-font-color-hover  : $color-gray19,
                $_icon-font-color-active : $color-gray19
            );
            margin: 0;
            position: absolute;
            right: 0;
            top: 1px;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .checkout-shipping-method {
        .actions-toolbar {
            > .primary {
                float: right;
            }

            .action {
                &.primary {
                    margin: 0;
                }
            }
        }
    }

    .opc-wrapper {
        .form-login,
        .form-shipping-address {
            @include lib-css(max-width, $checkout-shipping-address__max-width);
        }
    }
    .table-checkout-shipping-method {
        width: auto;
    }
}

@include min-screen($screen__l) {
    .opc-wrapper {
        .shipping-address-item {
            @include lib-css(width, $checkout-shipping-item__width);

            &:before {
                @include lib-css(background, $checkout-shipping-item-before__border-color);
                @include lib-css(height, $checkout-shipping-item-before__height);
                content: '';
                left: 0;
                position: absolute;
                top: 0;
                width: 1px;
            }

            &:nth-child(3n + 1) {
                &:before {
                    display: none;
                }
            }

            &.selected-item {
                &:before {
                    display: none;
                }

                + .shipping-address-item {
                    &:before {
                        display: none;
                    }
                }
            }
        }
    }
    .table-checkout-shipping-method {
        min-width: 500px;
    }
}
