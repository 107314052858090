.pages {
    @include lib-pager();

    .action {
        padding: 0 13px;

        &.previous {
            margin-right: 8px;
            padding-left: 10px;
        }

        &.next {
            margin-left: 8px;
            padding-right: 10px;
        }
    }
}
