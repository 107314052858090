.product-order-list {
    position: relative;
    max-width: $layout-small__max-width;
    margin: 0 auto;

    .warning {
        padding-top: 30px;
        padding-bottom: 30px;
        font-size: 16px;
        color: $djm-color-light-blue;
        text-align: center;
    }

    input[type=text] {
        padding-right: 35px;
        padding-left: 25px;
        border-radius: 30px;
        height: 45px;
        border-color: transparent;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    }

    &__top-section {
        position: relative;
        z-index: 1;
        margin-bottom: 40px;

        h2 {
            margin-bottom: 10px;
            font-size: 36px;
            font-family: $font-family__serif;
            color: $color-white;
        }

        p {
            font-size: 16px;
            color: $djm-color-light-blue;
        }
    }

    &__loader {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        background-color: #003366;
        z-index: 500;
        text-align: center;
        opacity: .5;
    }

    &__table-title {
        margin-bottom: 15px;
        font-size: 16px;
        font-weight: $font-weight__heavier;
        color: $color-white;
    }

    &__table-container {
        .blueprint-loader {
            text-align: center;
        }
    }

    &__search-container:not(.product-order-list__search-container--order-list) {
        position: relative;
        z-index: 30;
        margin: 0 auto 40px auto;
        max-width: 450px;
    }

    &__title {
        @include lib-heading(h3);
        font-weight: 400;
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        color: $color-white;
    }

    &__sku {
        font-size: 13px;
        font-weight: $font-weight__regular;
        color: $djm-color-light-gray;
    }

    &__stock {
        width: 13.5%;
        white-space: nowrap;
    }

    &__product-stock {
        display: flex;
        align-items: center;

        span {
            margin-right: 5px;
        }
    }

    &__amount-in-cart {
        @include lib-icon-font(
            $_icon-font-content: $icon-checkmark,
            $_icon-font-color: $djm-color-dark-green,
            $_icon-font-size: 12px
        );
        color: $djm-color-dark-green;
    }

    &__search-results {
        @include lib-list-reset-styles();
        box-sizing: border-box;
        position: absolute;
        top: calc(100% + 10px);
        width: 100%;
        background-color: #fff;
        border-radius: 3px;
        border: 1px solid #e2e5ed;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

        > li {
            margin: 0;

            &:not(:last-child) {
                border-bottom: 1px solid #e2e5ed;
            }

            &:hover {
                background-color: #f6f6f6;
            }
        }
    }

    &__search-item {
        padding: 10px 15px;
        cursor: pointer;

        &.out-of-stock {
            cursor: not-allowed;
        }
    }

    &__product-title {
        @extend .abs-product-link;
        display: flex;
        font-weight: 700;

        &-anchor {
            margin-right: 7px;
        }
    }

    &__image {
        max-width: 72px;
    }

    &__image-container {
        align-self: center;

        img {
            max-width: 72px;
        }
    }

    &__subtotal {
        width: 7%;
        text-align: center;
        justify-content: center;

        .price {
            white-space: nowrap;
        }
    }

    &__item {
        @include lib-block();
        position: relative;
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        padding: 0 15px;
        border-radius: 5px;

        &:first-child {
            border-top: 1px solid $border-color__base;
        }

        > *:not(.product-order-list__image-container) {
            display: flex;
            align-items: center;
        }
    }

    &__item & {
        &__product {
            width: 42%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
        }
    }

    &__remove {
        margin-right: 10px;
        padding: 12px 25px;
        box-shadow: $button__border__box-shadow;
        font-size: 15px;
        text-transform: initial;
        letter-spacing: initial;
    }

    td {
        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
            padding-left: 0;
        }
    }

    .control {
        float: left;
    }

    .tocart {
        @extend .abs-actions-addto-icon;
        @extend .abs-actions-addto-icon-plus;
        display: flex;
        align-items: center;
    }

    .all-to-cart {
        @extend .abs-actions-addto-icon;
        padding: 10px 25px;
        display: flex;
        align-items: center;
        margin-left: auto;

        &::before {
            margin-right: 10px;
        }
    }

    .add-to-cart {
        &__adding {
            background-color: $djm-color-light-gray3;
            border-radius: 19px;
            border: 1px solid $djm-color-light-gray3;
        }
    }

    .add-to-cart,
    .add-product-to-order-list {
        position: relative;

        &__adding {
            position: absolute;
            top: -1px;
            left: -1px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;

            .dot-loader {
                position: relative;
                height: 3px;
                width: 3px;
                background-color: $djm-color-gray;
                border-radius: 50%;
                animation: 1.2s grow ease-in-out infinite;
            }

            .dot-loader--2 {
                margin: 0 5px;
                animation: 1.2s grow ease-in-out infinite 0.15555s;
            }

            .dot-loader--3 {
                animation: 1.2s grow ease-in-out infinite 0.3s;
            }
        }
    }

    select  {
        color: $color-white;
    }
}

.product-order-list {
    &__body &__search {
        display: none;
    }
}

@include min-screen($screen__m) {
    .product-order-list {
        &__top-section & {
            &__search-container {
                width: 50%;
            }
        }

        &__search-item {
            display: flex;
            justify-content: space-between;
        }

        &__item {
            padding: 0 15px;

            &.out-of-stock {
                opacity: .4;

                &::after {
                    display: none;
                }
            }
        }
    }
}

@include max-screen($screen__m) {
    body.order_list-product-order {
        .product-order-list {
            &__qty {
                margin: 10px 0;
            }
        }
    }

    .product-order-list {
        &__body {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &__item {
            width: calc(50% - 10px);
            display: flex;
            flex-wrap: wrap;
            padding: 10px;

            td {
                padding: 0;
            }

            > * {
                box-sizing: border-box;
            }
        }

        &__item & {
            &__image-container,
            &__product,
            &__subtotal {
                width: auto;
            }

            &__image-container {
                margin-right: 10px;
                margin-bottom: auto;
            }

            &__product {
                flex: 1 1 50%;
            }

            &__stock {
                width: 100%;
                margin-top: 5px;
                margin-bottom: 5px;
            }

            &__amount-in-cart {
                display: none;
            }
        }
    }
}

@include max-screen($screen__s) {
    .product-order-list {
        &__item {
            width: 100%;
        }
    }
}

