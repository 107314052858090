@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/lato/hairline/Lato-Hairline',
    $font-weight: 100,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/lato/thin/Lato-Thin',
    $font-weight: 200,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/lato/light/Lato-Light',
    $font-weight: 300,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/lato/regular/Lato-Regular',
    $font-weight: 400,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/lato/medium/Lato-Medium',
    $font-weight: 500,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/lato/semibold/Lato-Semibold',
    $font-weight: 600,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/lato/bold/Lato-Bold',
    $font-weight: 700,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/lato/heavy/Lato-Heavy',
    $font-weight: 800,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/lato/black/Lato-Black',
    $font-weight: 900,
    $font-style: normal
);

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    h1 {
        @include lib-css(font-size, $h1__font-size-desktop);
        @include lib-css(margin-bottom, $h1__margin-bottom__desktop);
        letter-spacing: -0.02em;
    }
}

.items {
    @include lib-list-reset-styles();
}
