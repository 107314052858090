//
//  Variables
//  _____________________________________________

$checkout-sidebar__margin: $indent__base;
$checkout-sidebar__margin__xl: 46px;
$checkout-sidebar__columns: 8;

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .opc-sidebar {
        @include lib-css(margin, 0 0 $checkout-sidebar__margin);
        @include lib-layout-column(2, 2, $checkout-sidebar__columns);
    }
}
