//
//  Variables
//  _____________________________________________

$checkout-modal-popup__width: 800px;

.checkout-index-index {
    .modal-popup {
        .field-tooltip {
            .field-tooltip-content {
                @extend .abs-checkout-tooltip-content-position-top;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .checkout-index-index {
        .modal-popup {
            .form-shipping-address {
                @include lib-css(max-width, $checkout-shipping-address__max-width);
            }

            .modal-footer {
                .action-save-address {
                    float: right;
                    margin: 0 0 0 $indent__s;
                }
            }
        }
    }
}

@include min-screen($screen__l) {
    .checkout-index-index {
        .modal-popup {
            .modal-inner-wrap {
                @include lib-css(margin-left, -($checkout-modal-popup__width/2));
                @include lib-css(width, $checkout-modal-popup__width);
                left: 50%;
            }
        }
    }
}
