.breadcrumbs {
    @include lib-breadcrumbs();
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .breadcrumbs {
        white-space: nowrap;
        overflow-x: scroll;
    }
}
