.social-media {
    display: flex;
    flex-wrap: wrap;

    &__anchor {
        @include lib-icon-text-hide();
        @include lib-icon-font(
            $_icon-font-content: false,
            $_icon-font-size: 12px,
            $_icon-font-line-height: 1,
            $_icon-font-color: $color-white,
            $_icon-font-position: before
        );
        width: 35px;
        height: 35px;
        border: 1px solid $color-medium-blue4;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s ease;

        &:not(:last-child) {
            margin-right: 8px;
        }

        &.facebook,
        &.instagram {
            @include lib-icon-font-size(13px, 1);
        }

        &.linkedin,
        &.youtube {
            @include lib-icon-font-size(14px, 1);
        }

        &.twitter {
            @include lib-icon-font-symbol($icon-twitter);
        }

        &.facebook {
            @include lib-icon-font-symbol($icon-facebook);
        }

        &.linkedin {
            @include lib-icon-font-symbol($icon-linkedin);
        }

        &.youtube {
            @include lib-icon-font-symbol($icon-youtube);
        }

        &.instagram {
            @include lib-icon-font-symbol($icon-instagram);
        }

        &:hover {
            border: 1px solid $color-light-blue4;
            text-decoration: none;
        }
    }
}
@include max-screen($screen__m) {
    .social-media {
        margin-bottom: 30px;
    }
}
