@include max-screen($screen__l) {
    .add-product-to-order-list {
        @include lib-icon-font(
            $_icon-font-content     : $icon-list-plus,
            $_icon-font-color       : $primary__color__lighter,
            $_icon-font-size        : 26px,
            $_icon-font-line-height : 1,
            $_icon-font-display     : block
        );
    }
}

@include min-screen($screen__l) {
    .add-product-to-order-list {
        @include lib-icon-font(
            $_icon-font-content     : $icon-list-plus,
            $_icon-font-color       : $primary__color__lighter,
            $_icon-font-size        : 18px,
            $_icon-font-line-height : 1,
            $_icon-font-display     : block
        );
    }
}

.add-product-to-order-list {
    cursor: pointer;

    &:disabled::before {
        color: lighten($primary__color__lighter, 30%);
    }

    &:hover,
    &:focus {
        background-color: transparent;
    }

    &:focus,
    &:active {
        box-shadow: none;
    }
}

.product-order-list--alternative {
    .qty-select {
        &__button {
            &:not(.qty-select__button--decrease) {
                pointer-events: none;
            }

            &::before {
                color: $djm-color-light-gray2;
            }

            &.qty-increasable {
                background-color: darken($color-light-gray1, 4%);
                pointer-events: initial;

                &::before {
                    color: $djm-color-gray;
                }
            }
        }
    }
}
