//  Color variables

// Footer
$footer__background-color: $color-dark-blue-bg;

//
//  Footer
//  ---------------------------------------------

.page-footer {
    position: relative;
    @include lib-css(background-color, $footer__background-color);
    margin-top: auto;

    .widget.block {
        @include lib-css(margin, $indent__base 0);
    }
}

.footer {
    .copyright {
        span {
            &:not(:last-child) {
                margin-right: 5px;
            }
        }

        a {
            color: $color-white;
            font-weight: $font-weight--semibold;
        }
    }

    .copyright,
    address,
    a {
        font-size: 12px;
        color: $color-light-blue4;
    }

    &__menus {
        display: flex;
        justify-content: space-between;
        padding-right: 20px;
        padding-left: 20px;

        h4 {
            margin-bottom: 14px;
            font-weight: $font-weight__bold;
            font-size: 13px;
            text-transform: uppercase;
            color: $color-white;
            letter-spacing: 1px;
        }

        li,
        address {
            line-height: 23px;
        }
    }

    &__menu {
        display: flex;
        flex-direction: column;
        width: 20%;

        p {
            margin: 0;
        }

        ul {
            @extend .abs-reset-list;
        }

        li,
        p {
            color: $color-sky-blue2;
        }

        .contact-info {
            display: flex;
            flex-direction: column;
            padding: 30px 0;

            li {
                display: flex;
            }
        }

        &:last-child {
            p {
                a {
                    text-decoration: underline;
                }
            }
        }
    }

    &__socials {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }

    &__social {
        @include lib-icon-font(
            $_icon-font-content: false,
            $_icon-font-color: $color-sky-blue2,
            $_icon-font-size: 16px,
            $_icon-font-line-height: 1
        );
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s ease;

        &:hover {
            text-decoration: none;

            &:before {
                color: $color-white;
            }
        }

        &:not(:last-child) {
            margin-right: 20px;
        }

        &.twitter {
            @include lib-icon-font-symbol(
                $_icon-font-content: $icon-twitter
            );
        }

        &.youtube {
            @include lib-icon-font-symbol(
                $_icon-font-content: $icon-youtube
            );
        }

        &.facebook {
            @include lib-icon-font-symbol(
                $_icon-font-content: $icon-facebook
            );
        }

        &.linkedin {
            @include lib-icon-font-symbol(
                $_icon-font-content: $icon-linkedin
            );
        }

        &.instagram {
            @include lib-icon-font-symbol(
                $_icon-font-content: $icon-instagram
            );
        }
    }

    &.content {
        padding: 50px 0 30px;

        .links {
            > li {
                margin: 0 0 8px;
            }
        }

        .switcher-store {
            margin: 0 0 30px;
        }
    }
}

.bottom-footer {
    padding: 0 20px 40px;

    &__top {
        display: flex;
        align-items: center;
        padding: 30px 0;
    }

    &__logo {
        margin-right: 30px;

        img {
            height: 32px;
        }
    }

    .menu {
        &__mobile {
            display: none;
        }

        ul {
            margin-top: -7px;
            margin-bottom: 0;
        }

        li {
            display: inline;

            &:not(:last-child) {
                margin-right: 25px;
            }
        }
    }

    &__bottom {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        color: $color-light-blue4;
        border-top: 1px solid $color-medium-blue4;
        padding-top: 20px;

        ul {
            display: flex;

            li {
                &:not(:last-child) {
                    padding-right: 30px;
                }
            }
        }
    }

    .menu {
        &__mobile {
            display: none;
        }
    }

    .quality-marks {
        img {
            &.rva {
                height: 41px;
                position: relative;
                top: 5px;
                margin-top: -6px;
            }

            &:not(:last-child) {
                margin-right: 25px;
            }
        }
    }
}

//
//  Desktop
//  ---------------------------------------------

@include min-screen($screen__m) {
    .footer {
        &__menus {
            margin-bottom: 50px;
        }

        &__menu {
            .contact-info {
                strong {
                    min-width: 80px;
                }
            }
        }

        &.content {
            .block {
                float: right;
            }

            .links {
                display: inline-block;
                margin-bottom: 20px;
                padding: 0 50px 0 0;
                vertical-align: top;
            }

            .switcher.store {
                display: inline-block;
                padding-right: 50px;
                vertical-align: top;
            }
        }

        .copyright {
            @extend .abs-add-clearfix-desktop;
        }
    }

    .page-footer {
        &::before {
            content: '';
            position: absolute;
            z-index: -1;
            bottom: 100%;
            left: 0;
            right: 0;
            height: 517px;
            background-image: url('../images/footerbg.jpg');
            background-repeat: repeat-x;
            background-position: bottom center;
        }
    }
}

@include max-screen($screen__l) {
    .bottom-footer {
        .copyright {
            width: 100%;
            margin-top: 10px;
            order: 3;
        }
    }
}

//
//  Mobile
//  ---------------------------------------------

@include max-screen($screen__m) {
    .page-footer {
        margin-bottom: $footer-bottom__height;
    }

    .bottom-footer {
        padding: 0 10px;

        &__top {
            flex-direction: column;
            text-align: center;
            padding-bottom: 40px;
        }

        &__logo {
            margin-right: 0;
            margin-bottom: 15px;
        }

        &__bottom {
            justify-content: center;

            ul {
                flex-wrap: wrap;
                justify-content: center;

                li {
                    padding-bottom: 10px;
                }
            }
        }
    }

    .footer {
        &__menu {
            width: 100%;

            .contact-info {
                li {
                    display: block;

                    strong {
                        padding-right: 5px;
                    }
                }
            }
        }

        &__menus {
            justify-content: center;
            text-align: center;
        }

        &__menu:not(:last-child) {
            display: none;
        }

        &__socials {
            margin-bottom: 15px;
        }

        &.content {
            padding: 40px 0;
        }
    }

    .menu,
    .quality-marks,
    .copyright {
        width: 100%;
        margin-bottom: 15px;
        text-align: center;
    }
}

@include max-screen($screen__s) {
    .bottom-footer {
        & &__top {
            .menu__list {
                display: flex;
                flex-direction: column;
                padding: 0;

                li {
                    margin-right: 0;
                    margin-bottom: 4px;
                }
            }
        }
    }

    .copyright span {
        display: block;
    }
}
