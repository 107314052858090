//
//  Messages
//  _____________________________________________

.messages .messages {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    max-width: $layout-small__max-width;
    width: 100%;
    z-index: $z-index-5;
    box-sizing: border-box;
    opacity: 0;
    transition: all .2s ease-in-out;

    &.show {
        opacity: 1;
    }
}

.message-hide {
    @include lib-icon-font(
        $_icon-font-content: $icon-remove,
        $_icon-font-size: 14px
    );
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    cursor: pointer;
}

.message.info {
    @include lib-message-icon-inner(info);
}

.message.error {
    @include lib-message-icon-inner(error);
}

.message.warning {
    @include lib-message-icon-inner(warning);
}

.message.notice {
    @include lib-message-icon-inner(notice);
}

.message.success {
    @include lib-message-icon-inner(success);
}

@include max-screen($screen__m) {
    .messages {
        padding: 0 15px;

        .message {
            font-size: 13px;

            > *:first-child::before {
                font-size: 18px;
            }
        }
    }
}
