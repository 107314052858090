.actions-toolbar {
    > .primary,
    > .secondary {
        text-align: center;

        .action {
            @extend .abs-button-responsive;
            margin-bottom: $indent__s;

            &:last-child {
                margin-bottom: 0;
            }
        }

        > .primary {
            border-radius: 0;
        }

        margin-bottom: $indent__s;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
