//
//  Theme variables
//  _____________________________________________

//  Messages
$message-global-note__background           : $color-yellow-light2;
$message-global-note__border-color         : $color-yellow-light3;
$message-global-note__color                : $text__color;

$message-global-note-link__color           : $link__color;
$message-global-note-link__color-hover     : $link__hover__color;
$message-global-note-link__color-active    : $link__active__color;

$message-global-caution__background        : $color-red9;
$message-global-caution__border-color      : none;
$message-global-caution__color             : $color-white;

$message-global-caution-link__color        : $link__color;
$message-global-caution-link__color-hover  : $link__hover__color;
$message-global-caution-link__color-active : $link__active__color;

$addto-color                               : $text__color__muted;
$addto-hover-color                         : $primary__color;

$minicart-icons-color                      : $header-icons-color;
$minicart-icons-color-hover                : $header-icons-color-hover;

$h1__margin-bottom__desktop                : $indent__xl;

.transition-hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 400ms, opacity 300ms;
}

.transition-visible {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity 300ms;
}

body {
    @include lib-css(background-color, $page__background-color);
}

._has-modal {
    &::after {
        background: #001b48;
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        opacity: 30%;
        content: '';
        z-index: 400;
    }
}

.blueprint-loader {
    img {
        animation: rotate-loader 0.7s infinite;
    }
}

.loading-mask {
    .loader {
        img {
            animation: rotate-loader 0.7s infinite;
        }
    }
}

@keyframes rotate-loader {
    0%   { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.logo {
    float: left;
    max-width: 50%;
    position: relative;
    z-index: 5;

    img {
        display: block;
    }

    .page-print & {
        float: none;
    }
}

.page-main {
    > .page-title-wrapper {
        .page-title + .action {
            margin-top: $indent__l;
        }
    }
}

.action.skip {
    &:not(:focus) {
        @extend .abs-visually-hidden;
    }

    &:focus {
        @include lib-css(background, $color-gray94);
        @include lib-css(padding, $indent__s);
        box-sizing: border-box;
        left: 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;
        z-index: 15;
    }
}

.action-skip-wrapper {
    height: 0;
    position: relative;
}

//
//  Global notice
//  ---------------------------------------------

.message.global {
    p {
        margin: 0;
    }

    &.noscript,
    &.cookie {
        @include lib-message($_message-type: global-note);
        margin: 0;
    }

    &.cookie {
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        z-index: 3;

        .actions {
            margin-top: $indent__s;
        }
    }

    &.demo {
        @include lib-message($_message-type: global-caution);
        margin-bottom: 0;
        text-align: center;
    }
}

.page-header,
.page-footer {
    .switcher {
        margin-right: 10px;

        .options {
            @include lib-dropdown(
                $_dropdown-actions-padding            : 0,
                $_dropdown-list-item-padding          : 0,
                $_dropdown-toggle-icon-content        : $icon-down,
                $_dropdown-toggle-active-icon-content : $icon-up,
                $_icon-font-text-hide                 : true,
                $_icon-font-size                      : 22px,
                $_icon-font-line-height               : 22px,
                $_dropdown-list-min-width             : 160px
            );

            ul.dropdown {
                a {
                    display: block;
                    padding: 8px;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }

        li {
            font-size: $font-size__s;
            margin: 0;
        }

        .label {
            @extend .abs-visually-hidden;
        }

        strong {
            font-weight: $font-weight__regular;
        }
    }
}

.bottom-links {
    display: none;
    position: fixed;
    z-index: 30;
    right: 0;
    bottom: 0;
    left: 0;
    height: $footer-bottom__height;
    align-items: stretch;
    background-color: $color-white;
    box-shadow: -1px 0 4px rgba(0, 0, 0, 0.3);

    &__item {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        color: $color-gray-text;

        &:not(:first-child) {
            border-left: 1px solid $border-color__base;
        }

        &,
        &:visited {
            color: $bottom-links-text-color;
        }

        &:hover {
            text-decoration: none;
            color: $bottom-links-text-color-hover;
        }
    }

    &__item:hover &__title {
        color: $bottom-links-text-color-hover;
    }

    &__title {
        text-transform: uppercase;
        color: $color-gray-text-light;
        font-size: 9px;
        font-weight: 800;
        letter-spacing: 0.03em;
        color: $bottom-links-text-color;
    }

    &__wishlist {
        @include lib-icon-font(
            $icon-menu,
            $_icon-font-size: 18px,
            $_icon-font-display: flex
        );
    }

    &__search {
        @include lib-icon-font(
            $icon-search,
            $_icon-font-size: 22px,
            $_icon-font-display: flex
        );
        cursor: pointer;

        .label {
            cursor: pointer;
        }

        .label::before {
            margin: 0;
            color: $bottom-links-text-color;
            font-size: 19px;
        }
    }

    &__account {
        @include lib-icon-font(
            $icon-account,
            $_icon-font-size: 22px,
            $_icon-font-display: flex
        );
    }

    &__cart {
        @include lib-icon-font(
            $icon-cart,
            $_icon-font-size: 20px,
            $_icon-font-display: flex
        );
    }
}

.mobile-home-banner {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    padding: 20% 15px;
    color: $color-white;
    background-image: url('../images/mobilebanner.jpg');
    background-size: cover;
    text-align: center;
    border-radius: $block-border-radius;
    box-shadow: $block-shadow;

    h2 {
        margin-bottom: 10px;
        font-weight: 800;
        text-transform: uppercase;
        font-size: 40px;
    }

    p {
        font-weight: $font-weight__semibold;
        font-size: 16px;
    }

    .action.primary {
        padding-left: 50px;
        padding-right: 50px;
        background-color: #16a3ff;
        text-transform: uppercase;
        border: 0;
        font-weight: $font-weight__bold;
    }
}

//
//  Mobile
//  ---------------------------------------------

@include max-screen($screen__m) {
    #maincontent {
        min-height: 50px;
    }

    .logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        margin: 0;
        max-width: none;

        img {
            max-width: none;
            width: 200px;
        }
    }
}

//
//  Widgets
//  ---------------------------------------------

.sidebar {
    .widget.block:not(:last-child),
    .widget:not(:last-child) {
        @extend .abs-margin-for-blocks-and-widgets;
    }
}

.widget {
    clear: both;

    .block-title {
        @extend .abs-block-title;
    }
}

.no-display {
    @extend .abs-no-display;
}

.sidebar-block {
    margin-bottom: 40px;
    padding: $indent__m;
    background-color: rgba(10, 45, 103, 0.33);
    border-radius: $block-border-radius;

    h4 {
        margin: 0 0 25px;
        color: #fff;
        font-weight: $font-weight__bold;
        @include lib-font-size(16);
        text-transform: uppercase;
    }

    ul {
        @extend .abs-reset-list;

        > li {
            display: block;
            margin: 10px 0 0;
        }
    }

    a {
        color: $text__color__light;
        @include lib-font-size(15);
    }
}

//
//  Calendar
//  ---------------------------------------------

.ui-datepicker {
    td {
        padding: 0;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    html,
    body {
        height: 100%; // Stretch screen area for sticky footer
    }

    body {
        min-height: 1385px;
    }

    .page-wrapper {
        @include lib-vendor-prefix-display(flex);
        @include lib-vendor-prefix-flex-direction(column);
        min-height: 100%; // Stretch content area for sticky footer

        > .breadcrumbs,
        > .top-container,
        > .widget {
            box-sizing: border-box;
            width: 100%;
        }

        .ie10 &,
        .ie11 & {
            height: 100%;
        }
    }

    .navigation ul {
        padding: 0 8px;
    }

    .page-main {
        > .page-title-wrapper {
            .page-title {
                display: inline-block;
            }

            .page-title + .action {
                float: right;
                margin-top: $indent__base;
            }
        }
    }

    .logo {
        img {
            height: 44px;
            width: 300px;
        }
    }

    .home-intro {
        margin: 0 auto;
        max-width: 50%;

        h1,
        h2 {
            font-size: 44px;
        }
    }

    .page-info {
        h1,
        h2 {
            font-size: 32px;
        }

        &__left {
            flex: 1;
        }

        &__row {
            display: flex;
        }
    }

    .blue-block {
        padding: 25px;
        background-color: #00a8ff;
        border-radius: 8px;
        width: 30%;
        margin-left: 50px;

        &__block {
            padding-left: 70px;

            &::before {
                position: absolute;
                top: -10px;
                left: 0;
            }
        }
    }

    .mobile-home-banner {
        display: none;
    }
}

.home-intro,
.page-info {
    margin-bottom: 70px;

    h1,
    h2,
    h3,
    h4,
    h5 {
        color: $text__color__lighter;
    }

    h1,
    h2 {
        margin-top: $indent__s;
        font-family: $font-family__serif;
    }

    h5 {
        margin-top: 0;
        color: $text__color__lighter;
        text-transform: uppercase;
        font-weight: $font_weight__bold;
        letter-spacing: 0.08em;
        font-size: 13px;
    }

    p {
        color: $text__color__light;
    }
}

.home-intro {
  text-align: center;

  p {
    font-size: 19px;
    font-weight: 500;
  }
}

.page-info {
    position: relative;
    max-width: 900px;
    margin: 80px auto;

    &__left {
        p {
            @include lib-font-size(17);
        }
    }

    .blue-block {
        p {
            @include lib-font-size(15);
        }
    }
}

@include screen($screen__m, $screen__l) {
    .logo {
        max-width: 30%;
    }
}

@include max-screen($screen__l) {
    .log-in-block,
    .header__usps {
        display: none;
    }
}

@include max-screen($screen__xl) {
    .bottom-links {
        display: flex;
    }
}

@include min-screen($screen__l) {
    .home-intro {
        p {
            max-width: 70%;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.log-in-block {
    .action {
        padding: $button__padding__l;
        text-transform: uppercase;
        font-weight: 800;
        font-size: 13px;
        background: $button-light-blue__background;
        border-color: $button-light-blue__background;
        letter-spacing: 0.1em;

        @include lib-icon-font(
            $icon-account,
            $_icon-font-size: 18px,
            $_icon-font-margin: -5px 8px 0 0,
            $_icon-font-line-height: 1
        );

        &:hover,
        &:focus {
            background: darken($button-light-blue__background, 3%);
            border-color: darken($button-light-blue__background, 3%);
        }
    }

    span,
    a {
        color: $color-gray-text-light;
    }

    span {
        margin-left: 10px;
        font-size: 16px;
    }

    a {
        border-bottom: 1px solid;

        &:hover {
            text-decoration: none;
            color: darken(#8e9ea3, 4%);
            border-color: darken(#8e9ea3, 4%);
        }
    }
}

.logged-in-actions {
    display: flex;
    align-items: center;
}

.page-title {
    color: $text__color__lighter;
}

.cms-page-view,
.customer-account-logoutsuccess,
.contact-index-index,
.vacancy_page-application-form,
.cms-noroute-index {
    .column.main {
        @include lib-block();
        margin-bottom: $indent__l;

        :first-child {
            margin-top: 0;
        }

        &.no-bg {
            background: none;
            box-shadow: none;
        }
    }
}

.mobile-phone-icon {
    display: none;

    a {
        @include lib-icon-font(
            $icon-contact,
            $_icon-font-size    : 30px,
            $_icon-font-color   : $header-icons-mobile-color,
            $_icon-font-color-hover : $header-icons-mobile-color-hover
        );
    }
}

@include max-screen($screen__m) {
    .home-intro,
    .logged-in-actions,
    .page-header .header .header-icons,
    .header__links {
        display: none;
    }

    .mobile-phone-icon {
        display: block;
    }

    .page-info {
        margin: 0;

        h5 {
            display: none;
        }
    }

    .blue-block {
        display: flex;
        flex-wrap: wrap;
        margin-top: 30px;
        margin-right: -10px;
        margin-left: -10px;

        &__block {
            box-sizing: border-box;
            width: 50%;
            padding-right: 10px;
            padding-left: 10px;
            text-align: center;

            &-title {
                font-size: 14px;
            }
        }
    }
}

.blue-block {
    &__block {
        position: relative;

        &:not(:last-of-type) {
            margin-bottom: 20px;
        }

        &-title {
            margin-top: 0;
            margin-bottom: 5px;
            font-size: 16px;
            line-height: 1.4;
            text-transform: uppercase;
            font-weight: $font_weight__bold;
        }

        &-paragraph {
            margin-bottom: 0;
            font-weight: 500;
        }

        &--fish {
            @include lib-icon-font(
                $icon-fish,
                $_icon-font-size: 50px,
                $_icon-font-color: $color-white
            );
        }

        &--quality {
            @include lib-icon-font(
                $icon-medal,
                $_icon-font-size: 50px,
                $_icon-font-color: $color-white
            );
        }
    }
}

.validation-message-box {
    div.mage-error {
        @include lib-message(error);
    }
}

.miniwishlist-wrapper {
    margin-left: 28px;

    a {
        @include lib-icon-font(
                $icon-wishlist,
            $_icon-font-size: 16px,
            $_icon-font-color: $header-icons-color,
            $_icon-font-color-hover: $header-icons-color-hover
        );
    }

    .text {
        @extend .abs-visually-hidden;
    }
}

@include max-screen($screen__l) {
    .page.messages {
        pointer-events: none;

        .messages.show {
            pointer-events: auto;
        }
    }
}
