//
//  Variables
//  _____________________________________________

$checkout-wrapper__margin: $indent__base;
$checkout-wrapper__columns: 16;

$checkout-step-title__border: $border-width__base solid $color-gray80;
$checkout-step-title__font-size: 26px;
$checkout-step-title__font-weight: $font-weight__light;
$checkout-step-title__padding: $indent__s;

$checkout-step-title-mobile__font-size: 18px;

.checkout-index-index {
    .page-title-wrapper {
        @extend .abs-visually-hidden;
    }
}

.checkout-container {
    @extend .abs-add-clearfix;
    @include lib-css(margin, 0 0 $checkout-wrapper__margin);
}

.opc-wrapper {
    @include lib-css(margin, 0 0 $checkout-wrapper__margin);

    .opc {
        @extend .abs-reset-list;
    }

    .step-title {
        @extend .abs-checkout-title;
        color: $color-white;
    }

    .step-content {
        @include lib-block();
        margin: 0 0 $indent__xl;

        .step-title {
            color: $color-gray-text;
        }
    }

    .actions-toolbar {
        display: flex;
    }

    .payment-group {
        button {
            span {
                font-size: 14px;
            }
        }
    }
}

#shipping-method-buttons-container {
    .button {
        font-size: 14px;
        font-weight: 800;
    }
}

.checkout-index-index {
    .nav-sections,
    .nav-toggle {
        display: none;
    }

    .logo {
        margin-left: 0;
    }
}

.checkout-onepage-success {
    @extend .abs-add-clearfix;

    .print {
        display: none;
    }
}

.checkout-success {
    @include lib-block();

    .actions-toolbar > .primary .action {
        width: auto;
        border-radius: 32px;
    }
}

//
//  Tablet
//  _____________________________________________

@include max-screen($screen__l) {
    .checkout-index-index {
        .page-header {
            padding: 20px 0;
        }
    }

    .items-in-cart {
        .product-item-details {
            padding-left: 10px;
        }
    }

    .modal-inner-wrap .modal-content {
        .opc-block-summary {
            color: #82898e;
            background-color: #f4f4f4;
        }

        .price,
        .amount {
            color: #82898e;
        }

        .minicart-items .product-item {
            padding-top: 20px;
        }

        .opc-block-summary > .title,
        .items-in-cart > .title {
            border-bottom: 1px solid #c1c1c1;
            color: #515253;
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .opc-wrapper {
        .step-title {
            @include lib-css(font-size, $checkout-step-title-mobile__font-size);
            border-bottom: 0;
            padding-bottom: 0;
        }
    }
}

//
//  Mobile M
//  _____________________________________________

@include max-screen($screen__m) {
    .checkout-index-index {
        .modal-popup.agreements-modal {
            left: 0;

            .modal-inner-wrap {
                height: 0;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    body.page-layout-checkout {
        min-height: 1100px;

        .page-wrapper {
            background-image: url('../images/footerbg.jpg');
            background-repeat: repeat-x;
            background-position: left bottom;
        }
    }

    .opc-wrapper {
        @extend .abs-add-box-sizing-desktop-m;
        @include lib-layout-column(2, 1, $checkout-wrapper__columns);
        padding-right: $indent__l;
    }

    .checkout-onepage-success {
        .print {
            display: block;
            margin: 20px 0 0;

            @include lib-icon-font(
                $_icon-font-content: $icon-print,
                $_icon-font-size: 20px,
                $_icon-font-position: before,
                $_icon-font-display: block,
                $_icon-font-margin: 0 5px 0 0
            );
        }
    }

    .checkout-index-index {
        .modal-popup.agreements-modal {
            .modal-inner-wrap {
                max-height: 600px;
                margin: 0 auto;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                max-width: 625px;
            }
        }
    }
}

body.page-layout-checkout {
    .page-header {
        margin-bottom: 25px;
    }

    .header.content {
        padding: 10px 0;
    }
}

.checkout-shipping-address,
.checkout-shipping-method {
    .step-title {
        font-family: $font-family__serif;
    }
}
