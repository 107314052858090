//
//  Forms
//  _____________________________________________

@mixin lib-form-element-input(
    $_type                   : $form-element-input-type,
    $_background             : null,
    $_border                 : null,
    $_border-radius          : null,
    $_height                 : null,
    $_width                  : null,
    $_padding                : null,
    $_margin                 : null,
    $_vertical-align         : null,
    $_background-clip        : null,
    $_font-size              : null,
    $_color                  : null,
    $_font-family            : null,
    $_font-weight            : null,
    $_line-height            : null,
    $_font-style             : null,
    $_placeholder-color      : null,
    $_placeholder-font-style : null,
    $_disabled-background    : null,
    $_disabled-border        : null,
    $_disabled-opacity       : null,
    $_disabled-color         : null,
    $_disabled-font-style    : null,
    $_focus-background       : null,
    $_focus-border           : null,
    $_focus-color            : null,
    $_focus-font-style       : null,
    $_option-color           : null
) {
    @if $_type == "input-text" {
        $_background             : $input-text__background !default;
        $_background-clip        : $input-text__background-clip !default;
        $_border                 : $input-text__border !default;
        $_border-radius          : $input-text__border-radius !default;
        $_color                  : $input-text__color !default;
        $_disabled-background    : $input-text__disabled__background !default;
        $_disabled-border        : $input-text__disabled__border !default;
        $_disabled-color         : $input-text__disabled__color !default;
        $_disabled-font-style    : $input-text__disabled__font-style !default;
        $_disabled-opacity       : $input-text__disabled__opacity !default;
        $_focus-background       : $input-text__focus__background !default;
        $_focus-border           : $input-text__focus__border !default;
        $_focus-color            : $input-text__focus__color !default;
        $_focus-font-style       : $input-text__focus__font-style !default;
        $_font-family            : $input-text__font-family !default;
        $_font-size              : $input-text__font-size !default;
        $_font-style             : $input-text__font-style !default;
        $_font-weight            : $input-text__font-weight !default;
        $_height                 : $input-text__height !default;
        $_line-height            : $input-text__line-height !default;
        $_margin                 : $input-text__margin !default;
        $_padding                : $input-text__padding !default;
        $_placeholder-color      : $input-text-placeholder__color !default;
        $_placeholder-font-style : $input-text-placeholder__font-style !default;
        $_vertical-align         : $input-text__vertical-align !default;
        $_width                  : $input-text__width !default;
    }

    @if $_type == "select" {
        $_background             : $select__background !default;
        $_background-clip        : $select__background-clip !default;
        $_border                 : $select__border !default;
        $_border-radius          : $select__border-radius !default;
        $_color                  : $select__color !default;
        $_disabled-background    : $select__disabled__background !default;
        $_disabled-border        : $select__disabled__border !default;
        $_disabled-color         : $select__disabled__color !default;
        $_disabled-font-style    : $select__disabled__font-style !default;
        $_disabled-opacity       : $select__disabled__opacity !default;
        $_focus-background       : $select__focus__background !default;
        $_focus-border           : $select__focus__border !default;
        $_focus-color            : $select__focus__color !default;
        $_focus-font-style       : $select__focus__font-style !default;
        $_font-family            : $select__font-family !default;
        $_font-size              : $select__font-size !default;
        $_font-style             : $select__font-style !default;
        $_font-weight            : $select__font-weight !default;
        $_height                 : $select__height !default;
        $_line-height            : $select__line-height !default;
        $_margin                 : $select__margin !default;
        $_padding                : $select__padding !default;
        $_placeholder-color      : $select-placeholder__color !default;
        $_placeholder-font-style : $select-placeholder__font-style !default;
        $_vertical-align         : $select__vertical-align !default;
        $_width                  : $select__width !default;
        $_option-color           : $option__color !default;
    }

    @if $_type == "textarea" {
        $_background             : $textarea__background !default;
        $_background-clip        : $textarea__background-clip !default;
        $_border                 : $textarea__border !default;
        $_border-radius          : $textarea__border-radius !default;
        $_color                  : $textarea__color !default;
        $_disabled-background    : $textarea__disabled__background !default;
        $_disabled-border        : $textarea__disabled__border !default;
        $_disabled-color         : $textarea__disabled__color !default;
        $_disabled-font-style    : $textarea__disabled__font-style !default;
        $_disabled-opacity       : $textarea__disabled__opacity !default;
        $_focus-background       : $textarea__focus__background !default;
        $_focus-border           : $textarea__focus__border !default;
        $_focus-color            : $textarea__focus__color !default;
        $_focus-font-style       : $textarea__focus__font-style !default;
        $_font-family            : $textarea__font-family !default;
        $_font-size              : $textarea__font-size !default;
        $_font-style             : $textarea__font-style !default;
        $_font-weight            : $textarea__font-weight !default;
        $_height                 : $textarea__height !default;
        $_line-height            : $textarea__line-height !default;
        $_margin                 : $textarea__margin !default;
        $_padding                : $textarea__padding !default;
        $_placeholder-color      : $textarea-placeholder__color !default;
        $_placeholder-font-style : $textarea-placeholder__font-style !default;
        $_vertical-align         : $textarea__vertical-align !default;
        $_width                  : $textarea__width !default;
    }

    @if $_type == "input-radio" {
        $_disabled-opacity : $input-radio__disabled__opacity !default;
        $_margin           : $input-radio__margin !default;
        $_vertical-align   : $input-radio__vertical-align !default;
    }

    @if $_type == "input-checkbox" {
        $_disabled-opacity : $input-checkbox__disabled__opacity !default;
        $_margin           : $input-checkbox__margin !default;
        $_vertical-align   : $input-checkbox__vertical-align !default;
    }
    @include lib-css(background, $_background);
    @include lib-css(background-clip, $_background-clip);
    @include lib-css(border, none);
    @include lib-css(box-shadow, $box-shadow-border);
    @include lib-css(border-radius, $_border-radius);
    @include lib-css(color, $_color);
    @include lib-css(font-family, $_font-family);
    @include lib-css(font-size, $_font-size);
    @include lib-css(font-style, $_font-style);
    @include lib-css(font-weight, $_font-weight);
    @include lib-css(height, $_height);
    @include lib-css(line-height, $_line-height);
    @include lib-css(margin, $_margin);
    @include lib-css(padding, $_padding);
    @include lib-css(vertical-align, $_vertical-align);
    @include lib-css(width, $_width);
    box-sizing: border-box;

    option {
        @include lib-css(color, $_option-color);
    }

    @include _lib-form-element-focus(
        $_background,
        $_focus-background,
        $_border,
        $_focus-border,
        $_color,
        $_focus-color,
        $_font-style,
        $_focus-font-style
    );

    @include _lib-form-element-disabled(
        $_background,
        $_disabled-background,
        $_border,
        $_disabled-border,
        $_color,
        $_disabled-color,
        $_font-style,
        $_disabled-font-style,
        $_disabled-opacity
    );

    @include _lib-form-element-placeholder(
        $_type,
        $_placeholder-color,
        $_font-style,
        $_placeholder-font-style
    );
}

@mixin lib-form-element-choice(
    $_type             : $form-element-choice__type,
    $_vertical-align   : null,
    $_margin           : null,
    $_disabled-opacity : null
) {
    @if $_type == "radio" {
        $_vertical-align   : $radio__vertical-align !default;
        $_margin           : $radio__margin !default;
        $_disabled-opacity : $radio__disabled__opacity !default;
    }

    @if $_type == "checkbox" {
        $_vertical-align   : $checkbox__vertical-align !default;
        $_margin           : $checkbox__margin !default;
        $_disabled-opacity : $checkbox__disabled__opacity !default;
    }

    @include lib-css(margin, $_margin);
    @include lib-css(vertical-align, $_vertical-align);

    &:disabled {
        @include _lib-form-element-add-opacity($_disabled-opacity);
    }
}

@mixin lib-form-element-number-reset() {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    -moz-appearance: textfield;
}

@mixin lib-form-element-search-reset() {
    -webkit-appearance: none;
    &::-webkit-search-cancel-button,
    &::-webkit-search-decoration,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
        -webkit-appearance: none;
    }
}

@mixin lib-form-element-textarea-resize($_textarea-resize: $textarea__resize) {
    @include lib-css(resize, $_textarea-resize);
}

@mixin lib-form-element-color(
    $_border-color          : null,
    $_background            : null,
    $_color                 : null,
    $_focus-border-color    : null,
    $_focus-background      : null,
    $_focus-color           : null,
    $_disabled-border-color : null,
    $_disabled-background   : null,
    $_disabled-color        : null,
    $_placeholder-color     : null
) {
    @include lib-css(background, $_background);
    @include lib-css(border-color, $_border-color);
    @include lib-css(color, $_color);

    &:focus {
        @include _lib-form-element-state-add-border-color($_focus-border-color, $_border-color);
        @include _lib-form-element-state-add-background($_focus-background, $_background);
        @include _lib-form-element-state-add-font-color($_focus-color, $_color);
    }

    &:disabled {
        @include _lib-form-element-state-add-border-color($_disabled-border-color, $_border-color);
        @include _lib-form-element-state-add-background($_disabled-background, $_background);
        @include _lib-form-element-state-add-font-color($_disabled-color, $_color);
    }

    &::-moz-placeholder {
        @include lib-css(color, $_placeholder-color);
    }

    &::-webkit-input-placeholder {
        @include lib-css(color, $_placeholder-color);
    }

    &:-ms-input-placeholder {
        @include lib-css(color, $_placeholder-color);
    }
}

@mixin lib-form-validation(
    $_element-color-error        : $form-element-validation__color-error,
    $_element-color-valid        : $form-element-validation__color-valid,
    $_element-border-color-error : $form-element-validation__border-error,
    $_element-border-color-valid : $form-element-validation__border-valid,
    $_element-background-error   : $form-element-validation__background-error,
    $_element-background-valid   : $form-element-validation__background-valid
) {
    &.mage-error {
        @include lib-form-element-color(
            $_border-color : $_element-border-color-error,
            $_background   : $_element-background-error,
            $_color        : $_element-color-error
        );
    }

    &.valid {
        @include lib-form-element-color(
            $_border-color : $_element-border-color-valid,
            $_background   : $_element-background-valid,
            $_color        : $_element-color-valid
        );
    }
}

@mixin lib-form-validation-note(
    $_note-color                    : $form-validation-note__color-error,
    $_note-font-size                : $form-validation-note__font-size,
    $_note-font-family              : $form-validation-note__font-family,
    $_note-font-style               : $form-validation-note__font-style,
    $_note-font-weight              : $form-validation-note__font-weight,
    $_note-line-height              : $form-validation-note__line-height,
    $_note-margin                   : $form-validation-note__margin,
    $_note-padding                  : $form-validation-note__padding,
    $_note-icon-use                 : $form-validation-note-icon__use,

    $_note-icon-font-content        : $form-validation-note-icon__font-content,
    $_note-icon-font                : $form-validation-note-icon__font,
    $_note-icon-font-size           : $form-validation-note-icon__font-size,
    $_note-icon-font-line-height    : $form-validation-note-icon__font-line-height,
    $_note-icon-font-color          : $form-validation-note-icon__font-color,
    $_note-icon-font-color-hover    : $form-validation-note-icon__font-color-hover,
    $_note-icon-font-color-active   : $form-validation-note-icon__font-color-active,
    $_note-icon-font-margin         : $form-validation-note-icon__font-margin,
    $_note-icon-font-vertical-align : $form-validation-note-icon__font-vertical-align,
    $_note-icon-font-position       : $form-validation-note-icon__font-position,
    $_note-icon-font-text-hide      : $form-validation-note-icon__font-text-hide
) {
    @include lib-typography(
        $_font-size   : $_note-font-size,
        $_color       : $_note-color,
        $_font-family : $_note-font-family,
        $_font-weight : $_note-font-weight,
        $_line-height : $_note-line-height,
        $_font-style  : $_note-font-style
    );
    @include _lib-form-validation-icon(
        $_note-icon-use,
        $_note-icon-font-content,
        $_note-icon-font,
        $_note-icon-font-size,
        $_note-icon-font-line-height,
        $_note-icon-font-color,
        $_note-icon-font-color-hover,
        $_note-icon-font-color-active,
        $_note-icon-font-margin,
        $_note-icon-font-vertical-align,
        $_note-icon-font-position,
        $_note-icon-font-text-hide
    );
}

@mixin lib-form-validation-color(
    $_element-border-color-error : null,
    $_element-background-error   : null,
    $_element-color-error        : null,
    $_element-border-color-valid : null,
    $_element-background-valid   : null,
    $_element-color-valid        : null
) {
    &.mage-error {
        @include lib-form-element-color(
            $_border-color : $_element-border-color-error,
            $_background   : $_element-background-error,
            $_color        : $_element-color-error
        );
    }

    &.valid {
        @include lib-form-element-color(
            $_border-color : $_element-border-color-valid,
            $_background   : $_element-background-valid,
            $_color        : $_element-color-valid
        );
    }
}

@mixin lib-form-element-all() {
    input[type="text"],
    input[type="password"],
    input[type="url"],
    input[type="tel"],
    input[type="search"],
    input[type="number"],
    input[type="datetime"],
    input[type="email"] {
        @include lib-form-element-input($_type: input-text);
    }

    input[type="number"] {
        @include lib-form-element-number-reset();
    }

    input[type="search"] {
        @include lib-form-element-search-reset();
    }

    select {
        @include lib-form-element-input($_type: select);
    }

    select[multiple="multiple"] {
        @include lib-css(height, auto);
        background-image: none;
    }

    textarea {
        @include lib-form-element-input($_type: textarea);
        @include lib-form-element-textarea-resize();
    }

    input[type="checkbox"] {
        @include lib-form-element-choice($_type: input-checkbox);
    }

    input[type="radio"] {
        @include lib-form-element-choice($_type: input-radio);
    }

    input,
    select,
    textarea {
        @include lib-form-validation();
    }

    div.mage-error[generated] {
        @include lib-form-validation-note();
    }

    input[type="button"],
    input[type="reset"],
    input[type="submit"] {
        cursor: pointer;
        -webkit-appearance: button;
    }

    input::-moz-focus-inner {
        border: 0;
        padding: 0;
    }
}

//
//  Internal use mixins
//  ---------------------------------------------

@mixin _lib-form-validation-icon(
    $_note-icon-use,
    $_note-icon-font-content,
    $_note-icon-font,
    $_note-icon-font-size,
    $_note-icon-font-line-height,
    $_note-icon-font-color,
    $_note-icon-font-color-hover,
    $_note-icon-font-color-active,
    $_note-icon-font-margin,
    $_note-icon-font-vertical-align,
    $_note-icon-font-position,
    $_note-icon-font-text-hide
) {
    @if $_note-icon-use {
        @include lib-icon-font(
            $_icon-font-content        : $_note-icon-font-content,
            $_icon-font                : $_note-icon-font,
            $_icon-font-size           : $_note-icon-font-size,
            $_icon-font-line-height    : $_note-icon-font-line-height,
            $_icon-font-color          : $_note-icon-font-color,
            $_icon-font-color-hover    : $_note-icon-font-color-hover,
            $_icon-font-color-active   : $_note-icon-font-color-active,
            $_icon-font-margin         : $_note-icon-font-margin,
            $_icon-font-vertical-align : $_note-icon-font-vertical-align,
            $_icon-font-position       : $_note-icon-font-position,
            $_icon-font-text-hide      : $_note-icon-font-text-hide
        );
    }
}

@mixin _lib-form-element-add-opacity($_opacity) {
    @if $_opacity != 1 {
        @include lib-css(opacity, $_opacity);
    }
}

//  Only for states :disabled, :focus, placeholder
@mixin _lib-form-element-focus(
    $_background-default,
    $_background-state,
    $_border-default,
    $_border-state,
    $_color-default,
    $_color-state,
    $_font-style-default,
    $_font-style-state
) {
    &:focus {
        @include _lib-form-element-state-add-background($_background-state, $_background-default);
        @include _lib-form-element-state-add-border($_border-state, $_border-default);
        @include _lib-form-element-state-add-font-color($_color-state, $_color-default);
        @include _lib-form-element-state-add-font-style($_font-style-state, $_font-style-default);
    }
}

@mixin _lib-form-element-disabled(
    $_background-default,
    $_background-state,
    $_border-default,
    $_border-state,
    $_color-default,
    $_color-state,
    $_font-style-default,
    $_font-style-state,
    $_opacity
) {
    &:disabled {
        @include _lib-form-element-add-opacity($_opacity);
        @include _lib-form-element-state-add-background($_background-state, $_background-default);
        @include _lib-form-element-state-add-border($_border-state, $_border-default);
        @include _lib-form-element-state-add-font-color($_color-state, $_color-default);
        @include _lib-form-element-state-add-font-style($_font-style-state, $_font-style-default);
    }
}

@mixin _lib-form-element-placeholder(
    $_type,
    $_color,
    $_font-style-default,
    $_font-style-state
) {
    @if $_type != "select" {
        &::-moz-placeholder {
            @include lib-css(color, $_color);
            @include _lib-form-element-state-add-font-style(
                $_font-style-state,
                $_font-style-default
            );
        }

        &::-webkit-input-placeholder {
            @include lib-css(color, $_color);
            @include _lib-form-element-state-add-font-style(
                $_font-style-state,
                $_font-style-default
            );
        }

        &:-ms-input-placeholder {
            @include lib-css(color, $_color);
            @include _lib-form-element-state-add-font-style(
                $_font-style-state,
                $_font-style-default
            );
        }
    }
}

@mixin _lib-form-element-state-add-background(
    $_background-state,
    $_background-default
) {
    @if $_background-state != $_background-default {
        @include lib-css(background, $_background-state);
    }
}

@mixin _lib-form-element-state-add-border(
    $_border-state,
    $_border-default
) {
    @if $_border-state != $_border-default {
        @include lib-css(border, $_border-state);
    }
}

@mixin _lib-form-element-state-add-border-color(
    $_border-state,
    $_border-default
) {
    @if $_border-state != $_border-default {
        @include lib-css(border-color, $_border-state);
    }
}

@mixin _lib-form-element-state-add-font-style(
    $_font-style-state,
    $_font-style-default
) {
    @if $_font-style-state != $_font-style-default {
        @include lib-css(font-style, $_font-style-state);
    }
}

@mixin _lib-form-element-state-add-font-color(
    $_color-state,
    $_color-default
) {
    @if $_color-state != $_color-default {
        @include lib-css(color, $_color-state);
    }
}

@mixin lib-form-fieldset(
    $_border             : $form-fieldset__border,
    $_margin             : $form-fieldset__margin,
    $_padding            : $form-fieldset__padding,
    $_legend-color       : $form-fieldset-legend__color,
    $_legend-font-size   : $form-fieldset-legend__font-size,
    $_legend-font-family : $form-fieldset-legend__font-family,
    $_legend-font-weight : $form-fieldset-legend__font-weight,
    $_legend-font-style  : $form-fieldset-legend__font-style,
    $_legend-line-height : $form-fieldset-legend__line-height,
    $_legend-margin      : $form-fieldset-legend__margin,
    $_legend-padding     : $form-fieldset-legend__padding,
    $_legend-width       : $form-fieldset-legend__width
) {
    @include lib-css(border, $_border);
    @include lib-css(margin, $_margin);
    @include lib-css(padding, $_padding);
    letter-spacing: -0.31em;

    > * {
        letter-spacing: normal;
    }

    > .legend {
        @include lib-css(margin, $_legend-margin);
        @include lib-css(padding, $_legend-padding);
        @include lib-css(width, $_legend-width);
        box-sizing: border-box;
        float: left;

        @include lib-typography(
            $_font-size   : $_legend-font-size,
            $_color       : $_legend-color,
            $_font-family : $_legend-font-family,
            $_font-weight : $_legend-font-weight,
            $_line-height : $_legend-line-height,
            $_font-style  : $_legend-font-style
        );

        & + br {
            clear: both;
            display: block;
            height: 0;
            overflow: hidden;
            visibility: hidden;
        }
    }
}

@mixin lib-form-field(
    $_type                          : $form-field-type,
    $_type-inline-margin            : $form-field-type-inline__margin,
    $_type-inline-label-margin      : $form-field-type-label-inline__margin,
    $_type-inline-label-padding     : $form-field-type-label-inline__padding,
    $_type-inline-label-align       : $form-field-type-label-inline__align,
    $_type-inline-label-width       : $form-field-type-label-inline__width,
    $_type-inline-control-width     : $form-field-type-control-inline__width,
    $_vertical-indent               : $form-field__vertical-indent,
    $_additional-vertical-indent    : $form-field__additional-vertical-indent,
    $_type-block-margin             : $form-field-type-block__margin,
    $_type-block-label-margin       : $form-field-type-label-block__margin,
    $_type-block-label-padding      : $form-field-type-label-block__padding,
    $_type-block-label-align        : $form-field-type-label-block__align,

    $_border                        : $form-field__border,
    $_column                        : $form-field-column,
    $_column-padding                : $form-field-column__padding,
    $_column-number                 : $form-field-column__number,

    $_label-color                   : $form-field-label__color,
    $_label-font-size               : $form-field-label__font-size,
    $_label-font-family             : $form-field-label__font-family,
    $_label-font-weight             : $form-field-label__font-weight,
    $_label-font-style              : $form-field-label__font-style,
    $_label-line-height             : $form-field-label__line-height,

    $_label-asterisk-color          : $form-field-label-asterisk__color,
    $_label-asterisk-font-size      : $form-field-label-asterisk__font-size,
    $_label-asterisk-font-family    : $form-field-label-asterisk__font-family,
    $_label-asterisk-font-weight    : $form-field-label-asterisk__font-weight,
    $_label-asterisk-font-style     : $form-field-label-asterisk__font-style,
    $_label-asterisk-line-height    : $form-field-label-asterisk__line-height,
    $_label-asterisk-margin         : $form-field-label-asterisk__margin,

    $_note-color                    : $form-field-note__color,
    $_note-font-size                : $form-field-note__font-size,
    $_note-font-family              : $form-field-note__font-family,
    $_note-font-weight              : $form-field-note__font-weight,
    $_note-line-height              : $form-field-note__line-height,
    $_note-font-style               : $form-field-note__font-style,
    $_note-margin                   : $form-field-note__margin,
    $_note-padding                  : $form-field-note__padding,
    $_note-icon-font-content        : $form-field-note-icon-font__content,
    $_note-icon-font                : $form-field-note-icon-font,
    $_note-icon-font-size           : $form-field-note-icon-font__size,
    $_note-icon-font-line-height    : $form-field-note-icon-font__line-height,
    $_note-icon-font-color          : $form-field-note-icon-font__color,
    $_note-icon-font-color-hover    : $form-field-note-icon-font__color-hover,
    $_note-icon-font-color-active   : $form-field-note-icon-font__color-active,
    $_note-icon-font-margin         : $form-field-note-icon-font__margin,
    $_note-icon-font-vertical-align : $form-field-note-icon-font__vertical-align,
    $_note-icon-font-position       : $form-field-note-icon-font__position,
    $_note-icon-font-text-hide      : $form-field-note-icon-font__text-hide
) {
    @include lib-css(border, $_border);

    @include lib-form-field-type(
        $_type,
        $_type-inline-margin,
        $_type-inline-label-margin,
        $_type-inline-label-padding,
        $_type-inline-label-align,
        $_type-inline-label-width,
        $_type-inline-control-width,
        $_vertical-indent,
        $_type-block-margin,
        $_type-block-label-margin,
        $_type-block-label-padding,
        $_type-block-label-align
    );

    &:last-child {
        margin-bottom: 0;
    }

    @include lib-form-field-column(
        $_column,
        $_column-padding,
        $_column-number
    );

    > .label {
        @include lib-typography(
            $_font-size   : $_label-font-size,
            $_color       : $_label-color,
            $_font-family : $_label-font-family,
            $_font-weight : $_label-font-weight,
            $_line-height : $_label-line-height,
            $_font-style  : $_label-font-style
        );
        & + br {
            display: none;
        }
    }

    &:not(.choice) > .label {
        text-transform: uppercase;
    }

    .choice {
        input {
            vertical-align: top;
        }
    }

    .fields.group {
        @include lib-clearfix();
        .field {
            box-sizing: border-box;
            float: left;
        }

        &.group-2 .field {
            width: 50% !important;
        }

        &.group-3 .field {
            width: 33.3% !important;
        }

        &.group-4 .field {
            width: 25% !important;
        }

        &.group-5 .field {
            width: 20% !important;
        }
    }

    .addon {
        @include lib-vendor-prefix-display(inline-flex);
        @include lib-vendor-prefix-flex-wrap(nowrap);
        padding: 0;
        width: 100%;

        textarea,
        select,
        input {
            @include lib-vendor-prefix-order(2);
            @include lib-vendor-prefix-flex-basis(100%);
            display: inline-block;
            margin: 0;
            width: auto;
        }

        .addbefore,
        .addafter {
            @include lib-form-element-input($_type: input-text);
            @include lib-vendor-prefix-order(3);
            display: inline-block;
            vertical-align: middle;
            white-space: nowrap;
            width: auto;
        }

        .addbefore {
            float: left;
            @include lib-vendor-prefix-order(1);
        }
    }

    .additional {
        @include lib-css(margin-top, $_additional-vertical-indent);
    }

    @include _lib-form-field-required(
        $_label-asterisk-color,
        $_label-asterisk-font-size,
        $_label-asterisk-font-family,
        $_label-asterisk-font-weight,
        $_label-asterisk-font-style,
        $_label-asterisk-line-height,
        $_label-asterisk-margin
    );

    @include _lib-form-field-note(
        $_note-font-size,
        $_note-color,
        $_note-font-family,
        $_note-font-weight,
        $_note-line-height,
        $_note-font-style,
        $_note-margin,
        $_note-padding,

        $_note-icon-font-content,
        $_note-icon-font,
        $_note-icon-font-size,
        $_note-icon-font-line-height,
        $_note-icon-font-color,
        $_note-icon-font-color-hover,
        $_note-icon-font-color-active,
        $_note-icon-font-margin,
        $_note-icon-font-vertical-align,
        $_note-icon-font-position,
        $_note-icon-font-text-hide
    );
}

@mixin lib-form-field-type(
    $_type                      : $form-field-type,
    $_type-inline-margin        : $form-field-type-inline__margin,
    $_type-inline-label-margin  : $form-field-type-label-inline__margin,
    $_type-inline-label-padding : $form-field-type-label-inline__padding,
    $_type-inline-label-align   : $form-field-type-label-inline__align,
    $_type-inline-label-width   : $form-field-type-label-inline__width,
    $_type-inline-control-width : $form-field-type-control-inline__width,
    $_vertical-indent           : $form-field__vertical-indent,
    $_type-block-margin         : $form-field-type-block__margin,
    $_type-block-label-margin   : $form-field-type-label-block__margin,
    $_type-block-label-padding  : $form-field-type-label-block__padding,
    $_type-block-label-align    :  $form-field-type-label-block__align
) {
    @if $_type == "inline" {
        @include _lib-form-field-type-inline(
            $_type-inline-margin,
            $_type-inline-label-margin,
            $_type-inline-label-padding,
            $_type-inline-label-width,
            $_type-inline-control-width,
            $_type-inline-label-align,
            $_vertical-indent
        );
    }
    @else if $_type == "block" {
        @include _lib-form-field-type-block(
            $_type-block-margin,
            $_type-block-label-margin,
            $_type-block-label-padding,
            $_type-block-label-align
        );
    }
}

@mixin _lib-form-field-type-inline(
    $_type-inline-margin,
    $_type-inline-label-margin,
    $_type-inline-label-padding,
    $_type-inline-label-width,
    $_type-inline-control-width,
    $_type-inline-label-align,
    $_vertical-indent
) {
    @include lib-clearfix();
    @include lib-css(margin, $_type-inline-margin);
    box-sizing: border-box;

    &.choice:before,
    &.no-label:before {
        @include lib-css(padding, $_type-inline-label-padding);
        @include lib-css(width, $_type-inline-label-width);
        box-sizing: border-box;
        content: ' ';
        float: left;
        height: 1px;
    }

    .description {
        @include lib-css(padding, $_type-inline-label-padding);
        @include lib-css(text-align, $_type-inline-label-align);
        @include lib-css(width, $_type-inline-label-width);
        box-sizing: border-box;
        float: left;
    }


}

@mixin _lib-form-field-type-block(
    $_type-block-margin,
    $_type-block-label-margin,
    $_type-block-label-padding,
    $_type-block-label-align
) {
    @include lib-css(margin, $_type-block-margin);
    > .label {
        @include lib-css(margin, $_type-block-label-margin);
        @include lib-css(padding, $_type-block-label-padding);
        @include lib-css(text-align, $_type-block-label-align);
        display: inline-block;
    }
}

@mixin _lib-form-field-required(
    $_color       : $form-field-label-asterisk__color,
    $_font-size   : $form-field-label-asterisk__font-size,
    $_font-family : $form-field-label-asterisk__font-family,
    $_font-weight : $form-field-label-asterisk__font-weight,
    $_font-style  : $form-field-label-asterisk__font-style,
    $_line-height : $form-field-label-asterisk__line-height,
    $_margin      : $form-field-label-asterisk__margin
) {
    &.required > .label,
    &._required > .label {
        &:after {
            content: '*';
            @include lib-typography(
                $_font-size   : $_font-size,
                $_color       : $_color,
                $_font-family : $_font-family,
                $_font-weight : $_font-weight,
                $_line-height : $_line-height,
                $_font-style  : $_font-style
            );
            @include lib-css(margin, $_margin);
        }
    }
}

@mixin _lib-form-field-note(
    $_note-font-size,
    $_note-color,
    $_note-font-family,
    $_note-font-weight,
    $_note-line-height,
    $_note-font-style,
    $_note-margin,
    $_note-padding,

    $_note-icon-font-content,
    $_note-icon-font,
    $_note-icon-font-size,
    $_note-icon-font-line-height,
    $_note-icon-font-color,
    $_note-icon-font-color-hover,
    $_note-icon-font-color-active,
    $_note-icon-font-margin,
    $_note-icon-font-vertical-align,
    $_note-icon-font-position,
    $_note-icon-font-text-hide
) {
    .note {
        @include lib-typography(
            $_font-size   : $_note-font-size,
            $_color       : $_note-color,
            $_font-family : $_note-font-family,
            $_font-weight : $_note-font-weight,
            $_line-height : $_note-line-height,
            $_font-style  : $_note-font-style
        );

        @include lib-css(margin, $_note-margin);
        @include lib-css(padding, $_note-padding);

        @include lib-icon-font(
            $_icon-font-content        : $_note-icon-font-content,
            $_icon-font                : $_note-icon-font,
            $_icon-font-size           : $_note-icon-font-size,
            $_icon-font-line-height    : $_note-icon-font-line-height,
            $_icon-font-color          : $_note-icon-font-color,
            $_icon-font-color-hover    : $_note-icon-font-color-hover,
            $_icon-font-color-active   : $_note-icon-font-color-active,
            $_icon-font-margin         : $_note-icon-font-margin,
            $_icon-font-vertical-align : $_note-icon-font-vertical-align,
            $_icon-font-position       : $_note-icon-font-position,
            $_icon-font-text-hide      : $_note-icon-font-text-hide
        );
    }
}

@mixin lib-form-field-type-revert(
    $_type                      : $form-field-type-revert,
    $_type-inline-margin        : $form-field-type-inline__margin,
    $_type-inline-label-margin  : $form-field-type-label-inline__margin,
    $_type-inline-label-padding : $form-field-type-label-inline__padding,
    $_type-inline-label-align   : $form-field-type-label-inline__align,
    $_type-inline-label-width   : $form-field-type-label-inline__width,
    $_type-inline-control-width : $form-field-type-control-inline__width,
    $_vertical-indent           : $form-field__vertical-indent,
    $_type-block-margin         : $form-field-type-block__margin,
    $_type-block-label-margin   : $form-field-type-label-block__margin,
    $_type-block-label-padding  : $form-field-type-label-block__padding,
    $_type-block-label-align    : $form-field-type-label-block__align
) {
    @if $_type == block {
        &:not(.choice) {
            > .label {
                box-sizing: content-box;
                float: none;
                width: auto;

                @include _lib-revert-type-block-label-align(
                    $_type-block-label-align
                );

                @include _lib-revert-type-block-label-padding(
                    $_type-block-label-padding
                );
            }

            > .control {
                float: none;
                width: auto;
            }
        }

        @include _lib-form-field-type-block(
            $_type-block-margin,
            $_type-block-label-margin,
            $_type-block-label-padding,
            $_type-block-label-align
        );

        &.choice,
        &.no-label {
            &:before {
                display: none;
            }
        }
    }
    @if $_type == inline {
        > .label {
            @include _lib-revert-type-inline-label-margin(
                $_type-inline-label-margin
            );
        }
        @include _lib-form-field-type-inline(
            $_type-inline-margin,
            $_type-inline-label-margin,
            $_type-inline-label-padding,
            $_type-inline-label-width,
            $_type-inline-control-width,
            $_type-inline-label-align,
            $_vertical-indent
        );
    }
}

@mixin lib-form-field-column(
    $_column         : $form-field-column,
    $_column-padding : $form-field-column__padding,
    $_column-number  : $form-field-column__number
) {
    @if $_column {
        @include lib-css(padding, $_column-padding);
        box-sizing: border-box;
        display: inline-block;

        @include lib-form-field-column-number($_column-number);
        vertical-align: top;

        & + .fieldset {
            clear: both;
        }
    }
}

@mixin lib-form-field-column-number($_column-number: $form-field-column__number) {
    @include lib-css(width, 100% / $_column-number);
}

@mixin _lib-revert-type-block-label-padding(
    $_type-block-label-padding
) {
    @if $_type-block-label-padding == false {
        padding: 0;
    }
}

@mixin _lib-revert-type-block-label-align(
    $_type-block-label-align
) {
    @if $_type-block-label-align == false {
        text-align: left;
    }
}

@mixin _lib-revert-type-inline-label-margin(
    $_type-inline-label-margin
) {
    @if $_type-inline-label-margin == false {
        margin: 0;
    }
}

@mixin lib-form-hasrequired(
    $_position    : $form-hasrequired__position,
    $_color       : $form-hasrequired__color,
    $_font-size   : $form-hasrequired__font-size,
    $_font-family : $form-hasrequired__font-family,
    $_font-weight : $form-hasrequired__font-weight,
    $_font-style  : $form-hasrequired__font-style,
    $_line-height : $form-hasrequired__line-height,
    $_border      : $form-hasrequired__border,
    $_margin      : $form-hasrequired__margin,
    $_padding     : $form-hasrequired__padding
) {
    @include _lib-form-hasrequired-position(
        $_position,
        $_border,
        $_margin,
        $_padding,
        $_font-size,
        $_color,
        $_font-family,
        $_font-weight,
        $_line-height,
        $_font-style
    );
}

@mixin lib-form-hasrequired(
    $_position    : $form-hasrequired__position,
    $_color       : $form-hasrequired__color,
    $_font-size   : $form-hasrequired__font-size,
    $_font-family : $form-hasrequired__font-family,
    $_font-weight : $form-hasrequired__font-weight,
    $_font-style  : $form-hasrequired__font-style,
    $_line-height : $form-hasrequired__line-height,
    $_border      : $form-hasrequired__border,
    $_margin      : $form-hasrequired__margin,
    $_padding     : $form-hasrequired__padding
) {
    @if $_position == "top" {
        &:before {
            @include _lib-form-hasrequired-position-any(
                $_border,
                $_margin,
                $_padding,
                $_font-size,
                $_color,
                $_font-family,
                $_font-weight,
                $_line-height,
                $_font-style
            );
        }
    }
    @else if $_position == "bottom" {
        &:after {
            @include _lib-form-hasrequired-position-any(
                $_border,
                $_margin,
                $_padding,
                $_font-size,
                $_color,
                $_font-family,
                $_font-weight,
                $_line-height,
                $_font-style
            );
        }
    }
}

@mixin _lib-form-hasrequired-position-any(
    $_border,
    $_margin,
    $_padding,
    $_font-size,
    $_color,
    $_font-family,
    $_font-weight,
    $_line-height,
    $_font-style
) {
    @include lib-css(border, $_border);
    @include lib-css(margin, $_margin);
    @include lib-css(padding, $_padding);
    content: attr(data-hasrequired);
    display: block;
    letter-spacing: normal;
    word-spacing: normal;
    @include lib-typography(
        $_font-size   : $_font-size,
        $_color       : $_color,
        $_font-family : $_font-family,
        $_font-weight : $_font-weight,
        $_line-height : $_line-height,
        $_font-style  : $_font-style
    );
}

