//
//  Variables
//  _____________________________________________

$account-nav-background: rgba(10, 45, 103, 0.33);
$account-nav-color: $text__color__light;

$account-nav-current-border: false;
$account-nav-current-border-color: false;
$account-nav-current-color: $text__color__lighter;
$account-nav-current-font-weight: $font-weight__semibold;

$account-nav-delimiter__border-color: rgba(255, 255, 255, 0.1);

$account-nav-item-hover: false;

$_password-default: $color-gray-light01;
$_password-weak: #ffafae;
$_password-medium: #ffd6b3;
$_password-strong: #c5eeac;
$_password-very-strong: #81b562;

.login-container {
    .block {
        &-new-customer {
            .actions-toolbar {
                margin-top: 25px;
            }
        }

        .block-title {
            @extend .abs-login-block-title;
            @include lib-font-size(18);
        }
    }

    .action.primary {
        border-radius: 30px;
    }
}

.block-addresses-list {
    .items.addresses {
        > .item {
            margin-bottom: $indent__base;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.form-address-edit {
    #region_id {
        display: none;
    }

    .actions-toolbar .action.primary {
        @extend .abs-button-l;
    }
}

.form-edit-account {
    .fieldset.password {
        display: none;
    }
}

.box-billing-address,
.box-shipping-address,
.box-information,
.box-newsletter {
    .box-content {
        line-height: 26px;
    }
}

.form-create-account {
    fieldset {
        @include lib-block();

        p {
            font-weight: bold;
            font-size: 12pt;
            margin-top: 0;
        }

        select {
            background: #fff url('../images/select-bg-dark.svg') no-repeat 99% 45%;
            background-size: 30px 60px;
            border: 1px solid #ccc;
        }
    }
}

//  Full name fieldset
.fieldset {
    .fullname {
        &.field {
            > .label {
                @extend .abs-visually-hidden;

                + .control {
                    width: 100%;
                }
            }
        }

        .field {
            @extend .abs-add-clearfix;
        }
    }
}

//
//  My account Order History Details
//  ---------------------------------------------

.column.main {
    .page-title-wrapper {
        .block.order-detail-status {
            margin-bottom: 20px;
        }
    }
}


//
//  My account
//  ---------------------------------------------

.account {
    .column.main {
        h2 {
            margin-top: 0;
        }

        .toolbar {
            text-align: center;

            .limiter-options {
                width: auto;
            }
        }

        .limiter {
            > .label {
                @extend .abs-visually-hidden;
            }
        }

        .block:not(.widget) {
            @extend .abs-account-blocks;
        }
    }

    .sidebar-additional {
        margin-top: 40px;
    }

    .table-wrapper {
        &:last-child {
            margin-bottom: 0;
        }

        .action {
            margin-right: 15px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .table-return-items {
        .qty {
            .input-text {
                @extend .abs-input-qty;
            }
        }
    }
}

//  Checkout address (create shipping address)
.field.street {
    .field.additional {
        .label {
            @extend .abs-visually-hidden;
        }
    }
}

//
//  Account navigation
//  ---------------------------------------------

.block-collapsible-nav {
    .title {
        @extend .abs-visually-hidden;
    }

    .content {
        @include lib-css(background, $account-nav-background);
        padding: 15px 0;
    }

    .item {
        margin: 3px 0 0;

        &:first-child {
            margin-top: 0;
        }

        a,
        > strong {
            @include lib-css(color, $account-nav-color);
            border-left: 3px solid transparent;
            display: block;
            padding: $indent__xs 18px $indent__xs 15px;
            font-weight: 500;
        }

        a {
            text-decoration: none;

            &:hover {
                @include lib-css(background, $account-nav-item-hover);
            }
        }

        &.current {
            a,
            strong {
                @include lib-css(border-color, $account-nav-current-border-color);
                @include lib-css(color, $account-nav-current-color);
                @include lib-css(font-weight, $account-nav-current-font-weight);
            }

            a {
                @include lib-css(border-color, $account-nav-current-border-color);
            }
        }

        .delimiter {
            border-top: 1px solid $account-nav-delimiter__border-color;
            display: block;
            margin: $indent__s 1.8rem;
        }
    }
}

//
//  Blocks & Widgets
//  ---------------------------------------------

.block {
    @extend .abs-margin-for-blocks-and-widgets;

    .column.main & {
        &:last-child {
            margin-bottom: 0;
        }
    }

    .title {
        margin-bottom: $indent__s;

        strong {
            @include lib-heading(h4);
        }
    }

    p:last-child {
        margin: 0;
    }

    .box-actions {
        margin-top: $indent__xs;
    }
}

.login-wrapper {
    @include lib-block($padding-desktop: 40px);

    p {
        color: $color-gray-text-light;
        @include lib-font-size(15);
    }

    .catalog-product-view & {
        margin-bottom: $indent__m;
    }
}

.login-container {
    .block {
        margin-bottom: 0;
    }

    .register-title {
        margin-top: 0;
        @include lib-font-size(28);
        font-weight: 500;
    }
}

//
//  Customer menu
//  ---------------------------------------------

.customer-menu-wrapper {
    @include lib-dropdown(
        $_toggle-selector: '.action.show-customer-menu',
        $_options-selector: '.block-customer-menu',
        $_dropdown-toggle-icon-content: $icon-account,
        $_dropdown-toggle-active-icon-content: $icon-account,
        $_dropdown-list-width: 250px,
        $_icon-font-position: before,
        $_icon-font-margin: 0 5px 0 0,
        $_icon-font-size: 20px,
        $_icon-font-line-height: 28px,
        $_icon-font-color: $header-icons-color,
        $_icon-font-color-hover: $header-icons-color,
        $_icon-font-color-active: $header-icons-color
    );

    .block {
        &::after,
        &::before {
            margin-right: 40px;
        }
    }

    .show-customer-menu {
        @include lib-icon-font(
            $_icon-font-content: $icon-down,
            $_icon-font-size: 10px,
            $_icon-font-margin: 0 0 0 5px,
            $_icon-font-position: after
        );
        color: $djm-color-gray2;
        box-shadow: 0 0 0 1px inset $djm-color-light-gray6;
        padding: 8px 30px;
        line-height: 2.1;
        border-radius: 30px;
    }

    .block-customer-menu {
        right: 0;
        z-index: 101;

        .block-title {
            display: none;
        }

        &:after {
            left: auto;
            right: 25px;
        }

        &:before {
            left: auto;
            right: 26px;
        }
    }

    ul {
        @include lib-list-reset-styles;

        a {
            color: $color-gray-text;
        }
    }

    .customer-menu {
        &__link {
            @include lib-icon-font(
                $_icon-font-content: false,
                $_icon-font-size: 25px,
                $_icon-font-line-height: 1,
                $_icon-font-color: $djm-color-gray3,
                $_icon-font-margin: 0 5px 0 0,
                $_icon-font-position: before
            );
            position: relative;
            margin-bottom: 10px;
            padding-left: 35px;
            font-size: 15px;
            font-weight: 500;
            color: $djm-color-gray3;

            &:hover {
                color: darken($djm-color-gray3, 20%);
            }

            &::before {
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }

            &--order-list {
                @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-list-plus
                );
                @include lib-icon-font-size(
                    $_icon-font-size: 16px
                );
            }

            &--quick-order {
                @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-quick-order
                );

                &::before {
                    left: -4px;
                }
            }

            &--my-account {
                @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-account
                );
                @include lib-icon-font-size(
                    $_icon-font-size: 18px
                );
            }

            &--my-orders {
                @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-delivery
                );
                @include lib-icon-font-size(
                    $_icon-font-size: 21px
                );
            }
        }

        &__authentication {
            position: relative;
            margin-left: 35px;
            font-size: 15px;
            font-weight: 500;
            color: $djm-color-blue;

            &:hover {
                color: $djm-color-blue;
            }

            &::after {
                position: absolute;
                top: 50%;
            }

            &--sign-in,
            &--sign-out {
                @include lib-icon-font(
                    $_icon-font-content: $icon-down,
                    $_icon-font-size: 10px,
                    $_icon-font-line-height: 1,
                    $_icon-font-color: $djm-color-blue,
                    $_icon-font-margin: 0,
                    $_icon-font-position: after
                );
            }

            &--sign-in::after {
                right: -15px;
                transform: translateY(-38%) rotate(-90deg);
            }

            &--sign-out::after {
                left: -15px;
                transform: translateY(-38%) rotate(90deg);
            }
        }
    }
}

//
//  Password Strength Meter
//  ---------------------------------------------

.field.password {
    .control {
        @include lib-vendor-prefix-display();
        @include lib-vendor-prefix-flex-direction();

        .mage-error {
            @include lib-vendor-prefix-order(2);
        }

        .input-text {
            @include lib-vendor-prefix-order(0);
            z-index: 2;
        }
    }
}

.password-strength-meter {
    background-color: $_password-default;
    height: $form-element-input__height;
    line-height: $form-element-input__height;
    padding: $form-element-input__padding;
    position: relative;
    z-index: 1;

    &:before {
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        z-index: -1;
    }

    .password-none & {
        &:before {
            background-color: $_password-default;
            width: 100%;
        }
    }

    .password-weak & {
        &:before {
            background-color: $_password-weak;
            width: 25%;
        }
    }

    .password-medium & {
        &:before {
            background-color: $_password-medium;
            width: 50%;
        }
    }

    .password-strong & {
        &:before {
            background-color: $_password-strong;
            width: 75%;
        }
    }

    .password-very-strong & {
        &:before {
            background-color: $_password-very-strong;
            width: 100%;
        }
    }
}

.form-create-account {
    .action.primary {
        background: $color-medium-blue2;
        border-radius: 32px;
    }
}

.control.captcha-image {
    @include lib-css(margin-top, $indent__s);

    .captcha-img {
        vertical-align: middle;
    }
}

@include max-screen($screen__l) {
    .customer-menu-wrapper {
        .action.show-customer-menu {
            @include lib-rounded-button(
                $_button-color: $djm-color-gray2,
                $_button-background: $color-white,
                $_button-border: $djm-color-light-gray6
            );
            padding: 0;

            span,
            &::after {
                display: none;
            }
        }
    }

    .login-container .block {
        margin-bottom: $indent__m;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .account {
        .column.main,
        .sidebar-additional {
            margin: 0;
        }
    }
}

@include max-screen($screen__m) {
    .action.show-customer-menu {
        display: none;
    }

    .login-container {
        .fieldset {
            &:after {
                text-align: center;
            }
        }
    }

    .account {
        .page.messages {
            margin-bottom: $indent__xl;
        }

        .toolbar {
            @extend .abs-pager-toolbar-mobile;
        }
    }

    .control.captcha-image {
        .captcha-img {
            @include lib-css(margin-bottom, $indent__s);
            display: block;
        }
    }

    .customer-account-index {
        .page-title-wrapper {
            position: relative;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__l) {
    .login-container {
        display: flex;

        .block-customer-login {
            box-sizing: border-box;
            padding-right: 30px;
            border-right: 1px solid $color-gray-border;
        }

        .block-new-customer {
            padding-left: 30px;
        }

        .block {
            flex: 1;
        }
    }
}

@include min-screen($screen__m) {
    .form-create-account {
        .action.primary {
            max-width: 60%;
        }
    }

    .login-container {
        .block {
            &.login {
                .actions-toolbar {
                    > .primary {
                        margin-bottom: 0;
                        margin-right: $indent__l;
                    }

                    > .secondary {
                        float: left;
                    }
                }
            }
        }

        .fieldset {
            &:after {
                @extend .abs-margin-for-forms-desktop;
            }
        }
    }

    //  Full name fieldset
    .fieldset {
        .fullname {
            .field {
                .label {
                    @include lib-css(margin, $form-field-type-label-inline__margin);
                    @include lib-css(padding, $form-field-type-label-inline__padding);
                    @include lib-css(text-align, $form-field-type-label-inline__align);
                    @include lib-css(width, $form-field-type-label-inline__width);
                    box-sizing: border-box;
                    float: left;
                }

                .control {
                    @include lib-css(width, $form-field-type-control-inline__width);
                    float: left;
                }
            }
        }
    }

    .form.password.reset,
    .form.send.confirmation,
    .form.password.forget,
    .form.create.account {
        min-width: 600px;
        width: 50%;
    }

    .form.password.reset,
    .form.send.confirmation,
    .form.password.forget {
        @include lib-block();
    }

    //
    //  My account
    //  ---------------------------------------------

    .account.page-layout-2columns-left {
        .sidebar-main,
        .sidebar-additional {
            width: 22.3%;
        }

        .column.main {
            width: 77.7%;
        }
    }

    .account {
        .column.main {
            .block:not(.widget) {
                .block-content {
                    @extend .abs-add-clearfix-desktop;

                    .box {
                        @extend .abs-blocks-2columns;
                    }
                }
            }
        }

        .toolbar {
            @extend .abs-pager-toolbar;
        }
    }

    .block-addresses-list {
        .items.addresses {
            @extend .abs-add-clearfix-desktop;
            font-size: 0;

            > .item {
                display: inline-block;
                font-size: $font-size__base;
                margin-bottom: $indent__base;
                vertical-align: top;
                width: 48.8%;

                &:nth-last-child(1),
                &:nth-last-child(2) {
                    margin-bottom: 0;
                }

                &:nth-child(even) {
                    margin-left: 2.4%;
                }
            }
        }
    }

    //
    //  Welcome block
    //  ---------------------------------------------

    .dashboard-welcome-toggler {
        @extend .abs-visually-hidden-desktop;
    }

    .control.captcha-image {
        .captcha-img {
            margin: 0 $indent__s $indent__s 0;
        }
    }
}

.customer-account-login {
    #maincontent {
        max-width: 900px;
    }
}

body.account .column.main {
    .block,
    .fieldset,
    .table-wrapper.orders-history,
    .order-details-items {
        @include lib-block();
    }

    .fieldset.rma {
        box-shadow: none;
    }

    .action.primary {
        @extend .abs-button-l;
        font-size: 16px;
        background: $color-medium-blue2;
        border-radius: 32px;
        max-width: 400px;
    }

    .action.secondary {
        @extend .abs-button-s;
        font-weight: 600;
        font-size: 14px;
        background: $color-medium-blue2;
        color: $text__color__lighter;
        border-radius: 16px;
        padding: 6px 15px;
        max-width: 400px;
        margin: 0;

    }

    a:hover {
        text-decoration: none;
    }

    .action.back {
        display: none;
    }
}
