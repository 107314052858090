.category-slider {
    position: relative;
    margin: 80px auto;
    padding: 0 30px 30px 30px;
    border: 2px solid $color-blue-border;
    border-top: 0;
    z-index: 1;

    .swiper-wrapper {
        max-height: 100%;

        .item {
            width: 25%;
            margin-right: 30px;
        }
    }

    .block-title {
        transform: translateY(-50%);
        margin-left: -32px;
        margin-right: -32px;
        text-align: center;
        overflow: hidden;

        a {
            text-decoration: none;
        }

        strong {
            position: relative;
            font-weight: $font-weight__bold;
            text-transform: uppercase;
            color: $text__color__light;
            letter-spacing: 0.48em;
            font-size: 15px;

            &::before,
            &::after {
                content: '';
                position: absolute;
                top: 50%;
                width: 50vw;
                height: 2px;
                background-color: $color-blue-border;
            }

            &::before {
                right: calc(100% + 15px);
            }

            &::after {
                left: calc(100% + 10px); // text has some whitespace after it, so we use less px
            }
        }
    }

    .block-button {
        position: absolute;
        top: -11px;
        right: 30px;
        display: flex;
        justify-content: center;
        align-items: center;

        a {
            text-decoration: none;

            strong {
                font-weight: $font-weight__bold;
                text-transform: uppercase;
                letter-spacing: normal;
                font-size: 11px;
                background-color: $color-dark-blue1;
                box-shadow: 0 0 0 10px $color-dark-blue1;
                border: 2px solid rgba(255, 255, 255, 0.5);
                padding: 8px 24px;
                border-radius: 24px;
                color: $text__color__light;

                &:hover {
                    border: 2px solid rgba(255, 255, 255, 0.7);
                }
            }
        }
    }

    .controls {
        .category-slider-button-container {
            position: absolute;
            top: 16px;
            right: 14px;
            display: flex;
        }

        .category-slider-button {
            @include lib-icon-font(
                $_icon-font-content: $icon-prev,
                $_icon-font-position: after,
                $_icon-font-size: 16px,
                $_icon-font-display: block,
                $_icon-font-color: $text__color__light
            );

            border-radius: 50%;
            cursor: pointer;
            margin-right: 5px;
            padding: 0 10px;
            overflow: hidden;
            line-height: 35px;
            position: absolute;
            z-index: 2;
            top: 50%;
            transform: translateY(-50%);
            background-color: $color-dark-blue1;
            box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.5);
            border-top: 8px solid $color-dark-blue1;
            border-bottom: 8px solid $color-dark-blue1;

            &:hover {
                box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.7);
            }

            &:last-child {
                margin-right: 0;
            }
        }

        .category-slider-button-prev {
            left: -15px;

            &::after {
                transform: translateX(-1px);
            }
        }

        .category-slider-button-next {
            @include lib-icon-font-symbol(
                $_icon-font-content: $icon-next,
                $_icon-font-position: after
            );

            right: -15px;

            &::after {
                transform: translateX(1px);
            }
        }

        .category-slider-button-disabled {
            opacity: 0;
            cursor: auto;
            pointer-events: none;
        }

        .category-slider-button-lock {
            display: none;
        }
    }
}

@include max-screen($screen__m) {
    .category-slider {
        .block-button {
            top: unset;
            right: unset;
            left: 0;
            bottom: -18px;
            width: 100%;

            a {
                display: flex;
                justify-content: space-around;
                align-items: center;
                width: 90%;

                strong {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                }
            }
        }
    }
}
