//
//  Product Lists
//  _____________________________________________

.products {
    margin: $indent__l 0;
}

.products-related {
    .actions-primary {
        display: flex !important;
        align-items: center;
    }

    input[type="text"], input[type="password"], input[type="url"], input[type="tel"], input[type="search"], input[type="number"], input[type="datetime"], input[type="email"] {
        margin-top: 7px;
    }
}

#block-related-heading {
    color: white;
    font-size: 30px;
    font-family: $h1__font-family;
    font-weight: 300;
    line-height: 1.1;
}

.product {
    &-items {
        @extend .abs-reset-list;
    }

    &-item {
        @extend .abs-add-box-sizing;
        vertical-align: top;

        .products-grid & {
            display: inline-block;
            width: 50%;
            margin-bottom: $indent__l;
        }

        &-name {
            @extend .abs-product-link;
            display: block;
            margin: 10px 0 5px 0;
            word-wrap: break-word;
            hyphens: auto;
        }

        &-info {
            position: relative;
        }

        .products-grid &-photo {
            display: block;
            background-color: $color-white;
            margin: -15px -15px -7px;
        }

        .products-grid &-details {

        }

        .products-grid &-info {
            overflow: hidden;
            @include lib-block(
                $padding-desktop: false
            );
        }

        &-actions {
            display: none;

            .actions-secondary {
                & > .action {
                    @extend .abs-actions-addto;
                    &:before {
                        margin: 0;
                    }

                    span {
                        @extend .abs-visually-hidden;
                    }
                }
            }
        }

        &-description {
            margin: $indent__m 0;
        }

        .product-reviews-summary {
            .rating-summary {
                margin: 0 4px 0 0;
            }

            .reviews-actions {
                font-size: $font-size__s;
                margin-top: 5px;
                text-transform: lowercase;
            }
        }

        .price-box {
            display: inline-flex;
            flex-direction: row-reverse;

            .price {
                font-size: 14px;
                font-weight: $font-weight__bold;
                white-space: nowrap;
                color: $djm-color-dark-blue3;
            }

            .price-label {
                margin-right: 5px;
            }

            .minimal-price {
                display: flex;
                align-items: flex-end;
                color: $color-gray-text-light;
            }
        }

        .special-price,
        .minimal-price {
            .price {
                font-size: 14px;
                font-weight: $font-weight__bold;
            }

            .price-wrapper {
                display: inline-block;
            }

            .price-including-tax + .price-excluding-tax {
                display: block;
            }
        }

        .special-price {
            display: block;
        }

        .old-price {
            margin-right: $indent__xs;
            color: $text__color__faded;

            .price {
                font-weight: $font-weight__regular;
                color: $text__color__faded;
            }
        }

        .minimal-price {
            .price-container {
                display: block;
            }
        }

        .minimal-price-link {
            margin-top: 5px;

            .price-label {
                color: $link__color;
                font-size: 14px;
            }

            .price {
                font-weight: $font-weight__regular;
            }
        }

        .minimal-price-link,
        .price-excluding-tax,
        .price-including-tax {
            display: block;
            white-space: nowrap;
        }

        .price-from,
        .price-to {
            margin: 0;
        }

        .tocompare {
            @include lib-icon-font-symbol($icon-compare-full);
        }

        .tocart {
            white-space: nowrap;
        }
    }
}

.category {
    &-item {
        .categories-grid & {
            position: relative;
            margin-bottom: 30px;
        }
    }
}

.categories-grid {
    &__image {
        display: block;
        background-size: cover;
    }

    &__right {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 15px;
        text-align: center;
    }

    h3 {
        margin-top: 0;
        color: $color-dark-blue-text;
        font-weight: $font-weight__bold;
    }

    a {
        text-transform: uppercase;
        font-weight: $font-weight__semibold;
        color: $color-light-blue-text;
    }
}

.column.main {
    .product {
        &-items {
            display: flex;
            flex-wrap: wrap;
            margin-left: -$indent__base;
        }

        &-item {
            display: flex;
            padding-left: $indent__base;
        }

        &-item-info {
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        &-item-details {
            flex: 1 0 auto;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .special_price_label {
                position: absolute;
                z-index: 1;
                transform: translateY(-50%);
                left: 15px;

                span {
                    padding: 2px 10px;
                    background-color: $djm-color-dark-red;
                    color: white;
                    border-radius: 25px;
                    font-size: 10px;
                    text-transform: uppercase;
                    text-align: center;
                    font-weight: 1000;
                }
            }
        }

        &-item-name {
            flex: 1 0 auto;
        }
    }

}

.price-container {
    .price {
        font-size: 14px;
    }

    .price-including-tax + .price-excluding-tax,
    .weee {
        margin-top: $indent__xs;
    }

    .price-including-tax + .price-excluding-tax,
    .weee,
    .price-including-tax + .price-excluding-tax .price,
    .weee .price,
    .weee + .price-excluding-tax:before,
    .weee + .price-excluding-tax .price {
        font-size: 11px;
    }

    .weee {
        &:before {
            content: '('attr(data-label) ': ';
        }

        &:after {
            content: ')';
        }

        + .price-excluding-tax {
            &:before {
                content: attr(data-label) ': ';
            }
        }
    }
}

.products-list {
    .product {
        &-item {
            display: table;
            width: 100%;

            &-info {
                display: table-row;
            }

            &-photo {
                display: table-cell;
                padding: 0 $indent__l $indent__l 0;
                vertical-align: top;
                width: 1%;
            }

            &-details {
                display: table-cell;
                vertical-align: top;
            }
        }
    }

    .product-image-wrapper {
        @extend .abs-reset-image-wrapper;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .products-list .product {
        &-item {
            table-layout: fixed;

            &-photo {
                padding: 0 $indent__s $indent__s 0;
                width: 30%;
            }
        }
    }
}

@include max-screen($screen__m) {
    .categories-grid {
        position: relative;

        &__right {
            position: absolute;
            bottom: 0;

            > *:not(h3) {
                display: none;
            }

            h3 {
                margin-bottom: 0;
                color: $color-white;
            }
        }
    }
}

@include min-screen($screen__s) {
    .product-item-info {
        .products-grid & {
            &:hover,
            &.active {
                position: relative;
                z-index: 3;

                .product-item-inner {
                    display: block;
                }
            }
        }
    }

    .product-item-inner {
        .products-grid & {
            padding: 10px;
            background-color: $color-white;
            border-top: none;
            left: 0;
            position: absolute;
            right: 0;
            z-index: 2;
        }
    }

    .product {
        &-item {
            &-actions {
                display: block;

                .products-grid & {
                    margin: $indent__s 0 0;
                }

                .actions-primary + .actions-secondary {
                    display: table-cell;
                    padding-left: 10px;
                    white-space: nowrap;
                    width: 50%;

                    & > * {
                        white-space: normal;
                    }

                    & > .action:not(:last-child) {
                        margin-right: 10px;
                    }
                }

                .actions-primary {
                    display: table-cell;
                }
            }
        }
    }

    .products-grid .product-item {
        width: 33.3333%;
    }

    .products-grid {
        .product-item-info {
            &:not(:hover) {
                &:not(.active) {
                    .product-item-inner {
                        @extend .abs-visually-hidden-desktop-s;
                    }
                }
            }
        }
    }

    .page-products,
    .page-layout-1column,
    .page-layout-3columns,
    .page-products.page-layout-1column,
    .page-products.page-layout-3columns {
        .products-grid {
            .product-item {
                width: (100% / 3);
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .page-products {
        .products-grid {
            .product-item {
                width: 33.3333%;
            }
        }
    }

    .page-products.page-layout-1column {
        .products-grid {
            .product-item {
                width: 25%;
            }
        }
    }

    .page-products.page-layout-3columns {
        .products-grid {
            .product-item {
                width: 50%;
            }
        }
    }

    .categories-grid {
        &__image {
            border-radius: $block-border-radius $block-border-radius 0 0;
        }

        &__right {
            background-color: $color-white;
            border-radius: 0 0 $block-border-radius $block-border-radius;
        }

        h3 {
            text-transform: uppercase;
        }
    }
}
@include min-screen($screen__l) {
    .categories-grid {
        &__image {
            width: 45%;
            border-radius: $block-border-radius 0 0 $block-border-radius;
        }

        &__right {
            width: 55%;
            min-height: 200px;
            border-radius: 0 $block-border-radius $block-border-radius 0;
        }
    }

    .products-grid {
        .product-item {
            width: 20%;
        }
    }

    .page-layout-1column {
        .product-items {
            display: flex;
            flex-wrap: wrap;
        }

        .products-grid {
            .product-item {
                width: (100% / 5);
            }
        }
    }

    .page-layout-3columns {
        .products-grid {
            .product-item {
                width: 25%;
            }
        }
    }

    .page-products {
        .products-grid {
            .product-items {
                margin: 0;
            }

            .product-item {
                margin-left: calc((100% - 4 * 23.5%) / 3);
                padding: 0;
                width: 23.5%;

                &:nth-child(4n + 1) {
                    margin-left: 0;
                }
            }
        }
    }

    .page-products {
        &.page-layout-1column {
            .products-grid {
                .product-item {
                    margin-left: 0;
                    padding-left: 20px;
                    width: 20%;
                }
            }
        }

        &.page-layout-3columns {
            .products-grid {
                .product-item {
                    margin-left: 1%;
                    width: 32.667%;

                    &:nth-child(3n) {
                        margin-left: 1%;
                    }

                    &:nth-child(3n + 1) {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}

.attribute-badges {
    position: absolute;
    top: 15px;
    left: 15px;
    display: flex;

    &__label {
        margin-right: 15px;
        padding: 2px 7px;
        border-radius: 4px;
        background-color: $color-yellow;
        color: $color-white;
        text-transform: uppercase;
        font-weight: $font_weight__bold;
        font-size: 13px;

        &--sale {
            background-color: $color-light-red;
        }
    }
}

.block.upsell {
    .block-title strong {
        color: $color-white;
        font-family: $font-family__serif;
        @include lib-font-size(28);
        font-weight: $font-weight__regular;
    }

    .product-item-info {
        width: auto;
    }
}
