//
//  Variables
//  _____________________________________________

$checkout-sidebar-shipping-information__padding                : $indent__l;
$checkout-sidebar-shipping-information__line-height            : $checkout-billing-address-details__line-height;

$checkout-sidebar-shipping-information-edit-icon__color        : $text__color__light;
$checkout-sidebar-shipping-information-edit-icon__content      : $icon-edit;
$checkout-sidebar-shipping-information-edit-icon__font-size    : 18px;
$checkout-sidebar-shipping-information-edit-icon__line-height  : 20px;
$checkout-sidebar-shipping-information-edit-icon__top          : 2px;
$checkout-sidebar-shipping-information-edit-icon__hover__color : $text__color__lighter;

//
//  Shipping Information
//  ---------------------------------------------

.opc-block-shipping-information {
    .shipping-information-title {
        @extend .abs-checkout-title;
        color: $color-white;
        font-family: $font-family__serif;
        position: relative;

        .action-edit {
            @extend .abs-action-button-as-link;
            @include lib-css(top, $checkout-sidebar-shipping-information-edit-icon__top);
            @include lib-icon-font(
                $checkout-sidebar-shipping-information-edit-icon__content,
                $_icon-font-size         : $checkout-sidebar-shipping-information-edit-icon__font-size,
                $_icon-font-line-height  : $checkout-sidebar-shipping-information-edit-icon__line-height,
                $_icon-font-text-hide    : true,
                $_icon-font-color        : $checkout-sidebar-shipping-information-edit-icon__color,
                $_icon-font-color-hover  : $checkout-sidebar-shipping-information-edit-icon__hover__color,
                $_icon-font-color-active : $checkout-sidebar-shipping-information-edit-icon__color
            );
            margin: 0;
            position: absolute;
            right: 0;
        }
    }

    .shipping-information-content {
        @include lib-css(line-height, $checkout-sidebar-shipping-information__line-height);
        @include lib-block();
        .actions-toolbar {
            margin-left: 0;
        }
    }

    .ship-to,
    .ship-via {
        margin: 0 0 $indent__base;
    }
}
