.block.widget .widget-product-grid,
.block.widget .widget-category-grid,
.block.widget .category-slider {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;

    .product-item,
    .category-item {
        padding-left: 15px;
        padding-right: 15px;
    }

    .product-item {
        display: flex;

        .product-item-info {
            .special-price {
                margin-right: 10px;
            }
        }

        .product-item-details {
            flex: 1 0 auto;
            display: flex;
            flex-direction: column;

            .product-item-name {
                flex: 1 0 auto;
            }

            .price-box {
                flex-direction: row;
            }
        }
    }
}

.cms-body {
    .pages {
        li {
            padding-left: 0;

            &::before {
                display: none;
            }
        }
    }
}

.block.widget.block-products-list {
    .toolbar-amount {
        display: none;
    }

    .pages {
        display: flex;
        margin-bottom: 30px;
        justify-content: center;
    }
}

.block-categories-list,
.block-products-list {
    .block-content {
        margin-bottom: -30px;
    }
}

.block-categories-list {
    .category-item {
        box-sizing: border-box;
        box-shadow: $block-shadow;
    }
}

.block-product-link,
.block-category-link {
    &.widget {
        display: block;
        margin-bottom: $indent__base;
    }
}

.block-product-link-inline {
    &.widget {
        margin: 0;
    }
}

.block.widget {
    .product-item-info {
        width: auto;
    }

    .pager {
        padding: 0;

        .toolbar-amount {
            float: none;
            @include lib-font-size(12);
        }

        .pages-item-previous {
            padding-left: 0;
        }

        .pages-item-next {
            position: relative;
        }

        .items {
            white-space: nowrap;
        }
    }
}

.brands-swiper {
    padding: 0 30px 30px 30px;
    border: 2px solid $color-blue-border;
    border-top: 0;
}

.brands-swiper {
    .block-title {
        transform: translateY(-50%);
        margin-left: -32px;
        margin-right: -32px;
        text-align: center;
        overflow: hidden;

        strong {
            position: relative;
            font-weight: $font-weight__bold;
            text-transform: uppercase;
            color: $text__color__light;
            letter-spacing: 0.48em;
            font-size: 15px;

            &::before,
            &::after {
                content: '';
                position: absolute;
                top: 50%;
                width: 50vw;
                height: 2px;
                background-color: $color-blue-border;
            }

            &::before {
                right: calc(100% + 15px);
            }

            &::after {
                left: calc(100% + 10px); // text has some whitespace after it, so we use less px
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include min-screen($screen__s) {
    .block.widget .products-grid .product-item,
    .page-layout-1column .block.widget .products-grid .product-item,
    .page-layout-3columns .block.widget .products-grid .product-item {
        width: calc(100% / 3);
    }

    .block.widget .categories-grid .category-item {
        width: (100% / 3);
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .block.widget .products-grid .product-item {
        width: calc(100% / 3);

        .sidebar & {
            margin-left: 0;
            width: 100%;

            .actions-secondary {
                display: block;
                padding: 10px 0;
            }
        }
    }

    .page-layout-1column .block.widget .products-grid .product-item {
        width: (100% / 4);
    }

    .page-layout-3columns .block.widget .products-grid .product-item {
        width: calc(100% / 2);
    }
}

@include min-screen($screen__l) {
    .block.widget .categories-grid .category-item {
        display: flex;
    }

    .block.widget .products-grid .product-item {
        width: calc(100% / 5);
    }

    .page-layout-1column .block.widget .products-grid .product-item {
        width: (100% / 4);
    }

    .page-layout-3columns .block.widget .products-grid .product-item {
        width: calc(100% / 4);
    }
}

@include max-screen($screen__l) {
    .categories-grid {
        &__image {
            height: 140px;
            background-position: center;
        }
    }
}

@include min-screen($screen__m) {
    .block.widget .categories-grid {
        display: flex;
    }

    .sidebar .block.widget .pager {
        .item:not(.pages-item-next):not(.pages-item-previous) {
            @extend .abs-no-display-desktop;
        }

        .pages-item-next {
            padding: 0;

            .action {
                margin: 0;
            }
        }
    }
    .block-categories-list,
    .block-products-list {
        padding: 0 30px 30px 30px;
        border: 2px solid $color-blue-border;
        border-top: 0;
    }

    .block-categories-list,
    .block-products-list {
        .block-title {
            transform: translateY(-50%);
            margin-left: -32px;
            margin-right: -32px;
            text-align: center;
            overflow: hidden;

            strong {
                position: relative;
                font-weight: $font-weight__bold;
                text-transform: uppercase;
                color: $text__color__light;
                letter-spacing: 0.48em;
                font-size: 15px;

                &::before,
                &::after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    width: 50vw;
                    height: 2px;
                    background-color: $color-blue-border;
                }

                &::before {
                    right: calc(100% + 15px);
                }

                &::after {
                    left: calc(100% + 10px); // text has some whitespace after it, so we use less px
                }
            }
        }
    }
}

@include max-screen($screen__m) {
    .cms-special-offers {
        .block.widget.block-products-list {
            display: block;
        }
    }

    .block-categories-list,
    .block-products-list {
        .block-title {
            strong {
                color: $color-white;
                font-family: $font-family__serif;
                font-size: 26px;
                font-weight: normal;
            }
        }
    }

    .categories-grid {
        &__image {
            border-radius: $block-border-radius;
        }
    }
}

@include max-screen($screen__s) {
    .block.widget .categories-grid .category-item {
        width: 50%;
    }
}
