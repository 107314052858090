.cookie-notice {
    display: flex;
    justify-content: center;
    position: fixed;
    background-color: #fff;
    box-shadow: 0 15px 30px #000;
    bottom: 0;
    width: 100%;
    z-index: 100;

    .wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px;

        img {
            width: 40px;
            margin-right: 15px;
        }

        .allow {
            position: absolute;
            right: 20px;
            background-color: $color-success-bg;
            color: #fff;
            font-size: 12px;
            font-weight: $font-weight__bold;

            &:hover {
                background-color: darken($color-success-bg, 5%);
            }
        }
    }

    .allow {
        margin-left: 20px;
    }
}


@include max-screen($screen__m) {
    .cookie-notice {
        .wrapper {
            .allow {
                position: relative;
                right: auto;
            }
        }
    }
}
