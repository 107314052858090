//
//  Checkout Estimated Total
//  ---------------------------------------------

.opc-estimated-wrapper {
    @extend .abs-add-clearfix;
    @extend .abs-no-display-desktop;
    @include lib-css(border-bottom, $border-width__base solid $color-gray80);
    margin: 0 0 15px;
    padding: 18px 15px;

    .estimated-block {
        @include lib-css(font-size, $checkout-step-title-mobile__font-size);
        @include lib-css(font-weight, $font-weight__bold);
        float: left;

        .estimated-label {
            display: block;
            margin: 0 0 $indent__xs;
        }
    }

    .minicart-wrapper {
        .action {
            &.showcart {
                &:before {
                    @include lib-css(color, $primary__color);
                }
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__l) {
    .opc-estimated-wrapper {
        color: #fff;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .opc-estimated-wrapper {
        display: none;
    }
}
