//
//  Variables
//  _____________________________________________

$active-nav-indent: 54px;

.panel.header {
    .links,
    .switcher {
        display: none;
    }
}

body.nav-open {
    overflow: hidden;
}

.nav-sections {
    @include lib-css(background, $navigation__background);
}

.nav-toggle {
    @include lib-icon-font(
        $icon-menu,
        $_icon-font-size        : 28px,
        $_icon-font-color       : $header-icons-mobile-color,
        $_icon-font-color-hover : $header-icons-mobile-color-hover,
        $_icon-font-margin      : 0 15px 0 0
    );
    @include lib-icon-text-hide();
    cursor: pointer;
    display: block;
    font-size: 0;
    z-index: 14;
}

.nav-toggle-mobile {
    @include lib-icon-font(
        $_icon-font-content     : $icon-remove,
        $_icon-font-size        : 15px,
        $_icon-font-color       : $color-white,
        $_icon-font-color-hover : $color-white
    );
    @include lib-icon-text-hide();
    cursor: pointer;
    display: block;
    font-size: 0;
    position: absolute;
    top: 20px;
    right: 25px;
}

//
//  Megamenu Desktop
//  _____________________________________________

@include min-screen($screen__l) {
    .sections.nav-sections.solid {
        background-color: $color-dark-blue-bg;
    }
}

//
//  Megamenu Mobile
//  _____________________________________________

@include max-screen($screen__l) {
    .nav-sections {
        .block {
            margin-bottom: 0;
        }

        .opener.item-active:before, .drill-opener:before, .opener:before {
            font-family: "tickles-icons";
            font-weight: 100;
        }

        .nav-sections-item-content {
            display: inline-block;
            width: 100%;
        }
    }

    .offcanvas_information {
        padding: 0 15px;
    }

    .nav-sections-items {
        background-color: $color-white;
        height: 100%;
    }
}


//
//  Mobile
//  _____________________________________________

@include max-screen($screen__l) {
    .customer-welcome + .authorization-link {
        display: none;
    }

    .navigation {
        padding: 0;

        .parent {
            .level-top {
                position: relative;
                @include lib-icon-font(
                    $_icon-font-content  : $icon-down,
                    $_icon-font-size     : 42px,
                    $_icon-font-position : after,
                    $_icon-font-display  : block
                );

                &:after {
                    position: absolute;
                    right: 17px;
                    top: 12px;
                    @include lib-font-size(14);
                }

                &.ui-state-active {
                    @include lib-icon-font-symbol(
                        $_icon-font-content  : $icon-up,
                        $_icon-font-position : after
                    );
                }
            }
        }
    }

    .nav-sections {
        -webkit-overflow-scrolling: touch;
        @include lib-css(transition, left 0.2s, 1);
        @include lib-css(transition, opacity 0.2s, 1);
        opacity: 0;
        height: 100%;
        left: calc(-1 * (100% - #{$active-nav-indent}));
        overflow: auto;
        position: fixed;
        top: 0;
        padding-top: 61px;
        width: 100%;

        .switcher {
            border-top: 1px solid $color-gray82;
            font-size: 1.6rem;
            font-weight: 700;
            margin: 0;
            padding: 0.8rem 3.5rem 0.8rem 2rem;

            .label {
                display: block;
                margin-bottom: $indent__xs;
            }

            &:last-child {
                border-bottom: 1px solid $color-gray82;
            }
        }

        .switcher-trigger {
            strong {
                position: relative;
                @include lib-icon-font(
                    $_icon-font-content  : $icon-down,
                    $_icon-font-size     : 42px,
                    $_icon-font-position : after,
                    $_icon-font-display  : block
                );

                &:after {
                    position: absolute;
                    right: -40px;
                    top: -16px;
                }
            }

            &.active strong {
                @include lib-icon-font-symbol(
                    $_icon-font-content  : $icon-up,
                    $_icon-font-position : after
                );
            }
        }

        .switcher-dropdown {
            @include lib-list-reset-styles();
            padding: $indent__s 0;
        }
    }

    .nav-before-open {
        height: 100%;
        overflow-x: hidden;
        width: 100%;

        .page-wrapper {
            @include lib-css(transition, left 0.3s, 1);
            height: 100%;
            left: 0;
            overflow: hidden;
            position: relative;
        }

        body {
            height: 100%;
            overflow: hidden;
            position: relative;
            width: 100%;
        }
    }

    .nav-open {
        .page-wrapper {
            left: 80vw;
        }

        .nav-sections {
            $_shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);
            @include lib-css(box-shadow, $_shadow, 1);
            background-color: $djm-color-dark-blue3;
            left: 0;
            opacity: 1;
            z-index: 99;
        }

        .nav-toggle {
            &:after {
                background: rgba(0, 0, 0, $overlay__opacity);
                content: '';
                display: block;
                height: 100%;
                position: fixed;
                right: 0;
                top: 0;
                width: 100%;
                z-index: 1;
            }
        }
    }

    .nav-sections-items {
        @include lib-clearfix();
        position: relative;
        z-index: 1;
    }

    .nav-sections-item-title {
        box-sizing: border-box;
        padding: 10px 15px;
        font-size: 1.6rem;

        .nav-sections-item-switch {
            font-weight: $font-weight__semibold;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .nav-sections-item-content {
        box-sizing: border-box;
    }

    @include lib-main-navigation();
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__l) {
    .customer-name,
    .nav-toggle-mobile {
        display: none;
    }

    .mobile-nav-title {
        display: none;
    }

    .header.panel > .header.links > .customer-welcome + .authorization-link {
        display: none;
    }

    .nav-toggle {
        display: none;
    }

    .nav-sections {
        @include lib-vendor-prefix-flex-shrink(0);
        @include lib-vendor-prefix-flex-basis(auto);
        margin-bottom: $indent__m;
        @include lib-css(background, $navigation-desktop__background);
    }

    .nav-sections-item-title {
        display: none;
    }

    .nav-sections-item-content {
        display: block !important;
    }

    .nav-sections-item-content > .main-menu {
        display: flex;
    }

    .nav-sections-item-content {
        > .navigation {
            display: block;
        }
    }

    @include lib-main-navigation-desktop();
}

@include min-screen($screen__m) {
    .panel.header {
        .links,
        .switcher {
            display: inline-block;
        }
    }
}
