@include min-screen($screen__l) {
    .refine-toggle {
        display: none;
    }

    .filter-state-wrapper {
        .filter-state {
            padding: 0;
            background: none;

            a {
                color: $color-light-blue1;
            }

            .action.remove {
                &:before {
                    position: absolute;
                    color: $color-white;
                    font-size: 6px;
                    line-height: 0.1;
                    top: 6px;
                    left: 3px;
                }
            }

            .block-subtitle {
                font-size: 12px;
            }

            .items {
                margin: 15px 0 0 0;

                .item {
                    font-size: unset;
                }
            }

            .filter {
                &-current {
                    a.action.remove {
                        position: relative;
                    }
                }
            }

            .filter-current {
                border: 0;
                border-radius: $block-border-radius;
                background-color: rgba(10, 45, 103, .6);
                margin: 0 0 10px;
                padding: 25px;

                .items {
                    display: flex;
                    flex-direction: column;
                    margin: 0;

                    .item {
                        font-size: 1.4rem;
                        color: $djm-color-light-blue;
                        display: flex;
                        align-items: center;
                        margin: 10px 0 0 0;

                        a.action.remove {
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                            border: 1px solid #fff;
                            border-radius: 50%;
                            min-width: 12px;
                            min-height: 12px;
                            font-size: 12px;
                            color: $color-white;
                            cursor: pointer;

                            .remove-btn-span {
                                margin: 0 0 3px 0;
                            }
                        }

                        .filter-label {
                            margin-left: 7px;
                        }
                    }
                }
            }
        }

        .filter-state.mobile.filter {
            display: none;
        }
    }
}

.autocomplete-loader {
    position: absolute;
    width: 25px;
    display: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &.active {
        display: block;
    }
}

.autocomplete-wrapper {
    @extend .abs-search-styles;
    max-width: $layout__max-width;
    position: absolute;
    min-height: 330px;
    margin: 35px 0 0;
    left: 50%;
    transform: translateX(-50%);
    box-sizing: border-box;
    text-align: left;
    z-index: 100;
    width: 100%;
    display: none;

    .autocomplete {
        display: flex;
        min-height: 330px;

        .item-wrapper {
            li {
                margin-bottom: 5px;
            }
        }
    }

    &.active {
        display: block;
    }

    .autocomplete-title {
        display: block;
        text-transform: uppercase;
        color: $color-gray-light8;
        font-size: 11.2px;
        font-weight: $font-weight__bold;
        margin-bottom: 4px;
    }

    &__left, &__right {
        box-sizing: border-box;
    }

    &__left {
        border-right: 1px solid $border-color__lighter;
        width: 372px;
        padding: 22px 13px;
    }

    &__right {
        display: flex;
        position: relative;
        flex: 1;
        flex-direction: column;

        .recommend-categories, .recommend-categories__wrapper {
            display: inline-block;
        }

        .recommend-categories {
            a {
                margin-right: 5px;
            }
        }
    }

    .autocomplete {
        .suggestion,
        .item-count {
            padding: 13px 10px;
            text-align: center;
            font-size: 13px;
            line-height: 13px;
            color: #999;
            background-color: #f9f9f9;

            .suggest {
                color: $color-blue1;
                font-size: 1em;
                font-style: italic;
            }
        }

        .suggestion {
            text-align: left;
        }

        ul.category-wrapper {
            @include lib-list-reset-styles();

            li {
                margin: 0;
            }

            .autocomplete-category {
                @include lib-link-all($color-black);

                .title {
                    padding: 5px 10px;
                    margin: 0;
                }
            }
        }

        ul.item-wrapper {
            @include lib-list-reset-styles();
            padding: 15px 0;
            display: flex;
            flex-wrap: wrap;

            li {
                flex: 50%;
            }

            .autocomplete-item {
                display: flex;
                align-items: flex-start;
                margin: 0;
                padding: 8px 1rem;
                cursor: pointer;

                &:hover {
                    background-color: #f5f5f5;
                    text-decoration: none;
                }

                img {
                    width: 50px;
                    height: 52px;
                    margin-right: 1rem;
                }

                .meta {
                    flex-grow: 1;
                    overflow: hidden;

                    .title {
                        display: block;
                        margin-bottom: 0.5rem;

                        span {
                            color: #333;
                            text-decoration: none;
                        }

                        .sku {
                            color: $color-gray52;
                        }
                    }

                    .in-stock {
                        span {
                            display: inline-block;

                            &.qty:before {
                                content: '';
                            }

                            &.text {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }

        .no-results {
            padding: 40px;
            height: 100%;
            background-color: #f9f9f9;

            span {
                color: $djm-color-gray;
                font-weight: 700;
                font-size: 16px;
            }
        }
    }
}

.slider-filter {
    .ui-slider-handle {
        background-color: $djm-color-light-blue;
        height: 16px;
        width: 16px;
    }

    .ui-slider-horizontal {
        margin: 10px 0 20px;
        display: flex;
        align-items: center;
        height: 4px;
    }

    .ui-slider-handle {
        &:nth-child(even) {
            margin-left: 0;
        }

        &:nth-child(odd) {
            margin-left: -16px;
        }
    }

    .slider-controls {
        display: flex;
        flex-direction: column;

        label {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 5px 10px;

            span {
                color: $djm-color-light-blue;
                text-transform: uppercase;
                font-size: 11px;
                margin-right: 10px;
            }

            input[type="number"] {
                color: $color-gray55;
                box-shadow: none;
                font-weight: 600;
                font-size: 13px;
                border: none;
                padding: 0 10px;
                width: 40%;
                text-align: right;
                border-radius: 6px;
            }
        }
    }
}

@include max-screen($screen__l) {
    .refine-toggle {
        display: block;
        font-family: $font-family__sans-serif;
        text-align: center;
        height: 40px;
        line-height: 40px;
        border: 1px solid #ededed;
        margin: 10px 0 20px;
        font-weight: $font-weight__bold;
        cursor: pointer;
        color: $color-white;
    }

    .autocomplete-wrapper {
        margin: 46px 0 0;

        &__left {
            border: 0;
        }

        .autocomplete {
            max-height: none;
            flex-direction: column;
        }
    }

    .filter-options-title {
        &:after {
            display: none;
        }
    }

    .filter-options-content {
        .label {
            padding: 0;

            &:before, &:after {
                display: none;
            }
        }

        input[type=checkbox]:checked ~ .label:after {
            display: none;
        }
    }

    .filter-state-wrapper {
        .filter-state {
            display: none;
            background: none;
            opacity: 0.9;
        }

        .filter-state.mobile.filter {
            display: none;
            padding: 0;

            .block-subtitle {
                font-size: 12px;
                color: $color-white;
            }

            .filter-current {
                border: 0;
                border-radius: $block-border-radius;
                background-color: rgba(10, 45, 103, .6);
                margin: 0 0 10px;
                padding: 25px;

                .items {
                    display: block;
                    margin: 0;

                    .item {
                        font-size: 1.4rem;
                        color: $djm-color-light-blue;

                        .remove-btn {
                            position: absolute;
                            top: 50%;
                            left: 0;
                            transform: translateY(-50%);
                            border: 1px solid #fff;
                            border-radius: 50%;
                            width: 12px;
                            height: 12px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 12px;
                            color: #fff;
                            cursor: pointer;

                            .remove-btn-span {
                                margin: 0 0 3px 0;
                            }
                        }

                        .filter-label {
                            margin-left: 23px;
                        }
                    }
                }
            }
        }
    }
}

.filters .filter-container {
    color: $djm-color-light-blue;

    .filter-options-title {
        font-size: 12px;
        font-weight: 700;
        color: $color-white;
        text-transform: uppercase;
        letter-spacing: 1px;
        margin-bottom: 10px;
    }

    .filter-item {
        &.c-checkbox {
            .label {
                display: flex;
                user-select: none;

                span:not(.count) {
                    flex: 1;
                    text-align: start;
                }
            }
        }
    }
}

.product-list-wrapper.loading {
    opacity: 0.5;
}

.category-view-search {
    display: none;
}

.filters {
    &.filters-sorting {
        display: none;
    }
}

.sidebar-main {
    .filter-options-item {
        max-height: 350px;
        overflow-y: auto;
    }
}

@include min-screen($screen__l) {
    .filters {
        display: flex;
        align-items: center;
        flex: 1;
        margin-bottom: 0;

        &.filters-sorting {
            display: inline-block;
            min-width: fit-content;
            margin-right: 8px;

            .filter-container {
                .filter-options-title {
                    padding: 0 20px 0 33px;
                    background: $toolbar-element-background url('../images/sorting.svg') no-repeat 18% 49%;

                    &:after {
                        display: none;
                    }
                }
            }

            .filters-sorting__label {
                display: flex;
                height: 33px;
                align-items: center;
                color: $djm-color-light-blue;
                font-weight: $font-weight__bold;
                font-size: 11px;
                text-transform: uppercase;
            }
        }

        .filter-container {
            flex-wrap: wrap;
            color: $color-gray-text;

            .filter-options-title {
                box-sizing: border-box;
                margin-bottom: 10px;
                display: flex;
                align-items: center;
                padding: 0 50px 0 20px;
                height: 33px;
                border-radius: $button__border-radius;
                border: $button-ghost__border;
                color: $text__color__light;
                font-size: 11px;
                font-weight: $font-weight__regular;
                user-select: none;

                &:after {
                    top: initial;
                    right: 20px;
                    font-weight: $font-weight__bold;
                }
            }
        }
    }

    .toolbar {
        .inline-search {
            display: flex;
        }
    }

    .inline-search {
        position: relative;

        .category-view-search {
            display: block;
            width: 190px;
            padding: 0 20px;
            height: 33px;
            border-radius: 40px;
            border: none;
            font-size: 12px;
        }

        @include lib-icon-font(
            $icon-search,
            $_icon-font-size: 13px,
            $_icon-font-line-height: 1,
            $_icon-font-position: after,
            $_icon-font-color: $djm-color-gray3
        );

        &:after {
            position: absolute;
            right: 15px;
            top: 11px;
        }
    }

    .sorter-action {
        &:before {
            transform: translateY(6px);
        }
    }

    .sidebar-filter-wrapper {
        .filter-options-content {
            overflow-y: hidden;
        }
    }

    .filter-options {
        &-content {
            margin: 0;
            overflow-y: auto;

            .count {
                color: $color-gray-text;
            }
        }

        &-item {
            padding: 0;
            background: none;
            margin: 0 0 0 10px;

            &.active {
                .filter-options-content {
                    display: block;
                }
            }

            &.has-active {
                .filter-options-title {
                    border-color: $color-white;
                }
            }
        }
    }

    .toolbar-sorter, .sorter-wrapper {
        display: flex;
    }

    .sidebar-main {
        .filters .filter-container {
            width: 100%;
            border: 0;
            border-radius: 6px;
            background-color: rgba(10, 45, 103, 0.6);
            margin: 0 0 10px;
            padding: 25px;

            .filter-options {
                &-content {
                    color: $djm-color-light-blue;
                    position: relative;
                    visibility: visible;
                    display: block;
                    padding: 0;
                    top: 0;
                    background: none;
                    border: 0;
                    box-shadow: none;
                    width: auto;

                    & .count {
                        color: $djm-color-light-blue;
                    }

                    label {
                        padding: 0;

                        &:before, &:after {
                            display: none;
                        }
                    }

                    .item {
                        padding: 5px 0;
                    }
                }

                &-title {
                    color: $color-white;
                    font-weight: $font-weight__semibold;
                    letter-spacing: initial;
                    font-size: 12px;
                    height: auto;
                    border: 0;
                    padding: 0;

                    &:after {
                        display: none;
                    }
                }

                &-item {
                    width: 100%;
                    margin: 0;
                }
            }
        }
    }

    .filter-container {
        display: flex;
    }
}

@include max-screen($screen__l) {
    .autocomplete-loader {
        top: -52px;
        left: 50%;
        transform: translateX(-50%);
    }
}

@include screen($screen__m, $screen__l) {
    .autocomplete-wrapper {
        margin: -20px 0 0;
    }
}


