$stock-available-color   : $djm-color-dark-green;
$stock-unavailable-color : $djm-color-dark-red;

.out-of-stock,
.stock.unavailable {
    span:not(.add-to-cart__not-adding) {
        @include lib-icon-font(
            $_icon-font-content: false,
            $_icon-font-position: before,
            $_icon-font-margin: 0 8px 0 0,
            $_icon-font-size: 15px,
            $_icon-font-line-height: 1,
            $_icon-font-color: $color-white
        );
        display: flex;
        align-items: center;
        margin-right: 10px;
    }
}

.stock.available,
.in-stock {
    margin-right: 17.5px;

    span {
        @include lib-icon-font(
            $_icon-font-content: false,
            $_icon-font-position: before,
            $_icon-font-margin: 0 3px 0 0,
            $_icon-font-size: 20px,
            $_icon-font-line-height: 1,
            $_icon-font-color: $color-white
        );
        display: flex;
        align-items: center;
        margin-right: 5px;
    }
}

.product-list-wrapper {
    .stock.unavailable,
    .stock.available,
    .in-stock,
    .out-of-stock {
        span {
            &:before {
                font-size: 12px;
            }
        }
    }
}

.stock.available,
.in-stock {
    color: $stock-available-color;

    span {
        @include lib-icon-font-symbol($icon-success);
        color: $stock-available-color;

        &::before {
            color: $stock-available-color;
        }
    }
}

.stock.unavailable,
.out-of-stock {
    color: $stock-unavailable-color;

    span:not(.add-to-cart__not-adding) {
        @include lib-icon-font-symbol($icon-warning);
        color: $stock-unavailable-color;

        &::before {
            color: $stock-unavailable-color;
        }
    }
}

.product-info-main {
    .stock {
        &.available,
        &.unavailable {
            display: flex;
            align-items: center;
        }
    }
}
