@keyframes pulsate {
    0% {
        transform: scale(0.2);
    }

    50% {
        transform: scale(0.25);
    }

    100% {
        transform: scale(0.2);
    }
}

@include max-screen($screen__l) {
    .nav-deeper {
        .mobile-nav-title {
            display: none;
        }

        .mobile-nav-back {
            @include lib-icon-font(
                $_icon-font-content: $icon-prev,
                $_icon-font-size: 16px,
                $_icon-font-position: after,
                $_icon-font-display: flex,
                $_icon-font-color: $color-white
            );
            display: flex;
            position: absolute;
            align-items: center;
            justify-content: center;
            top: 5px;
            left: 8px;
            width: 50px;
            height: 50px;

            & > span {
                border: 0;
                clip: rect(0, 0, 0, 0);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 1px;
            }
        }
    }

    .mobile-nav-back {
        display: none;
    }

    .mobile-nav-title {
        color: $color-white;
        position: absolute;
        top: 22px;
        left: 28px;
        font-size: 16px;
        font-weight: bold;
        line-height: 1;
    }

    .desktop-additional-menu {
        display: none;
    }

    .megamenu {
        &__content-inner {
            &.page-main {
                padding: 0 20px;
            }

            .megamenu__first-tier {
                margin: 0;
            }
        }

        &__wrapper {
            .page-main {
                height: calc(100dvh - 61px);
                overflow-y: scroll;
            }

            .megamenu__content-container {
                display: flex;
                top: 0;
                padding: 0;
                box-shadow: none;

                .megamenu__link.parent {
                    &.active {
                        background: none;
                    }
                }

                .megamenu__mobile-additional {
                    .megamenu__link {
                        a {
                            height: 60px;
                        }

                        &:last-of-type {
                            a {
                                border-bottom: none;
                            }
                        }
                    }
                }

                .megamenu__mobile-information {
                    margin-top: 20px;

                    .megamenu__link {
                        a {
                            height: 60px;
                        }

                        &:first-of-type {
                            a {
                                position: relative;

                                &:before {
                                    position: absolute;
                                    content: '';
                                    top: -18px;
                                    left: -20px;
                                    height: 18px;
                                    width: 100vw;
                                    background-color: $color-gray95;
                                }
                            }
                        }

                        &:first-of-type {
                            a {
                                font-weight: bold;
                            }
                        }
                    }
                }

                .megamenu__first-tier, .megamenu__second-tier, .megamenu__third-tier {
                    display: none;

                    &.active {
                        display: block;
                    }

                    .megamenu__inner-link {
                        &.active {
                            background: none;
                            border-bottom: 1px solid $djm-color-light-gray7;
                        }

                        &:has(+ .active) a {
                            border-bottom: 1px solid $djm-color-light-gray7;
                        }
                    }
                }

                .megamenu__second-tier, .megamenu__third-tier {
                    .megamenu__column {
                        &-title {
                            position: fixed;
                            top: 20px;
                            padding: 0;
                            color: $color-white;
                            left: 50%;
                            transform: translateX(-50%);

                            h4 {
                                color: $color-white;
                            }
                        }

                        &.active {
                            overflow-y: scroll;

                            .megamenu__column-title {
                                display: block;
                            }
                        }
                    }
                }

                .megamenu__first-tier {
                    display: none;

                    &.active {
                        display: block;
                    }
                }

                .megamenu__mobile-additional, .megamenu__mobile-information {
                    display: block;
                }

                .megamenu__second-tier {
                    .megamenu__inner-link {
                        padding: 0;

                        &.megamenu__view-all-link {
                            display: block;

                            a {
                                font-weight: bold;
                            }
                        }

                        a {
                            padding-left: 28px;

                            img {
                                display: none;
                            }
                        }
                    }
                }

                .megamenu__third-tier {
                    .megamenu__column {
                        .megamenu__inner-link {
                            margin-bottom: 0;
                            padding: 0;

                            a {
                                padding-left: 28px;
                                display: flex;
                                align-items: center;
                                box-sizing: border-box;
                                height: 60px;
                                width: 100%;
                                font-size: 16px;
                                line-height: 24px;
                                border-bottom: 1px solid $djm-color-light-gray7;
                            }
                        }
                    }
                }

                .megamenu__link, .megamenu__inner-link {
                    padding: 0;

                    &.active a {
                        &:after {
                            display: none;
                        }
                    }

                    &.parent {
                        @include lib-icon-font(
                            $_icon-font-content: $icon-next,
                            $_icon-font-size: 13px,
                            $_icon-font-position: after,
                            $_icon-font-display: flex,
                            $_icon-font-color: $color-black
                        );
                        position: relative;

                        &:after {
                            position: absolute;
                            right: 25px;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                }

                .megamenu__link {
                    img {
                        width: 64px;
                    }

                    a {
                        height: 80px;
                    }
                }
            }
        }

        &__root-tier {
            .megamenu__all-category-node {
                display: none;
            }
        }

        &__second-tier, &__third-tier {
            .megamenu__column {
                background-color: $color-white;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }

        &__tier {
            .megamenu__column {
                border-right: none;

                &-title {
                    display: none;

                    & + .megamenu__link a {
                        border-top: none;
                    }
                }
            }
        }

        &__wrapper {
            .page-main {
                display: block;
            }
        }
    }
}

.navigation {
    &__bar {
        display: flex;
        justify-content: space-between;
    }

    &__left, &__right {
        display: flex;

        .navigation-menu {
            padding: 0;
            text-transform: uppercase;
            font-size: 13px;
            font-weight: bold;
        }
    }
}

.megamenu {
    &__wrapper {
        .page-main {
            width: 100%;
            flex-grow: 0;
            flex-shrink: 1;
            flex-basis: auto;
        }
    }

    &__mobile-additional, &__mobile-information {
        display: none;
    }

    &__all-category-node {
        @include lib-icon-font(
            $_icon-font-content: $icon-up,
            $_icon-font-size: 13px,
            $_icon-font-position: after,
            $_icon-font-display: block,
            $_icon-font-margin: 0 0 0 7px
        );
        display: flex;
        align-items: center;

        &.active {
            &:after {
                content: $icon-down;
            }
        }
    }

    &__second-tier {
        .megamenu__column {
            .megamenu__view-all-link {
                display: none;

                & + .megamenu__link, + .megamenu__inner-link {
                    a {
                        border-top: 1px solid $djm-color-light-gray7;
                    }

                    &.active {
                        a {
                            border-top: 1px solid transparent;
                        }
                    }
                }
            }
        }
    }

    &__column {
        display: flex;
        flex-direction: column;
        border-right: 1px solid $djm-color-light-gray7;
        height: 100%;

        &-title {
            h4 {
                font-weight: bold;
                font-size: 16px;
                margin: 0;
                padding-left: 8px;
                color: $djm-color-dark-gray2;
                padding-bottom: 20px;
            }

            & + .megamenu__link, + .megamenu__inner-link {
                a {
                    border-top: 1px solid $djm-color-light-gray7;
                }

                &.active {
                    a {
                        border-top: 1px solid transparent;
                    }
                }
            }
        }
    }

    &__tier {
        min-width: 320px;
    }

    &__first-tier {
        margin-left: -8px;
    }

    &__root-tier {
        height: 100%;
        display: flex;
        align-items: center;
        margin-right: 40px;

        a {
            color: $color-white;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 13px;

            &:hover {
                text-decoration: none;
            }
        }
    }

    &__content-container, &__column {
        overflow-y: auto;
    }

    &__content-container {
        display: none;
        position: absolute;
        z-index: 10;
        right: 0;
        left: 0;
        background-color: $color-white;
        padding: 20px 0;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

        .megamenu__second-tier, .megamenu__third-tier {
            .megamenu__column-title {
                padding-left: 25px;
            }

            .megamenu__inner-link {
                padding: 0 25px;
            }

            .megamenu__column {
                display: none;

                &.active {
                    display: flex;
                }
            }
        }

        &.active {
            display: block;
        }

        .megamenu__link {
            display: flex;
            align-items: center;

            img {
                width: 43px;
                margin-right: 13px;
            }
        }

        .megamenu__third-tier {
            .megamenu__column {
                border-right: none;

                &-title {
                    h4 {
                        padding: 0 0 28px 0;
                    }
                }
            }

            .megamenu__inner, .megamenu__inner-link {
                padding: 0 25px;
                margin-bottom: 24px;

                a {
                    display: flex;
                    align-items: center;
                    box-sizing: border-box;
                    height: auto;
                    width: 100%;
                    font-size: 16px;
                    line-height: 16px;
                    color: $djm-color-dark-gray;
                    border: none;

                    &:hover {
                        color: $djm-color-blue;
                        text-decoration: underline;
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .megamenu__link, .megamenu__inner-link {
            padding: 0 25px 0 8px;

            a {
                display: flex;
                align-items: center;
                box-sizing: border-box;
                height: 60px;
                width: 100%;
                font-size: 16px;
                line-height: 24px;
                color: $djm-color-dark-gray;
                border-bottom: 1px solid $djm-color-light-gray7;
            }

            &:has(+ .active) {
                a {
                    border-bottom: 1px solid transparent;
                }
            }

            &.active {
                background-color: $djm-color-light-blue2;
                border-bottom: 1px solid transparent;

                a {
                    @include lib-icon-font(
                        $_icon-font-content: $icon-next,
                        $_icon-font-size: 13px,
                        $_icon-font-position: after,
                        $_icon-font-display: flex,
                        $_icon-font-margin: 0 0 0 7px,
                        $_icon-font-color: $color-black
                    );
                    position: relative;
                    border-bottom: 1px solid transparent;

                    &:after {
                        position: absolute;
                        right: 0;
                    }
                }
            }

            &:hover {
                text-decoration: none;
            }
        }
    }

    &__content-inner {
        display: flex;
    }
}

.navigation-menu {
    @include make-container();
    max-width: $layout__max-width;
    margin: 0 auto;
    box-sizing: border-box;

    * {
        box-sizing: border-box;
    }

    a {
        @include lib-link-all($_link-color: $color-white);
        display: block;
        font-size: 14px;
        font-weight: 500;
        padding: 18px 0;
        line-height: 1;
        letter-spacing: 0.06em;

        &:hover {
            text-decoration: none;
        }
    }

    &__list,
    &__sub-menu-row,
    &__sub-menu-list {
        @include lib-list-reset-styles();
    }

    &__sub-menu-top-link {
        font-weight: $font-weight__medium;
    }
}

@include min-screen($screen__l) {
    .megamenu__column {
        max-height: 700px;
    }

    .megamenu__second-tier {
        .megamenu__column.scrollable {
            position: relative;
            overflow-y: scroll;

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                height: 200px;
                width: 100%;
                background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 1) 100%);
                z-index: 10;
            }

            &:before {
                content: url('../images/scrolldown.png');
                display: flex;
                justify-content: center;
                transform: scale(.2);
                position: absolute;
                bottom: -50px;
                left: 0;
                right: 0;
                z-index: 20;
                animation: pulsate 1.5s infinite;
            }

            &.scrolled {
                &:after, &:before {
                    display: none;
                }
            }
        }
    }

    .navigation-menu {
        &-container {
            position: relative;

            &--active {
                background-color: $color-nav-bg;
            }
        }

        &__list {
            display: flex;
            overflow-x: auto;
            white-space: nowrap;
        }

        &__item {
            margin: 0;

            &:not(:last-of-type) {
                margin-right: 40px;
            }

            &.grand-parent {
                .navigation-menu__sub-menu-row {
                    @include make-row();

                    & > .navigation-menu__sub-menu-column > .navigation-menu__sub-menu-list {
                        column-count: 1;
                        column-gap: 35px;
                    }
                }
            }

            &:not(.grand-parent) {
                .navigation-menu__sub-menu-row {
                    column-count: 5;
                    column-gap: 35px;
                }
            }
        }

        &__top-link {
            display: inline-block;
            padding: 15px 0;
            font-weight: $font-weight__medium;
            font-size: 14px;
            text-transform: uppercase;
        }

        &__sub-menu-container {
            position: absolute;
            z-index: $z-index__megamenu;
            left: 0;
            right: 0;
            width: 100%;
            border-top: 1px solid lighten($color-dark-blue-bg, 8%);
            background-color: $color-white;

            h4 {
                font-size: 15px;
                color: $text__color__lighter;
                margin: 0 0 15px;
                text-transform: uppercase;
            }

            p {
                font-size: 15px;
                line-height: 1.7;
                text-transform: initial;
                letter-spacing: normal;
                color: $text__color__light;
            }
        }

        &__sub-menu {
            padding: 55px 20px 75px;
            max-width: 1235px;
            margin: 0 auto;

            a {
                font-size: 13px;
                font-weight: 500;
                padding: 0;
                border: 0;
                text-transform: none;
                line-height: 27px;
            }
        }

        &__sub-menu-column {
            @include make-col-ready();
            @include make-col(8);
            margin-bottom: 0;
        }

        &__sub-menu-wrapper {
            margin-bottom: 40px;
        }

        &__sub-menu-list {
            margin-top: 5px;
        }

        &__top-linkall {
            display: none;
        }

        &__sub-menu-item {
            display: block;
            line-height: 1.2;
            margin: 0;
        }
    }
}

@include min-screen($screen__l) {
    .navigation-menu {
        &__sub-menu-column {
            width: 20%;
            flex: 0 0 20%;
        }
    }
}

//
//  Tablet
//  _____________________________________________

@include max-screen($screen__l) {
    .megamenu__column {
        max-height: 400px;
    }

    .megamenu__first-tier {
        .megamenu__column {
            max-height: initial;
        }
    }

    .nav-sections {
        a {
            @include lib-link(
                $_link-color: $text__color,
                $_link-color-visited: $text__color,
                $_link-color-hover: $text__color,
                $_link-color-active: $text__color
            );
        }

        &-item-title {
            display: none;
        }
    }

    .navigation-menu, {
        padding-right: 0;
        padding-left: 0;

        a {
            width: 100%;
            margin: 20px 0;
            padding: 0 15px;
            display: inline-block;
        }

        &__sub-menu-container {
            padding-left: 15px;
        }

        &__sub-menu {
            border-left: 2px solid $border-color__base;
        }
    }

    .navigation-menu,
    .nav-sections .footer-menu {
        a {
            display: block;

            &:hover {
                text-decoration: none;
            }
        }

        &__item.parent {
            @include lib-icon-font(
                $_icon-font-content: $icon-down,
                $_icon-font-size: 42px,
                $_icon-font-position: after,
                $_icon-font-display: block
            );
            position: relative;
            width: 100%;

            &::after {
                @include lib-font-size(14);
                position: absolute;
                z-index: 500;
                top: -2px;
                right: 15px;
                pointer-events: none;
            }
        }

        &__item {
            margin: 0;

            &--active.parent {
                @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-up,
                    $_icon-font-position: after
                );
            }

            &--active {
                > a {
                    @include lib-link(
                        $_link-color: $color-medium-blue3,
                        $_link-color-visited: $color-medium-blue3,
                        $_link-color-hover: $color-medium-blue3,
                        $_link-color-active: $color-medium-blue3
                    );
                    font-weight: bold;
                }
            }
        }

        &__top-linkall {
            margin-bottom: 10px;
        }
    }

    .nav-sections-item-content {
        > ul {
            margin: 0;
            padding-left: 15px;

            li {
                margin: 0;
                list-style: none;
            }

            a {
                display: block;
                color: $color-gray-text;
                font-size: 14px;
                font-weight: 500;
                margin: 15px 0;
                letter-spacing: 0.06em;

                &:hover {
                    color: $color-gray-text;
                    text-decoration: none;
                }
            }
        }
    }
}

@media (max-height: 800px) and (min-width: 1023px) {
    .megamenu {
        &__content-container, &__column {
            max-height: 350px;
        }
    }
}

@media (max-height: 1000px) and (min-height: 800px) and (min-width: 1023px) {
    .megamenu {
        &__content-container, &__column {
            max-height: 450px;
        }
    }
}

//
//  Mobile
//  _____________________________________________
@media (min-width: 1024px) and (max-width: 1100px) {
    .navigation-menu {
        &__item {
            &:not(.grand-parent) {
                .navigation-menu__sub-menu-row {
                    column-count: 4;
                    column-gap: 35px;
                }
            }
        }
    }
}

//
//  Debugging
//  _____________________________________________

//.navigation-menu__item:first-child > div {
//    display: block !important;
//}
