.checkout-index-index {
    .col-carrier-shipping-calculator {
        p {
            margin-top: 0;
            margin-bottom: 15px;

            &.label {
                margin-bottom: 0;
            }

            &.under-label {
                color: gray;
            }
        }

    }

}
