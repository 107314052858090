//
//  General
//  _____________________________________________

.price {
    @extend .abs-price;
}

.price-box {
    display: flex;
    line-height: 1;

    .price-label {
        margin-right: 0;
        @extend .abs-price-md;
    }

    .price {
        display: inline-block;
    }
}

.cart.item {
    .price-box {
        .old-price {
            .price {
                padding-right: 12px;
            }
        }
    }
}

//
//  Category page
//  _____________________________________________

.price-wrapper {
    .after_special {
        @extend .abs-price-md;
    }
}

//
//  Detail page
//  _____________________________________________

.product-info-price {
    .price-label {
        display: none;
    }

    .old-price {
        margin-right: 5px;
    }
}

//
//  Small (old-prices)
//  _____________________________________________

.price-box {
    .old-price,
    .normal-price {
        @extend .abs-price-md;
    }

    .old-price {
        .price {
            text-decoration: line-through;
        }
    }
}

.grouped-product-list__info-small {
    .js-price-per-piece {
        display: flex;
        align-items: center;

        .single-item-price__label {
            color: $djm-color-gray;
            line-height: 1;
            font-size: 12px;
            padding-right: 8px;
        }
    }

    .price-box.price-final_price {
        display: flex;
        flex-direction: row;

        .old-price,
        .special-price {
            .price {
                font-weight: 400;
                font-size: 12px;
                color: $color-gray-text-light;
            }
        }

        .old-price {
            padding-right: 5px;
        }

        .special-price {
            order: 2;
            margin: 0;
        }
    }
}

.price-box.price-final_price {
    display: flex;
    flex-direction: column;

    .old-price {
        .price {
            font-size: 10px;
        }
    }

    .special-price {
        order: 2;
        margin: 0;

        .price {
            color: $djm-color-dark-red;
        }
    }
}

//
//  Medium (regular)
//  _____________________________________________

.price-box {
    > .price-final_price,
    .special-price,
    .normal-price,
    .minimal-price {
        .price {
            @extend .abs-price-md;
        }
    }
}

//
//  Extra Large (Detail page)
//  _____________________________________________

.product-info-price {
    .price-box {
        > .price-final_price,
        .special-price,
        .old-price,
        .normal-price,
        .minimal-price {
            .price {
                @extend .abs-price-lg;
            }
        }
    }
}
