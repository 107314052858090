.qty-select {
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 10px;

    &__button {
        @include lib-icon-font(
            $_icon-font-content: false,
            $_icon-font-size: 9px,
            $_icon-font-color: $djm-color-light-gray
        );
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        box-sizing: border-box;
        border-radius: 4px;
        user-select: none;
        background-color: $color-light-gray1;
        cursor: pointer;

        &::before {
            position: relative;
            top: 1px;
        }

        &--increase {
            @include lib-icon-font-symbol($icon-expand);

            &::before {
                color: $djm-color-gray;
            }

            &:hover,
            &:focus {
                background-color: darken($color-light-gray1, 4%);
                text-decoration: none;
            }

            &:active {
                background-color: darken($color-light-gray1, 6%);
            }
        }

        &--decrease {
            @include lib-icon-font-symbol($icon-collapse);

            &::before {
                color: $djm-color-light-gray2;
            }

            &:hover,
            &:focus {
                background-color: darken($color-light-gray1, 4%);
                text-decoration: none;
            }

            &:active {
                background-color: darken($color-light-gray1, 6%);
            }
        }
    }

    input[type="number"] {
        width: 50px;
        height: 30px;
        margin: 0 9px;
        padding: 0;
        border-radius: 5px;
        border: 0;
        box-shadow: 0 0 0 1px inset $djm-color-light-gray5;
        color: $djm-color-gray;
        font-weight: $font-weight__bold;
        font-size: 15px;
        text-align: center;
    }
}

.product-item-actions {
    form {
        display: flex;
    }

    .qty-select {
        margin-right: 10px;
        padding-right: 23px;

        &__button {
            width: 24px;

            &::before,
            &::after {
                vertical-align: 1px;
            }
        }
    }
}
