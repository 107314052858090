$toolbar-mode-icon-font-size: 16px;
$toolbar-background: transparent;
$toolbar-element-background: transparent;
$toolbar-element__color: $color-gray37;
$pager-action__background: $toolbar-element-background;
$pager-action__hover__background: darken($toolbar-element-background, 7%);
$pager__font-weight: $font-weight__bold;
$button__shadow-active: inset 0 1px 0 0 rgba($border-color__base, 0.8), inset 0 -1px 0 0 rgba($border-color__base, 0.3);

.page-products {
    .columns {
        position: relative;
        z-index: 1;
    }
}

.toolbar {
    @extend .abs-add-clearfix;
    margin-bottom: $indent__l;
    text-align: center;
    color: $text__color__light;

    &.toolbar-top {
        margin-bottom: 0;
    }

    select {
        padding: 0 13px 0 32px;
        height: 36px;
        border-radius: $button__border-radius;
        border: $button-ghost__border;
        color: $text__color__light;
        text-transform: uppercase;
        font-weight: $font-weight__bold;
        background: $toolbar-element-background url('../images/sorting.svg') no-repeat 10% 49%;
        width: 139px;

        option {
            text-transform: none;
            color: $color-gray-darken4;
        }

        &:hover {
            border: $button-ghost__hover__border;
        }
    }

    &-amount {
        left: 0;
        line-height: $toolbar-mode-icon-font-size + 2;
        margin: 0;
        padding: 7px 0;
        text-align: left;
        top: 0;
        vertical-align: middle;

        .products.wrapper ~ .toolbar & {
            display: none;
        }
    }

    .page-with-filter & {
        &-amount {
            display: flex;
            align-items: center;
            position: static;
        }
    }

    &-products {
        @include lib-css(background-color, $toolbar-background);

        .pages {
            display: none;

            .products.wrapper ~ & {
                display: block;
            }
        }
    }

    .pages {
        margin-bottom: $indent__m;
    }
}

.sorter {
    float: left;

    .page-products & {
        right: $indent__s;
        top: 0;
        z-index: 1;
    }

    .products.wrapper ~ .toolbar & {
        display: none;
    }

    &-options {
        margin: 0 $indent__xs 0 0;
        width: auto;
    }

    &-action {
        @include lib-icon-font(
            $icon-arrow-up,
            $_icon-font-size: 16px,
            $_icon-font-color: $text__color__light,
            $_icon-font-color-hover: $text__color__lighter
        );
        @include lib-icon-text-hide();

        &.sort-desc:before {
            content: $icon-arrow-down;
        }

        &::before {
            transform: translateY(-2px);
        }
    }
}

.modes {
    display: none;
}

.limiter {
    &-options {
        margin: 0 5px 0 7px;
        width: auto;
    }

    &-label {
        font-weight: 400;
    }

    .page-products .toolbar & {
        display: none;
    }

    .control {
        display: inline-block;
    }
}

//
//  Mobile (small)
//  _____________________________________________

@include max-screen($screen__xs) {
    .sorter-label {
        display: none;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .toolbar-products {
        margin-bottom: 0;
    }

    .pages {
        .pages-item-previous,
        .pages-item-next {
            display: block;
            margin: 15px 0;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .page-products {
        .columns {
            padding-top: 0;
            position: relative;
            z-index: 1;
        }
    }

    .toolbar-amount {
        display: block;
        float: left;
        position: static;
    }

    .toolbar {
        .products.wrapper ~ & .pages {
            margin-bottom: 0;
        }
    }

    .modes {
        display: inline-block;
        float: right;

        .products.wrapper ~ .toolbar & {
            display: none;
        }
    }

    .modes-mode {
        display: inline-block;
        color: $text__color__light;
        border: $button-ghost__border;
        font-weight: $font-weight__semibold;
        line-height: 1;
        margin-left: 7px;
        padding: 7px 10px;
        text-align: center;
        border-radius: $button__border-radius;
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 0.06em;

        .modes-label + & {
            border-radius: $button__border-radius;
        }

        &:hover {
            color: $text__color__light;
            background: $toolbar-element-background;
            border: $button-ghost__hover__border;
            text-decoration: none;
        }

        &.active {
            background: $color-white;
            border-color: $color-white;
            color: $color-dark-blue-text;
        }
    }

    .modes-label {
        margin-right: $indent__xs;
    }

    .sorter {
        .page-products & {
            position: static;
        }
    }

    .limiter {
        float: right;
    }
}
