$widgets-indent__bottom: $indent__base;

.block-static-block,
.block-cms-link {
    &.widget {
        @include lib-css(margin-bottom, $widgets-indent__bottom);
        .links & {
            margin-bottom: 0;
        }
    }
}

.block-cms-link-inline {
    &.widget {
        margin: 0;
    }
}

.block-category-link-button {
    &.widget {
        a {
            @extend .action.primary;
        }
    }
}

.cms-body {
    font-size: 16px;
    
    .products-grid {
        li {
            &::before {
                display: none;
            }
        }
    }
    
    p {
        margin: 15px 0;
        font-size: 17px;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        font-weight: $font-weight__bold;
    }

    h3 {
        margin-top: 20px;
    }

    ul {
        @extend .abs-reset-list;
    }

    li {
        position: relative;
        padding-left: 20px;

        &::before {
            content: "\2022";
            position: absolute;
            left: 0;
            color: #c7c7c6;
        }
    }
}

.modal-inner-wrap {
    top: 300px;
    
    label {
        font-weight: bold
    }
    
    input {
        margin-top: 5px;
    }
    
    button {
        padding: 12px 24px;
        text-transform: uppercase;
        font-weight: 800;
        font-size: 13px;
        background: #2fb8e6;
        border-color: #2fb8e6;
        color: $color-white;
        letter-spacing: 0.1em;
        text-decoration: none;
    }
}


@include min-screen($screen__m) {
    .cms-body {
        padding: 10px 10px 5px;
    }
}
