.barcode-scanner {
    margin-top: 20px;
    margin-bottom: 60px;
    text-align: center;

    p {
        margin-top: 30px;
        color: $color-white;
    }

    button {
        @include lib-button-reset();
        border: 2px solid rgba(255, 255, 255, 0.3);
        font-size: 14px;
        color: $color-white;
        padding: 12px 28px;

        &:hover,
        &:focus,
        &:active {
            border: 2px solid rgba(255, 255, 255, 0.3);
        }
    }
}


#html5-qrcode-button-camera-permission, #html5-qrcode-button-camera-stop, #html5-qrcode-button-camera-start {
    @include lib-button-primary();
}

#html5-qrcode-select-camera {
    @include lib-form-element-input($_type: select);
    margin: 12px 0;

    background: #fff url('../images/select-bg-dark.svg') no-repeat 99% 45%;
    background-size: 30px 60px;
    border: 1px solid #ccc;
    max-width: 80%;
}

#headerPreview, #preview {
    background-color: #fff;

    img[alt="Info icon"] {
        display: none;
    }
}

