$product-grid-items-per-row-layout-default: 2;

$product-grid-items-per-row-layout-1-screen-s: 3;
$product-grid-items-per-row-layout-1-screen-m: 4;
$product-grid-items-per-row-layout-1-screen-l: 5;

$product-grid-items-per-row-layout-2-left-screen-s: 3;
$product-grid-items-per-row-layout-2-left-screen-m: 4;
$product-grid-items-per-row-layout-2-left-screen-l: '';

$product-grid-items-per-row-layout-2-right-screen-s: 3;
$product-grid-items-per-row-layout-2-right-screen-m: 4;
$product-grid-items-per-row-layout-2-right-screen-l: '';

$product-grid-items-per-row-layout-3-screen-s: 3;
$product-grid-items-per-row-layout-3-screen-m: '';
$product-grid-items-per-row-layout-3-screen-l: '';

$product-grid-items-padding: 0 $indent__base $indent__base;
$product-grid-items-margin: 0 0 $indent__s;

$product-name-text-decoration: none;
$product-name-text-decoration-hover: $link__hover__text-decoration;

$toolbar-mode-icon-font-size: 14px;
$product-h1-margin-bottom-desktop: $indent__base;

@import 'module/prices';
@import 'module/stock';
@import 'module/listings';
@import 'module/toolbar';
@import 'module/gallery';

//
//  Category view
//  ---------------------------------------------

.checkout-qty-warning {
    background: $djm-color-warning-red;
    height: 35px;
    line-height: 35px;
    width: fit-content;

    .out-of-stock {
        &:before {
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-size: 15px;
            line-height: 15px;
            content: $message-error-icon;
            font-family: "tickles-icons";
            margin: -2px -5px 0 0;
            vertical-align: middle;
            display: inline-block;
            font-weight: normal;
            speak: none;
            text-align: center;
            left: 0;
            top: 0;
            width: 40px;
            position: relative;
        }
        padding: 7px;
    }
}

.old-price,
.old.price {
    text-decoration: line-through;
}

.prices-tier {
    .price-container {
        .price-including-tax {
            + .price-excluding-tax {
                &:before {
                    content: '(' attr(data-label) ': ';
                }

                &:last-child:after {
                    content: ')';
                }
            }
        }

        .weee[data-label] {
            display: inline;

            .price {
                @include lib-font-size(11);
            }

            &:before {
                content: ' +' attr(data-label) ': ';
            }
        }
    }
}

.actual-price {
    font-weight: $font-weight__bold;
}

.product.name a {
    @extend .abs-product-link;
}

.category-image {
    .image {
        display: block;
        height: auto;
        max-width: 100%;
    }
}

.category-image,
.category-description {
    margin-bottom: $indent__base;
}

.category-view {
    color: $text__color__light;

    .page-title {
        margin-bottom: $indent__s;
        color: $text__color__lighter;
    }
}

.category-description {
    margin-bottom: 30px;

    &__title {
        @extend h1;
        margin-bottom: 10px;
        color: $color-white;
    }

    &__content {
        font-size: 22px;
        color: $djm-color-light-blue;
    }
}

.distribution-message {
    padding: 12px 15px;
    border-radius: 5px;
    background-color: $djm-color-green;
    font-size: 15px;
    font-weight: $font-weight__bold;
    color: $color-white;
}

//
//  Product images general container
//  ---------------------------------------------

.product-image-container {
    display: inline-block;
    max-width: 100%;
}

.product-image-wrapper {
    display: block;
    height: 0;
    overflow: hidden;
    position: relative;
    z-index: 1;
}

.product-image-photo {
    bottom: 0;
    display: block;
    height: auto;
    left: 0;
    margin: auto;
    width: 100%;
    position: absolute;
    right: 0;
    top: 0;
}

.catalog-category-view {
    .product-image-wrapper {
        border-radius: 6px 6px 0 0;
    }
}

//
//  Product view
//  ---------------------------------------------

.product.media {
    .product.photo .photo.image {
        @extend .abs-adaptive-images-centered;
    }

    .placeholder .photo.container {
        max-width: 100%;
    }

    .notice {
        @include lib-css(color, $text__color__muted);
        @include lib-font-size($font-size__s);
        margin: $indent__s 0;
    }

    .product.thumbs {
        margin: $indent__base 0 $indent__l;
    }

    .items.thumbs {
        @include lib-list-inline();

        .active {
            display: block;
            line-height: 1;
        }
    }
}

.product.info.detailed {
    clear: both;

    .product {
        display: none;
    }

    .additional-attributes {
        width: auto;
        @include lib-table-resize(
            $_th-padding-left: 0,
            $_th-padding-right: $indent__l,
            $_th-padding-bottom: $indent__s,
            $_td-padding-bottom: $indent__s
        );
    }
}

.product-info-main {
    .page-title-wrapper {
        .page-title {
            line-height: $line-height__base;
            margin-bottom: $indent__s;
        }
    }

    .product {
        &.attribute {
            &.sku {
                display: inline-block;
                vertical-align: top;
                @include lib-css(color, $text__color__muted);

                > .value {
                    display: inline-block;
                    vertical-align: top;
                    word-break: break-all;
                }

                .type {
                    margin-right: $indent__xs;
                }
            }

            &.overview {
                margin: $indent__base 0;
                color: white;
            }
        }

        &.alert {
            margin: $indent__s 0;
        }
    }

    .product-reviews-summary .reviews-actions {
        @include lib-font-size($font-size__base);
    }
}

.product-options-wrapper {
    .fieldset-product-options-inner {
        .legend {
            @include lib-css(font-weight, $font-weight__bold);
            @include lib-css(margin, 0 0 $indent__xs);
            @include lib-font-size(14px);
            border: none;
            display: inline-block;
            float: none;
            padding: 0;
        }

        //  Date & Time custom option (Affect Time that goes only after Date)
        input.datetime-picker {
            ~ select.datetime-picker {
                margin-top: $indent__s;
            }
        }

        &.required,
        &._required {
            .legend {
                &:after {
                    content: '*';
                    @include lib-typography(
                        $_font-size: $form-field-label-asterisk__font-size,
                        $_color: $form-field-label-asterisk__color,
                        $_font-family: $form-field-label-asterisk__font-family,
                        $_font-weight: $form-field-label-asterisk__font-weight,
                        $_line-height: $form-field-label-asterisk__line-height,
                        $_font-style: $form-field-label-asterisk__font-style
                    );
                    @include lib-css(margin, $form-field-label-asterisk__margin);
                }
            }
        }
    }

    .field {
        .note {
            display: block;
        }

        .price-notice {
            @extend .abs-adjustment-incl-excl-tax;
        }
    }
}

.product-info-main,
.product-options-bottom {
    .special-price {
        display: block;
        margin: $indent__s 0;

        .price-container {
            @include lib-font-size(14);
        }

        .price-label + .price-wrapper {
            display: inline-block;
        }
    }

    .old-price,
    .special-price {
        .price-label {
            &:after {
                content: ': ';
            }
        }
    }

    .box-tocart {
        margin: $indent__base 0;

        .field.qty {
            padding-right: 0.75 * $indent__base;
        }

        .input-text.qty {
            $tocart-input-size: $button__line-height__l + 30px;
            height: $tocart-input-size;
            text-align: center;
            width: $tocart-input-size;
        }

        .actions {
            text-align: center;
        }

        .action.tocart {
            @extend .abs-button-l;
            @extend .abs-actions-addto-icon;
        }

        .atc-has-selection,
        .atc-no-selection {
            color: #acffa5;
            font-size: 14px;
            font-weight: 600;

            strong {
                font-weight: 600;
            }
        }

        .atc-btn-text {
            font-size: 16px;
            font-weight: 800;
            text-transform: uppercase;
            letter-spacing: 0.04em;
        }

        .atc-qty-text {
            display: none;
        }
    }

    .product-addto-links {
        margin: $indent__base 0;
    }

    .action.tocompare {
        @extend .abs-action-addto-product;
        vertical-align: top;
    }
}

.product-item {
    &__bottom-section {
        display: flex;
        margin-top: 30px;
        margin-right: -10px;
        margin-left: -10px;
        width: 100%;
    }

    &__title {
        @include lib-heading(h4);
        display: block;
        margin-bottom: 15px;
        font-size: 12px;
        color: $color-white;
        text-transform: uppercase;
        letter-spacing: 0.09em;
    }

    &__specifications {
        width: 100%;
        padding: 0 10px;
        color: $color-white;

        .inner {
            margin-right: 15px;
        }

        .short-description {
            border-bottom: 1px solid #257cc3;
            margin-bottom: 5px;
            padding-bottom: 20px;
        }

        p {
            margin-bottom: 0;
        }
    }

    &__specifications-inner {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        width: 100%;

        &--half {
            width: 50%;
        }

        ul {
            padding-left: 0;

            li {
                margin-bottom: 0;
                list-style: none;
            }
        }
    }

    &__values {
        font-size: 13px;
        width: 100%;
    }

    &__value {
        display: flex;
        justify-content: space-between;
        text-align: right;
        margin-right: 15px;
        margin-bottom: 5px;
        padding-bottom: 5px;
        border-bottom: 1px solid #257cc3;

        strong {
            padding-right: 10px;
        }
    }

    &__icon {
        @include lib-icon-font(
            $_icon-font-content: false,
            $_icon-font-size: 50px,
            $_icon-font-margin: 0 15px 0 0,
            $_icon-font-line-height: 0,
            $_icon-font-color: $color-white
        );

        &::before {
            position: absolute;
            top: 40px;
            left: 15px;
        }

        &.icon-transportation {
            @include lib-icon-font-size(
                $_icon-font-size: 56px,
                $_icon-font-line-height: 0
            );
        }
    }

    &__info {
        width: 50%;
        padding: 0 10px;

        &-title {
            margin-top: 0;
            margin-bottom: 8px;
        }

        p {
            margin: 0;
        }

        &-block {
            box-sizing: border-box;
            position: relative;
            display: flex;
            padding: 15px 15px 15px 85px;
            margin-bottom: 5px;
            background-color: #004681;
            color: $color-white;
            border-radius: 5px;
        }
    }
}

.product-usps {
    width: 50%;

    &__cart {
        display: flex;
        align-items: center;
        position: relative;

        h3 {
            font-size: 14px;
            color: $color-white;
            font-weight: 500;
            margin-bottom: 10px;
            padding: 0;
        }

        p {
            font-size: 13px;
            padding: 0;
            margin: 0;
            color: #c5e4fb;
        }
    }
    &__wrapper {
        padding: 0 20px 20px 20px;
        background: #004681;
        border-radius: 5px;
        margin-bottom: 5px;
    }
}

.prices-tier {
    @extend .abs-reset-list;
    @include lib-css(background, $sidebar__background-color);
    margin: $indent__s 0;
    padding: $indent__s (0.75 * $indent__base);

    .price-container {
        display: inline-block;
    }

    .price-including-tax,
    .price-excluding-tax,
    .weee {
        display: inline-block;

        .price {
            @extend .abs-price-md;
        }
    }
}

.ui-dialog-titlebar-close {
    @include lib-button-as-link();
}

.block.related {
    .action.select {
        margin: 0 $indent__xs;
    }
}

//
//  Sticky product header
//  ---------------------------------------------

.sticky-header {
    z-index: 500;
    position: fixed;
    opacity: 0;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    background-color: $color-white;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.25);
    transform: translateY(-100%);
    transition: all 0.2s ease-in-out;

    &--active {
        opacity: 1;
        transition: all 0.2s ease-in-out;
        transform: translateY(0%);
    }

    &__inner {
        max-width: $layout__max-width;
        margin: 0 auto;
        padding: $indent__xs $indent__base;
        display: flex;
        justify-content: space-between;

        .product-image-container {
            max-width: 30%;
        }
    }

    &__left {
        display: flex;
    }

    &__right {
        display: flex;
        align-items: center;
    }

    &__product-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__product-title {
        font-weight: $font-weight__semibold;
    }

    &__btn {
        &--configure {
            @include lib-icon-font(
                    $icon-settings,
                $_icon-font-size: 13px,
                $_icon-font-line-height: 1.1,
                $_icon-font-margin: 0 5px 0 0,
                $_icon-font-position: before
            );
        }

        &--addtocart {
            @include lib-icon-font(
                    $icon-cart,
                $_icon-font-size: 13px,
                $_icon-font-line-height: 1.1,
                $_icon-font-margin: 0 5px 0 0,
                $_icon-font-position: before
            );
        }
    }
}

.catalog-product-view {
    .page-title {
        color: $text__color__lighter;
    }
}

.trigger-filters {
    @include lib-button();
    @include lib-link-as-button();
    @include lib-css(border-radius, $button__border-radius);
    float: left;
    margin-right: 15px;
    margin-bottom: 10px;
    padding: 10px 20px;

    .products.wrapper ~ .toolbar & {
        display: none;
    }
}

@include min-screen($screen__m) {
    .sticky-header {
        &__inner {
            max-width: calc(#{$layout__max-width} - #{$indent__xl});
        }
    }

    .category-description {
        font-size: 22px;
    }

    .trigger-filters {
        display: none;
    }
}

@include max-screen($screen__l) {
    .filter-container.filter-options {
        display: none;
    }

    .page-products {
        .columns {
            display: flex;
            flex-direction: column;

            .column.main {
                padding-bottom: 40px;
                flex-basis: 100%;
                flex-grow: 1;
                order: 1;
                width: 100%;
            }

            .sidebar-main {
                flex-grow: 1;
                flex-basis: 100%;
                width: 100%;
            }
        }
    }

    .product-info-main,
    .product-options-bottom {
        .box-tocart {
            .action.tocart {
                width: 100%;
            }
        }
    }

    .product-item {
        &__bottom-section {
            flex-direction: column;

            > div {
                width: 100%;
            }
        }
    }
}

@include max-screen($screen__m) {
    .category-description {
        font-size: 17px;
    }

    .page-with-filter .category-view {
        margin-bottom: $indent__m;
    }

    .sticky-header {
        bottom: 0;
        right: 0;
        left: 0;
        top: auto;
        box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.25);
        transform: translateY(100%);

        &--active {
            transform: translateY(0);
        }

        &__inner {
            padding: $indent__xs 15px;
        }

        &__btn {
            &--configure {
                @include lib-icon-font(
                        $icon-cart,
                    $_icon-font-size: 13px,
                    $_icon-font-line-height: 1.1,
                    $_icon-font-margin: 0,
                    $_icon-font-position: before
                );

                span {
                    display: none;
                }
            }

            &--addtocart {
                @include lib-icon-font(
                        $icon-cart,
                    $_icon-font-size: 13px,
                    $_icon-font-line-height: 1.1,
                    $_icon-font-margin: 0,
                    $_icon-font-position: before
                );

                span {
                    display: none;
                }
            }
        }

        &__left {
            .product-image-container {
                margin-right: 5px;
            }
        }
    }
}

//
//  Sidebar product view
//  ---------------------------------------------

.sidebar {
    .product-items {
        .product-item {
            margin-bottom: $indent__base;
            position: relative;
        }

        .product-item-info {
            position: relative;
            width: auto;

            .product-item-photo {
                left: 0;
                position: absolute;
                top: 0;
            }
        }

        .product-item-name {
            margin-top: 0;
        }

        .product-item-details {
            margin: 0 0 0 85px;
        }

        .product-item-actions {
            display: block;
            margin-top: $indent__s;
        }

        .price-box {
            display: block;
            margin: 7px 0;
        }

        .text {
            margin-right: 8px;
        }

        .counter {
            @include lib-css(color, $primary__color__lighter);
            @include lib-font-size(12);
            white-space: nowrap;
        }

        .minilist {
            .price {
                display: inline;
                padding: 0;
            }

            .weee:before {
                display: inline-block;
            }
        }
    }

    .action {
        &.delete {
            @extend .abs-remove-button-for-blocks;
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .subtitle {
        @extend .abs-no-display;
    }

    //
    //  Product images only
    //  ---------------------------------------------

    .product-items-images {
        @extend .abs-add-clearfix;
        margin-left: -$indent__xs;

        .product-item {
            @extend .abs-add-box-sizing;
            float: left;
            padding-left: $indent__xs;
        }
    }

    //
    //  Product names only
    //  ---------------------------------------------

    .product-items-names {
        .product-item {
            margin-bottom: $indent__s;
        }

        .product-item-name {
            margin: 0;
        }
    }
}

.product-view-login {
    margin-bottom: 25px;

    &__title {
        margin-top: 0;
        margin-bottom: 15px;
        font-weight: 500;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .product-usps {
        &__left {
            position: unset;
        }

        &__right {
            margin-left: 10px;
        }

        &__cart {
            align-items: end;
        }
    }

    .product-item__values {
        width: 95%;
    }

    .catalog-product-view {
        .column.main {
            @include lib-vendor-prefix-display(flex);
            @include lib-vendor-prefix-flex-direction(column);
        }

        .product.media {
            @include lib-vendor-prefix-order(-1);
            margin-bottom: 15px;
        }
    }

    .product-info-main .box-tocart {
        .actions {
            .action.tocart {
                @extend .abs-button-responsive-smaller;
            }
        }
    }

    .block.related {
        .action.select {
            display: block;
            margin: $indent__xs 0;
        }
    }

    .compare,
    .product-addto-links .action.tocompare,
    .product-item-actions .actions-secondary > .action.tocompare,
    [class*='block-compare'] {
        display: none;
    }
}

.subcategories-list {
    display: flex;
    flex-wrap: wrap;
    margin: 30px -10px 0;

    &__item {
        box-sizing: border-box;
        width: 50%;
        margin-bottom: 20px;
        padding-right: 10px;
        padding-left: 10px;
    }

    &__inner {
        &:hover {
            text-decoration: none;
        }
    }

    &__image {
        height: 0;
        padding-bottom: 90%;
        background: red;
        background-size: cover;
        border-radius: $block-border-radius $block-border-radius 0 0;
    }

    &__title {
        @include lib-block($border-radius: 0 0 $block-border-radius $block-border-radius);
        color: $color-gray-text;
        font-weight: $font-weight__semibold;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .product-info-main {
        .page-title-wrapper {
            .page-title {
                margin-top: -13px;
            }
        }
    }

    .sidebar {
        .product-items {
            .product-item-info {
                .product-item-photo {
                    float: left;
                    left: auto;
                    margin: 0 $indent__s $indent__s 0;
                    position: relative;
                    top: auto;
                }
            }

            .product-item-details {
                margin: 0;
            }

            .product-item-actions {
                clear: left;
            }
        }
    }

    .product-add-form {
        @extend .abs-revert-field-type-desktop;
    }

    .subcategories-list {
        margin-top: 60px;

        &__item {
            width: (100% / 4);
        }
    }
}

//
//  Desktop medium
//  _____________________________________________

@include min-screen($screen__l) {
    .product-info-main,
    .product-options-bottom {
        .box-tocart {
            width: 100%;
            display: flex;
            justify-content: center;

            .field.qty {
                display: block;
            }

            .actions {
                display: block;
                text-align: center;
                vertical-align: bottom;
            }

            .action.tocart {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                padding-left: 70px;
                padding-right: 70px;
            }

            .atc-btn-text,
            .atc-qty-text {
                margin: 0 10px;
            }
        }
    }

    .product-info-bottom {
        display: flex;
    }

    .product-info-bottom-inner {
        width: 50%;
    }

    .product-info-bottom-left {
        padding-right: 60px;
    }

    .product-view-login {
        margin-bottom: 40px;

        &__title {
            @include lib-font-size(22);
            @include lib-icon-font(
                    $icon-account
            );

            &::before {
                width: 45px;
                height: 45px;
                margin-right: 15px;
                background-color: #d8edff;
                border-radius: 50%;
                line-height: 42px;
                text-align: center;
                color: $color-dark-blue-text;
            }
        }
    }

    .subcategories-list {
        &__item {
            width: (100% / 6);
        }
    }
}

//
//  Desktop large
//  _____________________________________________

@include min-screen($screen__xl) {
    .sidebar {
        .product-items {
            .product-item-info {
                .product-item-photo {
                    float: none;
                    left: 0;
                    margin: 0;
                    position: absolute;
                    top: 0;
                }
            }

            .product-item-details {
                margin-left: 85px;
            }
        }
    }
}

//
//  Category page layout
//  ---------------------------------------------

@include min-screen($screen__m) {
    .product-info-main {
        float: right;
    }

    .product.media {
        float: left;
        margin-bottom: $indent__m;
        box-sizing: border-box;
        padding-right: 15px;
    }

    .page-layout-1column {
        .product-info-main {
            width: 57%;
        }

        .product.media {
            width: 42%;
        }
    }

    .page-layout-2columns-left,
    .page-layout-2columns-right,
    .page-layout-3columns {
        .product-info-main {
            width: 48%;
        }

        .product.media {
            width: 50%;
        }
    }

    .category-view {
        margin-bottom: 40px;
    }
}

//
//  Compare Products Page
//  ---------------------------------------------

body.catalog-product-compare-index {
    .action.print {
        float: right;
        margin: 15px 0;
    }
}

.table-wrapper.comparison {
    clear: both;
    max-width: 100%;
    overflow-x: auto;
}

.table-comparison {
    table-layout: fixed;

    .cell.label.remove,
    .cell.label.product {
        span {
            @extend .abs-visually-hidden;
        }
    }

    .cell.label,
    td:last-child {
        border-right: $table__border-width $table__border-style $table__border-color;
    }

    .cell {
        padding: 15px;
        width: 140px;

        .attribute.value {
            overflow: hidden;
            width: 100%;
        }

        &.product.info,
        &.product.label {
            border-bottom: $table__border-width $table__border-style $table__border-color;
        }

        &.label {
            .attribute.label {
                display: block;
                width: 100%;
                word-wrap: break-word;
            }
        }

        &.attribute {
            @include lib-font-size(13);

            img {
                height: auto;
                max-width: 100%;
            }
        }
    }

    .product-item-photo {
        display: block;
        margin: 0 auto 15px;
    }

    .product-image-photo {
        margin-left: 0;
    }

    .product-item-actions,
    .price-box,
    .product.rating,
    .product-item-name {
        display: block;
        margin: 15px 0;
    }

    .product-addto-links {
        margin-top: 15px;

        .action.split,
        .action.toggle {
            @include lib-button-s();
        }

        .action.toggle {
            padding: 0;
        }
    }

    .cell.remove {
        padding-bottom: 0;
        padding-top: 0;
        text-align: right;

        .action.delete {
            @extend .abs-remove-button-for-blocks;
        }
    }

    .product-item-actions {
        > .actions-primary {
            + .actions-secondary {
                margin-top: $indent__s;
            }
        }
    }

    .action {
        &.tocart {
            white-space: nowrap;
        }
    }
}

.comparison.headings {
    @include lib-css(background, $page__background-color);
    left: 0;
    position: absolute;
    top: 0;
    width: auto;
    z-index: 2;
}

.block-compare {
    .block-title {
        @extend .abs-block-title;
    }

    .product-item .product-item-name {
        margin-left: 22px;
    }

    .action {
        &.delete {
            @extend .abs-remove-button-for-blocks;
            left: 0;
            position: absolute;
            top: 0;
        }

        &.compare {
            @extend .abs-revert-secondary-color;
        }
    }

    .counter {
        @extend .abs-block-items-counter;
    }

    .actions-toolbar {
        margin: 17px 0 0;
    }
}

.icons-meaning {
    display: none;

    &__title {
        font-size: 13px;
        color: $text__color__lighter;
        font-weight: 800;
        text-transform: uppercase;
        letter-spacing: 0.02em;
    }

    &__item {
        box-sizing: border-box;
        flex: 1;
        min-width: 50%;
        margin-bottom: 10px;

        &-inner {
            position: relative;
            padding: 15px 15px 15px 82px;
            background-color: $color-dark-blue-bg;
            border-radius: 6px;

            &::before {
                position: absolute;
                top: 10px;
                left: 15px;
            }
        }

        &-title {
            margin-top: 0;
            margin-bottom: $indent__s;
            color: $text__color__lighter;
            font-weight: $font-weight__bold;
        }

        &-paragraph {
            margin-bottom: 0;
            color: $text__color__light;
        }
    }

    &__icon {
        &--quality {
            @include lib-icon-font(
                    $icon-quality,
                $_icon-font-color: $color-white,
                $_icon-font-size: 50px
            );
        }

        &--documents {
            @include lib-icon-font(
                    $icon-documents,
                $_icon-font-color: $color-white,
                $_icon-font-size: 50px
            );
        }
    }
}

@include min-screen($screen__s) {
    .icons-meaning {
        &__row {
            display: flex;
            flex-wrap: wrap;
            margin-left: -5px;
            margin-right: -5px;
        }

        &__item {
            padding-right: 5px;
            padding-left: 5px;
        }
    }
}

.cart-empty {
    @include lib-block();
}

.catalog-category-non-anchor {
    .page-title-wrapper,
    .category-description {
        text-align: center;
    }
}

.messages  {
    .sensitivity-warning {
        display: flex;
        margin-bottom: 20px;
        justify-content: center;
        align-items: center;

        .warning-text {
            padding-left: 20px;

            span {
                font-size: 16px;
                line-height: 24px;
                font-weight: $font-weight__bold;
                color: $djm-color-dark-blue4;
            }
        }

    }
}
