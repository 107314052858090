//
//  Color variables
//  _____________________________________________

$color-white             : #fff !default;
$color-black             : #000 !default;

$color-gray19            : #303030 !default;
$color-gray20            : #333 !default;
$color-gray34            : #575757 !default;
$color-gray35            : #43484c !default;
$color-gray37            : #5e5e5e !default;
$color-gray40            : #666 !default;
$color-gray43            : #6d6d6d !default;
$color-gray46            : #757575 !default;
$color-gray52            : #858585 !default;
$color-gray55            : #8c8c8c !default;
$color-gray56            : #8f8f8f !default;
$color-gray60            : #999 !default;
$color-gray62            : #9e9e9e !default;
$color-gray64            : #a3a3a3 !default;
$color-gray68            : #adadad !default;
$color-gray76            : #c2c2c2 !default;
$color-gray78            : #c7c7c7 !default;
$color-gray79            : #c9c9c9 !default;
$color-gray80            : #ccc !default;
$color-gray82            : #d1d1d1 !default;
$color-gray89            : #e3e3e3 !default;
$color-gray90            : #e5e5e5 !default;
$color-gray91            : #e8e8e8 !default;
$color-gray92            : #ebebeb !default;
$color-gray94            : #f0f0f0 !default;
$color-gray95            : #f2f2f2 !default;
$color-white-smoke       : #f5f5f5 !default;
$color-white-dark-smoke  : #efefef !default;
$color-white-fog         : #f8f8f8 !default;

$color-gray-light0       : #f6f6f6 !default;
$color-gray-light01      : #f4f4f4 !default;
$color-gray-light1       : #e5efe5 !default;
$color-gray-light2       : #bbb !default;
$color-gray-light3       : #aeaeae !default;
$color-gray-light4       : #cecece !default;
$color-gray-light5       : #c1c1c1 !default;
$color-gray-light6       : #c5c5c5 !default;
$color-gray-light7       : #b3bdc6 !default;
$color-gray-light8       : #a6a6a6 !default;

$color-gray-middle1      : #e4e4e4 !default;
$color-gray-middle2      : #c6c6c6 !default;
$color-gray-middle3      : #7e807e !default;
$color-gray-middle4      : #6e716e !default;
$color-gray-middle5      : #707070 !default;

$color-gray-darken0      : #eee !default;
$color-gray-darken1      : #e2e2e2 !default;
$color-gray-darken2      : #cdcdcd !default;
$color-gray-darken3      : #555 !default;
$color-gray-darken4      : #494949 !default;

$color-red9              : #ff0101 !default;
$color-red10             : #e02b27 !default;
$color-red11             : #b30000 !default;
$color-red12             : #d10029 !default;

$color-orange-red1       : #ff5501 !default;
$color-orange-red2       : #ff5601 !default; // Legacy orange
$color-orange-red3       : #ff5700 !default; // Legacy orange
$color-orange-red4       : #fc5e10 !default; // Legacy orange

$color-dark-green1       : #006400 !default;

$color-blue1             : #1979c3 !default;
$color-blue2             : #006bb4 !default;
$color-blue3             : #00699D !default;
$color-sky-blue1         : #68a8e0 !default;
$color-sky-blue2         : #809ac8 !default;

$color-pink1             : #fae5e5 !default;
$color-dark-pink1        : #800080 !default;

$color-brownie1          : #6f4400 !default;
$color-brownie-light1    : #c07600 !default;

$color-yellow-light1     : #fdf0d5 !default;
$color-yellow-light2     : #ffee9c !default;
$color-yellow-light3     : #d6ca8e !default;
$color-yellow1           : #ff0 !default;

//
//  Theme colors
//  ---------------------------------------------

$color-light-blue1        : #b1dbff !default;
$color-light-blue2        : #2fb8e6 !default;
$color-light-blue3        : #00a8ff !default;
$color-light-blue4        : #a3beec !default;

$color-medium-blue1       : #0c8ff3 !default;
$color-medium-blue2       : #16a3ff !default;
$color-medium-blue3       : #267ec7 !default;
$color-medium-blue4       : #193c77 !default;

$color-dark-blue1         : #0061b3 !default;
$color-dark-blue2         : #005fae !default;
$color-dark-blue4         : #8fa9d7 !default;

$color-green-addtocart    : #10b902 !default;
$color-light-gray         : #9fb0be !default;
$color-light-gray1        : #e1e8ee !default;
$color-light-gray1-darken : darken($color-light-gray1, 3%) !default;
$color-light-gray3        : #666;

$color-yellow             : #f9c400 !default;
$color-light-red          : #ff4d4f !default;

//
// DJM Theme colors
//  ---------------------------------------------

$djm-color-gray           : #43484d !default;
$djm-color-gray2          : #333333 !default;
$djm-color-gray3          : #666666 !default;
$djm-color-light-gray     : #959ca7 !default;
$djm-color-light-gray2    : #b3bdc6 !default;
$djm-color-light-gray3    : #e1e8ee !default;
$djm-color-light-gray4    : #acb3bd !default;
$djm-color-light-gray5    : #dedede !default;
$djm-color-light-gray6    : #cccccc !default;
$djm-color-light-gray7    : #e6e6e6 !default;
$djm-color-dark-gray      : #3e3f42 !default;
$djm-color-dark-gray2     : #141616 !default;
$djm-color-light-green    : #73bb4f !default;
$djm-color-green          : #0ebb2d !default;
$djm-color-dark-green     : #01982b !default;
$djm-color-light-blue     : #b1dbff !default;
$djm-color-light-blue2    : #f0f6ff !default;
$djm-color-blue           : #16a3ff !default;
$djm-color-dark-blue3     : #0a2d67 !default;
$djm-color-orange         : #faad14 !default;
$djm-color-dark-red       : #e02b27 !default;
$djm-color-warning-red    : #fbe9e9 !default;
$djm-color-dark-blue4     : #27527D !default;
$djm-color-dark-blue5     : #001b48 !default;

//
//  Reusable colors
//  ---------------------------------------------

// text
$color-light-blue-text    : $color-light-blue2 !default;
$color-dark-blue-text     : $color-dark-blue2 !default;
$color-gray-text          : $djm-color-gray !default;
$color-gray-text-light    : $djm-color-light-gray !default;

// icons
$color-gray-icon          : #b0b7c0 !default;

// borders
$color-blue-border        : $color-medium-blue3 !default;
$color-gray-border        : #dddddd !default;

// backgrounds
$color-light-blue-bg      : $color-light-blue3 !default;
$color-dark-blue-bg       : $djm-color-dark-blue3 !default;
$color-nav-bg             : $djm-color-dark-blue5 !default;

$color-success-bg         : $djm-color-dark-green !default;

//
//  Color nesting
//  ---------------------------------------------

$primary__color          : $color-gray-text !default;
$primary__color__dark    : darken($primary__color, 35%) !default; // #000
$primary__color__darker  : darken($primary__color, 13.5%) !default; // #111
$primary__color__lighter : lighten($primary__color, 29%) !default; // #7d7d7d
$primary__color__light   : lighten($primary__color, 45%) !default; // #a6a6a6

$secondary__color        : $color-gray91 !default;
$secondary__color__light : lighten($secondary__color, 5%) !default;

$page__background-color  : $color-dark-blue1 !default;
$panel__background-color : darken($page__background-color, 6%) !default;

$active__color           : $color-orange-red1 !default;
$error__color            : $color-red10 !default;

$text__color__lighter    : $color-white !default;
$text__color__light      : $djm-color-light-blue !default;
$text__color__faded      : $color-light-gray !default;
$text__color             : $color-gray-text !default;

$price__color            : $color-dark-blue-text !default;
