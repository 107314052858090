//  Header
$header__background-color: $color-white;
$header-icons-color: $color-gray-icon;
$header-icons-color-hover: darken($color-gray-icon, 3%);
$customer-welcome__z-index: $dropdown-list__z-index + 1;

//
//  Header
//  ---------------------------------------------

.page-header {
    @include lib-css(background-color, $header__background-color);
    position: relative;
    border-bottom: 1px solid $border-color__base;
    margin-bottom: $indent__base;

    .panel.wrapper {
        .panel.header {
            &::before,
            &::after {
                display: none;
            }
        }
    }

    .panel.header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        ul {
            @include lib-dropdown(
                $_toggle-selector: '.dropdown > a',
                $_options-selector: '.dropdown ul',
                $_dropdown-toggle-icon-content: $icon-down,
                $_dropdown-toggle-active-icon-content: $icon-up,
                $_icon-font-position: after,
                $_dropdown-list-width: 180px,
                $_icon-font-size: 9px,
                $_icon-font-line-height: 1,
                $_icon-font-margin: 7px 0 0 5px,
                $_icon-font-color: $djm-color-gray2,
                $_icon-font-color-hover: $djm-color-gray2,
                $_icon-font-color-active: $djm-color-gray2
            );
            @include lib-list-inline();

            ul li {
                display: block;
            }
        }

        li {
            position: relative;
            font-size: 14px;
            color: $djm-color-gray2;

            a {
                color: $djm-color-gray2;
            }

            &.dropdown {
                &:hover ul {
                    display: block;
                }

                ul {
                    margin-top: 10px;
                }

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 28px;
                    width: 100%;
                }
            }
        }
    }

    .header {
        &__usps {
            @include lib-list-inline();

            li {
                @include lib-icon-font(
                    $_icon-font-content: $icon-checkmark,
                    $_icon-font-size: 12px,
                    $_icon-font-color: $djm-color-blue,
                    $_icon-font-line-height: 1,
                    $_icon-font-margin: -2px 5px 0 0
                );
                margin-bottom: 0;

                &:not(:last-child) {
                    margin-right: 20px;
                }
            }
        }

        &__links ul > li {
            @include lib-list-reset-styles();

            &:not(:last-child) {
                margin-right: 20px;
            }
        }

        &.content {
            position: relative;
            display: flex;
            align-items: center;
            padding: 10px;
        }

        &-icons {
            display: flex;
            align-items: center;
            margin-left: auto;

            div:not(:last-child) {
                margin-right: 10px;
            }

            .to-order-list {
                @include lib-rounded-button(
                    $_button-color: $djm-color-gray2,
                    $_button-background: $color-white,
                    $_button-border: $djm-color-light-gray6
                );
                @include lib-icon-font(
                    $_icon-font-content: $icon-list-plus,
                    $_icon-font-size: 16px,
                    $_icon-font-color: $djm-color-gray2,
                    $_icon-font-line-height: 1,
                    $_icon-font-display: flex
                );
            }
        }
    }

    .widget.block {
        @include lib-css(margin, $indent__base 0);
    }
}

@include min-screen($screen__l) {
    .header.panel > .header.links > li {
        &.authorization-link,
        .register-link {
            display: none;
        }
    }

    .page-header {
        margin-bottom: 0;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .page-header {
        border: 0;
        color: $color-gray-text-light;
        font-weight: $font-weight__semibold;

        .panel.wrapper {
            border-bottom: 1px solid $secondary__color;
        }

        .header.panel {
            @extend .abs-add-clearfix-desktop;
            padding-bottom: $indent__s;
            padding-top: $indent__s;
        }

        .header.content {
            padding: 30px 20px;
        }

        .switcher {
            display: inline-block;
        }

        &::after {
            content: '';
            position: absolute;
            z-index: -1;
            top: 100%;
            left: 0;
            right: 0;
            height: 921px;
            background-image: url('../images/headerbg.jpg');
            background-repeat: no-repeat;
            background-position: top center;
        }
    }
}

//
//  Mobile
//  ---------------------------------------------

@include max-screen($screen__m) {
    .header.content {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .page-header {
        border-bottom: 0;
    }
}
