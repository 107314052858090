$filter-title-background-color: $color-gray94;
$filter-link: $text__color__light;
$filter-link-hover: darken($filter-link, 30%);
$filter-quantity: lighten($color-dark-blue1, 50%);

.filter {
    &.block {
        margin-bottom: 0;
    }

    &-title {
        strong {
            $_shadow: inset 0 1px 0 0 $color-white, inset 0 -1px 0 0 rgba($border-color__base, 0.3);
            @include lib-css(background-color, $color-white);
            @include lib-css(box-shadow, $_shadow);
            border: 1px solid $border-color__base;
            border-radius: 3px;
            font-weight: 400;
            left: 0;
            line-height: 16px;
            padding: 7px $indent__s;
            position: absolute;
            text-align: center;
            top: 0;
            z-index: 2;
            cursor: pointer;
            display: none;

            &[data-count]:after {
                @include lib-css(color, $color-white);
                background: $color-orange-red1;
                border-radius: 2px;
                content: attr(data-count);
                display: inline-block;
                font-size: 0.8em;
                line-height: 1;
                margin: 0 $indent__xs;
                min-width: 1em;
                padding: 2px;
            }

            &.disabled {
                opacity: 0.5;
            }
        }
    }

    .block-subtitle {
        border-bottom: $border-width__base solid $border-color__base;
        font-size: $font-size__base;
        font-weight: $font-weight__semibold;
        line-height: 1em;
    }

    &-subtitle {
        display: none;
    }

    .actions a,
    a {
        @include lib-css(color, $filter-link);
    }

    &-current {
        margin: 0;

        .item {
            padding-left: 2px;
            position: relative;
            z-index: 1;
            color: $color-white;
        }

        .filter &-subtitle {
            border: none;
            display: block;
            color: $color-white;
        }

        .action.remove {
            @extend .abs-remove-button-for-blocks;
            left: -2px;
            position: absolute;
            top: -1px;
        }
    }

    &-actions {
        margin-bottom: $indent__m;
        padding: 0 $indent__s;
    }

    &-label {
        strong {
            font-weight: $font-weight__bold;
        }
    }

    &-value {
        @include lib-css(color, $filter-quantity);
    }

    &-options {
        display: none;
        margin: 0;

        &-item {
            margin-bottom: $indent__m;
        }

        &-title {
            padding-right: 40px;
            font-weight: $font-weight__semibold;
            overflow: hidden;
            position: relative;
            word-break: break-all;
            z-index: 1;
            font-size: 1.6rem;
            text-transform: uppercase;
            cursor: pointer;
            @include lib-icon-font(
                $icon-down,
                $_icon-font-position: after,
                $_icon-font-display: block
            );

            &::after {
                position: absolute;
                top: 0;
                right: 0;
            }

            &[aria-expanded=true] {
                &::after {
                    transform: rotate(180deg);
                }
            }
        }

        &-content {
            margin: 20px 0 0;
            padding: 0;

            .item {
                line-height: 1.5em;
                margin: $indent__s 0 0;
            }

            a {
                margin-left: -5px;
                margin-right: -5px;
                padding-left: 5px;
                padding-right: 7px;
                @include lib-font-size(15);
                color: $color-gray-text;
                font-weight: $font-weight__heavier;
            }

            input[type=checkbox] {
                display: none;
            }

            input[type=checkbox]:checked {
                &~.label {
                    @include lib-icon-font(
                        $_icon-font-display: inline,
                        $_icon-font-content: $icon-checkmark,
                        $_icon-font-position: after,
                        $_icon-font-color: $color-blue1,
                        $_icon-font-size: 14px
                    );

                    &::after {
                        line-height: 17px;
                    }
                }
            }

            .label {
                position: relative;
                display: inline;
                margin-bottom: 0;
                padding-left: 27px;

                &::before,
                &::after {
                    position: absolute;
                    top: 1px;
                    left: 0;
                    display: block;
                    width: 16px;
                    height: 16px;
                    pointer-events: none;
                    content: "";
                    user-select: none;
                    border-radius: 0.25rem;
                    border: 1px solid $form-element-input__border-color;
                }

                &::before {
                    background-color: #fff;
                }
            }

            .count {
                @include lib-css(color, $filter-quantity);
                font-weight: $font-weight__light;
                padding-left: 5px;
                padding-right: 5px;
            }

            .filter-count-label {
                @include abs-visually-hidden();
            }
        }
    }

    &-state {
        position: relative;
        padding: 15px;
        background-color: rgba(10, 45, 103, 0.33);
        border-radius: $block-border-radius;
        color: $text__color__lighter;

        .item {
            @include lib-font-size(15);
        }

        .block-subtitle {
            font-weight: $font-weight__semibold;
            font-size: 1.6rem;
            text-transform: uppercase;
        }

        .filter-value {
            color: $text__color__light;
        }

        .filter-actions {
            margin: 0;
            margin-bottom: 10px;
        }

        a {
            color: $text__color__lighter;
        }

        .action.remove {
            &::before {
                color: $text__color__lighter;
            }
        }
    }
}


//
//  Mobile
//  _____________________________________________

@include max-screen($screen__l) {
    body {
        &.filter-active {
            .page-header {
                display: none;
            }

            .page-wrapper {
                height: 0;
                margin-top: -999999em;
                visibility: hidden;
            }

            .columns {
                z-index: 999;
            }
        }
    }

    .filter {
        &.active {
            position: relative;
            visibility: visible;
            z-index: 99;

            .filter-options-item:last-child {
                margin-bottom: $indent__xl;
            }

            .filter-title {
                height: 48px;
                left: 0;
                position: fixed;
                right: 0;
                top: 0;
                z-index: 2;
                background-color: $color-white;
                border-bottom: 1px solid $border-color__base;

                strong {
                    @include lib-css(box-shadow, none);
                    background: none;
                    border: 0;
                    color: transparent;
                    left: auto;
                    right: 3px;
                    top: 10px;
                    @include lib-icon-font(
                        $icon-remove,
                        $_icon-font-color: $text__color__muted,
                        $_icon-font-size: 16px,
                        $_icon-font-position: after
                    );
                }
            }

            .filter-subtitle {
                @include lib-css(background, $toolbar-background);
                display: block;
                height: 50px;
                left: 0;
                line-height: 32px;
                position: fixed;
                right: 0;
                top: 0;
                z-index: 1;
                display: none;
            }

            .filter-options {
                @include lib-css(background, $color-white);
                bottom: 0;
                display: block;
                left: 0;
                overflow: scroll;
                position: fixed;
                right: 0;
                top: 49px;
                z-index: 10;
                padding-top: $indent__m;
            }
        }
    }

    .filter {
        & &-subtitle {
            font-size: 20px;
            font-weight: $font-weight__light;
        }

        &-actions {
            margin: -50px 5px 25px;
        }

        &-options-item {
            padding: 25px;
            background-color: rgba(10, 45, 103, .6);
            border-radius: $block-border-radius;
            margin-bottom: 10px;
        }

        &-options-content {
            padding: 0;
        }
    }

    .filter .filter-current {
        border-width: 1px 0;
        margin: 0 0 15px;
        padding: 15px;
        border: $button-ghost__border;
        border-radius: 6px;

        .items {
            display: none;
            margin: 20px 0 30px;
        }

        &-subtitle {
            text-transform: uppercase;
            z-index: 1;
            @include lib-icon-font(
                $icon-down
            );

            &:before {
                position: absolute;
                right: 25px;
                top: 20px;
            }
        }

        &.active {
            .items {
                display: block;
            }

            & + .block-actions {
                display: block;
            }
        }

        & + .block-actions {
            display: none;
        }
    }

    .filter-no-options {
        .filter-title {
            &:before {
                background: rgba(255, 255, 255, 0.5);
                content: '';
                display: block;
                height: 40px;
                left: -15px;
                margin-top: -60px;
                position: relative;
                width: 100px;
                z-index: 99;
            }
        }

        .filter-content {
            margin-bottom: $indent__base;
        }
    }

    .page-with-filter {
        .columns {
            .sidebar-main {
                @include lib-vendor-prefix-order(0);
            }
        }
    }
}

@include min-screen($screen__l) {
    .filter {
        &-current,
        &-current + &-actions {
            padding: 25px;
            background-color: rgba(10, 45, 103, 0.33);
        }

        & &-current {
            padding-bottom: 10px;
            border-radius: $block-border-radius $block-border-radius 0 0;

            &-subtitle {
                margin-bottom: 20px;
                font-weight: $font-weight__semibold;
                overflow: hidden;
                position: relative;
                word-break: break-all;
                z-index: 1;
                font-size: 1.6rem;
                text-transform: uppercase;
            }
        }

        &-current + &-actions {
            padding-top: 0;
            border-radius: 0 0 $block-border-radius $block-border-radius;
        }

        &.block {
            margin-bottom: $indent__xl;
        }

        &-actions {
            margin-bottom: $indent__l;
        }

        &.active &-options,
        &-options {
            background: transparent;
            clear: both;
            display: block;
            overflow: initial;
            position: static;
        }

        &-options {
            &-title {
                margin: 0;
                color: $text__color__lighter;
            }

            &-item {
                padding: $indent__m;
                background-color: rgba(10, 45, 103, 0.33);
                border-radius: $block-border-radius;
            }

            &-content {
                a {
                    @include lib-css(color, $filter-link);
                }
            }
        }

        &-subtitle {
            display: none;
            position: static;
        }
    }

    .page-layout-1column, .page-layout-2columns-left {
        .products {
            margin: 20px 0;
        }

        .toolbar-products {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding-bottom: 8px;

            &.toolbar-top {
                border-bottom: 1px solid #267bc1;
            }

            &.toolbar-bottom {
                justify-content: center;
            }

            .modes {
                display: none;
            }

            &:before, &:after {
                display: none;
            }
        }

        .products ~ .toolbar-products {
            position: static;
        }

        &.page-with-filter .column.main {
            padding-top: 45px;
            position: relative;
            z-index: 1;
        }

        &.page-with-filter .sidebar-main {
            padding-top: 45px;
        }

        .filter {
            &.block {
                border-top: 1px solid $border-color__base;
            }

            &-content {
                margin-top: $indent__s;
            }

            &-subtitle {
                display: none;
            }

            &-options {
                &-item {
                    border: 0;
                    display: inline-block;
                    position: relative;

                    &.active {
                        z-index: 10;

                        .filter-options-content {
                            visibility: visible;
                        }

                        &:hover {
                            z-index: 3;
                        }

                        &:after,
                        &:before {
                            @include lib-arrow(up, 8px, $color-black);
                            bottom: -1px;
                            content: '';
                            display: block;
                            right: 20px;
                            position: absolute;
                            z-index: 3;
                        }

                        &:after {
                            @include lib-css(border-bottom-color, $color-white);
                            margin-top: 2px;
                            z-index: 25;
                        }
                    }
                }

                &-title {
                    padding: 0 $indent__base 0 0;

                    &:after {
                        right: 2px;
                        top: 3px;
                        z-index: 3;
                    }
                }

                &-content {
                    $_shadow: 0 3px 5px 0 rgba(50, 50, 50, 0.75);

                    @include lib-css(background, $color-white);
                    @include lib-css(box-shadow, $_shadow, 1);
                    border: 1px solid $border-color__base;
                    padding: $indent__xs 0;
                    position: absolute;
                    top: 100%;
                    right: 0;
                    visibility: hidden;
                    width: 180px;
                    z-index: 15;

                    .item {
                        margin: 0;
                        padding: $indent__xs;

                        label {
                            span:hover {
                                cursor: pointer;
                            }
                        }

                        a {
                            margin-left: 0;
                        }
                    }
                }
            }

            &-current {
                &-subtitle {
                    @include lib-css(color, $text__color__muted);
                    display: inline;
                    font-size: $font-size__base;
                    font-weight: normal;
                    padding: 0;

                    &:after {
                        content: ':';
                    }
                }

                .item,
                .items {
                    display: inline;
                }

                .item {
                    margin-right: $indent__m;
                }

                .action.remove {
                    line-height: normal;
                }
            }

            &-actions {
                display: inline;
                white-space: nowrap;

                & ~ .filter-options {
                    margin-top: $indent__m;
                }
            }
        }
    }
}
