.impression-slider {
    position: relative;
    margin: 55px auto;
    padding: 0 30px 30px 30px;
    border: 2px solid $color-blue-border;
    border-top: 0;
    z-index: 1;

    .swiper-wrapper {
        max-height: 100%;

        .item {
            width: 25%;
            margin-right: 30px;

            img.slider-image {
                border-radius: 6px;
            }
        }
    }

    .block-title {
        transform: translateY(-50%);
        margin-left: -32px;
        margin-right: -32px;
        text-align: center;
        overflow: hidden;

        strong {
            position: relative;
            font-weight: $font-weight__bold;
            text-transform: uppercase;
            color: $text__color__light;
            letter-spacing: 2px;
            font-size: 18px;

            &::before,
            &::after {
                content: '';
                position: absolute;
                top: 50%;
                width: 50vw;
                height: 2px;
                background-color: $color-blue-border;
            }

            &::before {
                right: calc(100% + 15px);
            }

            &::after {
                left: calc(100% + 10px); // text has some whitespace after it, so we use less px
            }
        }
    }

    .controls {
        .impression-slider-button-container {
            position: absolute;
            top: 16px;
            right: 14px;
            display: flex;
        }

        .impression-slider-button {
            @include lib-icon-font(
                $_icon-font-content: $icon-prev,
                $_icon-font-position: after,
                $_icon-font-size: 16px,
                $_icon-font-display: block,
                $_icon-font-color: #ffffff
            );

            border-radius: 50%;
            cursor: pointer;
            margin-right: 5px;
            padding: 0 10px;
            overflow: hidden;
            line-height: 35px;
            position: absolute;
            z-index: 2;
            top: 50%;
            transform: translateY(-50%);
            background-color: $djm-color-blue;

            &:hover {
                box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.7);
            }

            &:last-child {
                margin-right: 0;
            }
        }

        .impression-slider-button-prev {
            left: 12px;

            &::after {
                transform: translateX(-1px);
            }
        }

        .impression-slider-button-next {
            @include lib-icon-font-symbol(
                $_icon-font-content: $icon-next,
                $_icon-font-position: after
            );

            right: 12px;

            &::after {
                transform: translateX(1px);
            }
        }

        .impression-slider-button-disabled {
            opacity: 0;
            cursor: auto;
            pointer-events: none;
        }

        .impression-slider-button-lock {
            display: none;
        }
    }
}

@include max-screen($screen__l) {
    .impression-slider {
        .block-title {
            strong {
                font-size: 16px;
            }
        }
    }
}

@include max-screen($screen__m) {
    .impression-slider {
        margin-top: 30px;
    }
}

@include max-screen($screen__xs) {
    .impression-slider {
        .block-title {
            strong {
                font-size: 14px;
            }
        }
    }
}
