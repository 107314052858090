.vacancy_page-vacancy-index {
    .page-title-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        .page-title {
            font-family: "PT Serif", Georgia, "Times New Roman", Times, serif;
            font-weight: bold;
        }
    }

    .vacancy-page {
        .vacancy-listing {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            column-gap: 22px;
            row-gap: 42px;

            .vacancy {
                flex: 0 0 calc((100% / 3) - 15px);
                background-color: white;
                border-radius: 6px;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                img {
                    min-height: 300px;
                    max-height: 300px;
                }

                .content {
                    padding: 30px;

                    h2.title {
                        text-transform: uppercase;
                        font-size: 18px;
                        font-weight: bold;
                        letter-spacing: 0.9px;
                        text-align: left;
                        color: $djm-color-dark-blue3;
                        margin-bottom: 5px;
                        margin-top: 0;
                    }

                    h3.sub-title {
                        margin-top: 0;
                        font-size: 16px;
                        font-weight: normal;
                        text-align: left;
                        color: $color-blue1;
                    }

                    .short-description {
                        font-size: 15px;
                        font-weight: normal;
                        line-height: 1.5;
                        text-align: left;
                        color: $djm-color-gray;
                        max-height: calc(10 * 22.5px);
                        min-height: calc(10 * 22.5px);
                        overflow: hidden;
                        margin-bottom: 20px;

                        &:first-child {
                            margin-top: 0;
                        }

                        p, h1, h2, h3, h4, h5, h6 {
                            margin-bottom: 22.5px;
                            margin-top: 22.5px;
                        }

                        ul > li {
                            margin-bottom: 0;
                            margin-top: 0;
                        }

                        ul {
                            padding-inline-start: 10px;
                        }
                    }

                    button.action {
                        height: 36px;
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        border-radius: 18px;
                        background-color: #0ebb2d;
                        text-decoration: none;

                        span {
                            color: #ffffff;
                            font-size: 13px;
                            font-weight: 900;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }

        .information-blocks {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: 50px;

            .information-block {
                flex: 0 0 calc((100% / 2) - 20px);
                box-sizing: border-box;
                font-size: 16px;
                font-weight: normal;
                line-height: 1.5;
                color: $djm-color-light-blue;
                border: 2px solid #267ec7;
                border-top: 0;
                padding: 0 30px 30px 30px;

                .block-title {
                    transform: translateY(-50%);
                    margin-left: -32px;
                    margin-right: -32px;
                    text-align: center;
                    overflow: hidden;

                    strong {
                        position: relative;
                        font-weight: $font-weight__bold;
                        text-transform: uppercase;
                        color: $text__color__light;
                        letter-spacing: 2px;
                        font-size: 18px;
                        line-height: 2.67;

                        &::before,
                        &::after {
                            content: '';
                            position: absolute;
                            top: 50%;
                            width: 50vw;
                            height: 2px;
                            background-color: $color-blue-border;
                        }

                        &::before {
                            right: calc(100% + 15px);
                        }

                        &::after {
                            left: calc(100% + 10px); // text has some whitespace after it, so we use less px
                        }
                    }
                }

                .text {
                    margin-top: 0;

                    p {
                        margin-top: 0;
                    }

                    li {
                        margin-bottom: 0;
                    }

                    ul {
                        padding-inline-start: 10px;
                    }
                }
            }
        }
    }

    // Modal
    .modal-inner-wrap {
        border-radius: 6px;
        max-width: 1030px;
        top: unset;
        bottom: unset;

        .modal-header {
            padding: 20px;

            button.action-close {
                padding: 20px;
                &:before {
                    font-size: 15px;
                }
            }
        }

        .modal-content {
            .content {
                h2.title {
                    text-transform: uppercase;
                    font-size: 35px;
                    font-weight: bold;
                    color: $djm-color-dark-blue3;
                    margin-bottom: 5px;
                    margin-top: 0;
                }

                h3.sub-title {
                    margin-top: 0;
                    margin-bottom: 30px;
                    font-size: 16px;
                    font-weight: normal;
                    color: $color-blue1;
                }

                .description {
                    font-size: 15px;
                    font-weight: normal;
                    line-height: 1.5;
                    color: $djm-color-gray;

                    ul > li {
                        margin-bottom: 0;
                        margin-top: 0;
                    }

                    ul {
                        padding-inline-start: 25px;
                    }
                }
            }
        }

        .modal-footer {
            border-top: 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding-top: 0;

            button.action {
                height: 36px;
                width: 320px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                border-radius: 18px;
                background-color: #0ebb2d;
                text-decoration: none;

                span {
                    color: #ffffff;
                    font-size: 13px;
                    font-weight: 900;
                    text-transform: uppercase;
                }
            }

            button.close {
                background-color: transparent;
                color: $djm-color-gray;

                &:active {
                    box-shadow: none;

                    span {
                        color: grey;
                    }
                }

                span {
                    font-size: 15px;
                    font-weight: normal;
                    text-transform: none;
                    line-height: 1.5px;
                    text-decoration: underline;
                    text-underline-offset: 4px;
                    letter-spacing: normal;
                }
            }
        }
    }
}

@include max-screen($screen__l) {
    .vacancy_page-vacancy-index {
        .vacancy-page {
            .vacancy-listing {
                .vacancy {
                    flex: 0 0 calc((100% / 2) - 15px);
                }
            }

            .information-blocks {
                .information-block {
                    .block-title {
                        strong {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}

@include max-screen($screen__m) {
    .vacancy_page-vacancy-index {
        .vacancy-page {
            .vacancy-listing {
                .vacancy {
                    flex: 0 0 100%;

                    .content {
                        padding: 15px;
                        .short-description {
                            max-height: calc(5 * 22.5px);
                            min-height: calc(5 * 22.5px);
                        }
                    }
                }
            }

            .information-blocks {
                .information-block {
                    flex: 0 0 100%;
                    margin-bottom: 36px;
                    font-size: 14px;

                    .block-title {
                        margin-bottom: -15px;
                        font-size: 16px;
                    }
                }
            }
        }

        // Mobile Modal
        .modal-popup {
            left: 0;
            top: 4vh;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 4vh;

            .modal-inner-wrap {
                max-width: 95%;

                .modal-header {
                    position: absolute;
                    right: 0;

                    button.action-close {
                        background-color: $djm-color-blue;
                        padding: 7px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin: 15px;
                        width: 24px;
                        height: 24px;
                        border-radius: 24px;

                        &:before {
                            font-size: 12px;
                            color: #ffffff;
                        }
                    }
                }

                .modal-content {
                    padding: 20px;
                    .content {
                        h2.title {
                            font-size: 18px;
                            max-width: 90%;
                        }
                    }
                }
            }
        }
    }
}

@include max-screen($screen__s) {
    .vacancy_page-vacancy-index {
        .page-title-wrapper {
            .page-title {
                font-size: 22px;
            }
        }
    }
}

.vacancy_page-application-form {
    .column.main {
        .form {
            .fieldset {
                .field.function {
                    select {
                        box-shadow: 0 0 0 1px inset #ccc;
                    }
                }
            }

            span.allowed-types {
                font-style: italic;
            }
        }
    }
}
