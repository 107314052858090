//
//  Variables
//  _____________________________________________

$autocomplete__background-color: $color-white;
$autocomplete__border: 1px solid #ddd;
$autocomplete-item__border: 1px solid $color-gray90;
$autocomplete-item__hover__color: $color-gray91;
$autocomplete-item-amount__color: $color-gray60;

.block-search {
    margin-bottom: 0;

    .block {
        &-title {
            display: none;
        }
    }

    .block-content {
        margin-bottom: 0;
    }

    .label {
        @include lib-icon-font(
            $_icon-font-content: $icon-search,
            $_icon-font-margin: 0 10px 0 0,
            $_icon-font-size: 22px,
            $_icon-font-line-height: 28px,
            $_icon-font-color: $minicart-icons-color,
            $_icon-font-color-hover: $minicart-icons-color-hover,
            $_icon-font-color-active: $minicart-icons-color-hover,
            $_icon-font-text-hide: true
        );
        display: inline-block;

        &.active {
            + .control {
                input {
                    position: static;
                }
            }
        }
    }

    .action.search {
        display: none;
    }

    .control {
        border-top: 1px solid $border-color__base;
        clear: both;
        margin: 0 (-$layout__width-xs-indent) -1px;
        padding: 0 $layout__width-xs-indent;
    }

    input {
        margin: 15px 0;
        position: relative;
    }

    .nested {
        display: none;
    }
}

.search-autocomplete {
    @extend .abs-add-box-sizing;
    display: none;
    margin-top: -15px;
    overflow: hidden;
    position: absolute;
    z-index: 3;

    ul {
        @include lib-list-reset-styles();

        li {
            @include lib-css(border-top, $autocomplete-item__border);
            cursor: pointer;
            margin: 0;
            padding: $indent__xs $indent__xl $indent__xs $indent__s;
            position: relative;
            text-align: left;
            white-space: normal;

            &:not(:empty) {
                border-top: 0;
                @include lib-css(border, $autocomplete__border);
                @include lib-css(background, $autocomplete__background-color);
            }

            &:first-child {
                border-top: none;
            }

            &:hover,
            &.selected {
                @include lib-css(background, $autocomplete-item__hover__color);
            }

            .amount {
                @include lib-css(color, $autocomplete-item-amount__color);
                position: absolute;
                right: 7px;
                top: $indent__xs;
            }
        }
    }
}

.form.search.advanced {
    .fields.range {
        .field {
            &:first-child {
                position: relative;

                .control {
                    padding-right: 25px;

                    &:after {
                        content: ' \2013 ';
                        display: inline-block;
                        position: absolute;
                        right: 0;
                        text-align: center;
                        top: 6px;
                        width: 25px;
                    }
                }
            }

            &:last-child {
                position: relative;

                div.mage-error[generated] {
                    left: 0;
                    position: absolute;
                    top: 32px;
                }
            }

            &.with-addon {
                .control {
                    padding-right: 45px;
                }
            }
        }
    }

    .group.price {
        .addon {
            .addafter {
                background: none;
                border: 0;
                padding-top: 6px;
                position: absolute;
                right: 0;
                top: 0;
            }
        }
    }
}

.search.summary {
    margin-bottom: $indent__s;
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .block-search {
        .block-content {
            margin-bottom: 0;
        }

        .control {
            box-sizing: border-box;
            border-top: 0;
            position: relative;
            margin: 0;
            padding: 0 15px;
            background-color: $color-white;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .block-search {
        position: relative;
        z-index: 100;
        float: right;
        margin: 0 auto;
        width: 35%;

        .label {
            @extend .abs-visually-hidden-desktop;
            float: right;
        }

        .control {
            border-top: 0;
            margin: 0;
            padding: 0;
        }

        .nested {
            display: block;
            padding-top: 5px;
            position: absolute;
        }

        input {
            @include lib-input-placeholder($form-element-input-placeholder__color);
            margin: 0;
            padding-right: 35px;
            padding-left: 25px;
            position: static;
            border-radius: 30px;
            height: 45px;
        }

        .action.search {
            display: inline-block;
            @include lib-button-icon(
                $_icon-font-content: $icon-search,
                $_icon-font-text-hide: true,
                $_icon-font-color: $header-icons-color,
                $_icon-font-size: 16px,
                $_icon-font-line-height: 32px
            );
            @include lib-button-reset();
            padding: 0;
            position: absolute;
            right: 25px;
            top: 6px;
            z-index: 1;

            &:focus {
                &:before {
                    @include lib-css(color, $color-gray20);
                }
            }
        }
    }

    .search-autocomplete {
        margin-top: 0;
    }
}

.result-wrapper {
    background: $color-white;
}

@include min-screen($screen__xl) {
    .block-search {
        max-width: 450px;
        margin-left: 110px;
    }
}

@include min-screen($screen__xl) {
    .barcode-scanner-header {
        display: none;
    }
}

@include max-screen($screen__xl) {
    .block-search {
        display: none;
        position: absolute;
        width: 100%;
        bottom: 0;
        background-color: white;
        left: 0;
        right: 0;

        .block-content {
            display: flex;

            .form.minisearch {
                width: 100%;

                input {
                    height: 45px;
                    border-radius: 30px;
                }

                .search {
                    .label {
                        display: none;
                    }
                }

                .actions {
                    button {
                        &:before {
                            display: none;
                        }
                    }
                }
            }

            .barcode-scanner-header {
                .scanning-toggle-wrapper {
                    display: flex;

                    p {
                        padding: 0 15px;
                    }
                }

                .scanning-toggle {
                    padding: 0 15px;
                    margin: 15px 0;
                }

                button {
                    display: flex;
                    align-items: center;
                    flex-direction: column;

                    svg {
                        width: 35px;
                        fill: $djm-color-light-gray;
                    }

                    span {
                        font-size: 11px;
                        font-weight: 800;
                        color: $djm-color-light-gray;
                    }
                }

                .camera-preview {
                    position: absolute;
                    @media (orientation: landscape) {
                        top: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 50%;
                    }
                }
            }
        }
    }

    .block-search.scanning {
        z-index: 5;
        height: 100vh;

        .form.minisearch {
            display: none;
        }

        .barcode-scanner-header {
            margin: auto;
        }

        .scanning-toggle {
            padding: 0 15px;
            margin: 15px 0;
            width: 60%;
        }
    }

    .block-search.active {
        display: block;
        position: fixed;
        top: 0;
        height: 75px;
        z-index: 40;
        border-bottom: 1px solid $border-color__base;

        input {
            margin: 15px 0;
        }

        .control {
            padding: 0 15px;
        }

    }

    .autocomplete-wrapper.active {
        position: fixed;
        min-height: initial;

        .category-wrapper {
            display: none;
        }

        .autocomplete-wrapper__left {
            display: none;
        }

        .autocomplete {
            min-height: initial;

            ul.item-wrapper {
                li {
                    margin-bottom: 0;

                    &:nth-child(n+11) {
                        display: none;
                    }
                }
            }
        }
    }
}
